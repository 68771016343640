import React, { useEffect, useState } from "react";
import { Input } from "../../shared/styled";
import { FooterContainer, FooterWrapper } from "./styled";
import inputArrowEmpty from "../../assets/footer-input-arrow-empty.svg";
import inputArrowFilled from "../../assets/footer-input-arrow-filled.svg";
import { ClickAwayListener } from "@mui/material";
import barbershopLogo from "../../assets/barbershop-logo.svg";
import PATH from "../../constants/path";
import axios from "axios";

const Footer = () => {
  const [inputValue, setInputValue] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const [inputHovered, setInputHovered] = useState(false);
  const [response, setResponse] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subscriptionDiscount, setSubscriptionDiscount] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    axios.get(`${PATH}/api/information/facebook_link`).then((res) => {
      setFacebookLink(res.data.data.information?.value);
    });
    axios.get(`${PATH}/api/information/instagram_link`).then((res) => {
      setInstagramLink(res.data.data.information?.value);
    });
    axios.get(`${PATH}/api/information/phone`).then((res) => {
      setPhone(res.data.data.information?.value);
    });
    axios.get(`${PATH}/api/information/email`).then((res) => {
      setEmail(res.data.data.information?.value);
    });
    axios.get(`${PATH}/api/information/subscription_discount`).then((res) => {
      setSubscriptionDiscount(res.data.data.information?.value);
    });
  }, []);

  const handleSubscribe = () => {
    axios
      .post(`${PATH}/api/subscribe`, { email: inputValue })
      .then((res) => {
        setResponse(res.data.message);
      })
      .catch((err) =>
        setResponse(
          err.response.data?.data?.email?.[0] || err.response.data.message
        )
      )
      .finally(() => {
        setTimeout(() => {
          setInputValue("");
          setResponse("");
        }, 5000);
      });
  };

  return (
    <FooterContainer className="footer-container">
      <a
        // onClick={(e) => e.preventDefault()}
        href="/barbershop"
        className="section-8 section-barbershop"
        // onClick={handleBarbershopContainerClick}
      >
        <div className="divider"></div>
        <div className="barbershop-container">
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
          <img src={barbershopLogo} alt="barbershop" />
          <p>BARBERSHOP</p>
        </div>
        <div className="divider"></div>
      </a>
      <FooterWrapper>
        <h3>
          We collaborate with ambitious brands and people. Let's cooperate.
        </h3>
        <div>
          <a className="boldLink" href={`mailto:${email && email}`}>
            {email && email}
          </a>
        </div>
        <h3>STAY IN THE KNOW</h3>
        <p>
          subscribe to our newsletter and get{" "}
          {subscriptionDiscount && subscriptionDiscount}% discount
        </p>
        <div
          className={`input-wrapper ${inputFocused ? "focused" : ""} ${
            inputHovered ? "hovered" : ""
          }`}
          onMouseOver={() => setInputHovered(true)}
          onMouseOut={() => setInputHovered(false)}
        >
          <ClickAwayListener onClickAway={() => setInputFocused(false)}>
            <Input
              onChange={handleInputChange}
              onFocus={() => setInputFocused(true)}
              onfocusout={() => setInputFocused(false)}
              value={inputValue}
              placeholder="E-MAIL ADDRESS"
            />
          </ClickAwayListener>
          <img
            onClick={handleSubscribe}
            className="arrow"
            src={
              inputFocused || window.screen.width <= 768
                ? inputArrowFilled
                : inputArrowEmpty
            }
            alt="arrow"
          />
          {response && <p className="response-message">{response}</p>}
        </div>
        <div className="lower-row">
          <div className="left copyright">ALGORITHM SOLUTIONS Ⓒ 2022</div>
          <div className="center">
            <a href="/about">ABOUT</a>
            <a href="/contacts">CONTACTS</a>
            <a href="/blog">BLOG</a>
            <a href="/privacy-policy">PRIVACY</a>
          </div>
          <div className="right">
            <a href="tel:+37455110406">{phone && phone}</a>
            <a
              href={facebookLink && facebookLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              FACEBOOK
            </a>
            <a
              href={instagramLink && instagramLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              INSTAGRAM
            </a>
          </div>
        </div>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
