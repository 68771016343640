import React, { useEffect, useState } from "react";
import { BlogWrapper, Divider } from "./styled";
import BlogPost from "../../shared/blog-post/BlogPost";
import { PageWrapper, Section } from "../../shared/styled";
import Footer from "../footer/Footer";
import CustomPagination from "../../shared/CustomPagination";
import PATH from "../../constants/path";
import axios from "axios";
import moment from "moment";

const Blog = () => {
  const [pagination, setPagination] = useState({});
  const [blogposts, setBlogposts] = useState([]);
  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    axios
      .get(`${PATH}/api/blogs?size=4&page=${currentPage}`)
      .then((response) => {
        setBlogposts(response.data.data.posts);
        setPagination(response.data.data.pagination);
      });
  }, [currentPage]);

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
  };

  return (
    <BlogWrapper id="blog-top">
      <PageWrapper>
        <Section className="section-head">
          <div className="left-side">
            <h2>BLOG</h2>
          </div>
          <div className="right-side">
            <p>
              We know how important your beard <br /> is to you and we know how
              to take care of it.
            </p>
          </div>
        </Section>
        <Section>
          <Divider />
        </Section>
        <Section className="section-blogs">
          {!!blogposts.length &&
            blogposts.map((blogPost) => {
              return (
                <span key={blogPost?.id}>
                  <BlogPost
                    img={`${PATH}/${blogPost?.image}`}
                    imgStyle={{
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url("${PATH}/${blogPost?.image}")`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    date={moment(blogPost?.date).format("DD.MM.YYYY")}
                    href={`/single-blog/${blogPost?.slug}`}
                    title={blogPost?.blog_info?.title}
                  />
                  <Divider />
                </span>
              );
            })}
        </Section>
        <Section className="section-pagination">
          {pagination.pages_count > 1 && (
            <CustomPagination
              pageCount={pagination.pages_count}
              handlePageChange={handlePageChange}
            />
          )}
        </Section>
        <Footer />
      </PageWrapper>
    </BlogWrapper>
  );
};

export default Blog;
