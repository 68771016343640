import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./home/Home";
import Account from "./account/Account";
import About from "./about/About";
import Products from "./products/Products";
import Product from "./products/Product";
import Contacts from "./contacts/Contacts";
import Blog from "./blog/Blog";
import Checkout from "./checkout/Checkout";
import Cart from "./cart/Cart";
import Barbershop from "./barbershop/Barbershop";
import Settings from "./account/Settings";
import Orders from "./account/Orders";
import SingleOrder from "./account/SingleOrder";
import "./App.css";
import Header from "./headers/Header";
import UserVerification from "./auth/UserVerification";
import ResetPassword from "./auth/ResetPassword";
import moruqHomeLogo from "../assets/moruq_logo.svg";
import Menu from "./menu/Menu";
import { Main } from "../shared/styled";
import SingleBlog from "./blog/SingleBlog";
import PATH from "../constants/path";
import axios from "axios";
import Admin from "./admin/Admin";
import UsersAdmin from "./admin/components/users/UsersAdmin";
import ProductsAdmin from "./admin/components/products/ProductsAdmin";
import OrdersAdmin from "./admin/components/orders/OrdersAdmin";
import BlogAdmin from "./admin/components/blog/BlogAdmin";
import PartnersAdmin from "./admin/components/partners/PartnersAdmin";
import AddNewProduct from "./admin/components/products/AddNewProduct";
import ProductAdmin from "./admin/components/products/ProductAdmin";
import Promos from "./admin/components/promo/Promos";
import AddNewPromo from "./admin/components/promo/AddNewPromo";
import AddNewPartner from "./admin/components/partners/AddNewPartner";
import AddNewPost from "./admin/components/blog/AddNewPost";
import EditPost from "./admin/components/blog/EditPost";
import EditPartner from "./admin/components/partners/EditPartner";
import BrandLeaders from "./admin/components/brand-leaders/BrandLeaders";
import HomeAdmin from "./admin/components/home/HomeAdmin";
import BarbershopAdmin from "./admin/components/barbershop/BarbershopAdmin";
import ContactsAdmin from "./admin/components/contacts/ContactsAdmin";
import AdminLogIn from "./admin/components/AdminLogIn";
import AboutAdmin from "./admin/components/about/AboutAdmin";
import NotFound from "../components/not_found/NotFound";
import Privacy from "./global-terms/Privacy";
import Categories from "./admin/components/categories/Categories";
import OrderAccepted from "./order-accepted/OrderAccepted";
import OrderDeclined from "./order-accepted/OrderDeclined";
import MetaDecorator from "../MetaDecorator";
import metaThumbnail from "../assets/barbershop-img.jpg";

const App = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [menuClosed, setMenuClosed] = useState(false);
  const [update, setUpdate] = useState(false);

  let storageCart = JSON.parse(localStorage.getItem("storageCart"));

  if (storageCart?.length) {
  } else {
    localStorage.setItem("storageCart", JSON.stringify([]));
  }

  useEffect(() => {
    !menuOpened && !openMenu && setMenuOpened(true);
    menuOpened && !menuClosed && setMenuClosed(true);
  }, [openMenu]);

  useEffect(() => {
    axios.get(`${PATH}/api/categories`).then((response) => {
      localStorage.setItem(
        "categories",
        JSON.stringify(response.data.data.categories)
      );
    });
  }, []);

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const rerenderParentCallback = () => {
    setUpdate((prev) => !prev);
  };

  const content = {
    pageDescription: "PAGE DESCRIPTION",
    pageTitle: "PAGE TITLE",
    metaImageAlt: "META IMAGE ALT",
  };

  return (
    <>
      <Menu
        closeMenu={handleCloseMenu}
        open={openMenu}
        menuClosed={menuClosed}
      />
      <>
        <Routes>
          {!window.location.pathname.includes("admin") ? (
            <>
              <Route
                path="/"
                element={
                  <Main>
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <Home />
                  </Main>
                }
              />
              <Route
                path="about"
                element={
                  <Main>
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <About />
                  </Main>
                }
              />
              <Route
                path="*"
                element={
                  <Main>
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <NotFound />
                  </Main>
                }
              />
              <Route
                path="account"
                element={
                  <Main>
                    {" "}
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <Account />
                  </Main>
                }
              >
                <Route path="settings" element={<Settings />} />
                <Route path="orders" element={<Orders />} />
              </Route>
              <Route
                path="accepted"
                element={
                  <Main>
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <OrderAccepted />
                  </Main>
                }
              />
              <Route
                path="declined"
                element={
                  <Main>
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <OrderDeclined />
                  </Main>
                }
              />
              <Route
                path="order"
                element={
                  <Main>
                    {" "}
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <SingleOrder />
                  </Main>
                }
              >
                <Route path=":product" />
              </Route>
              <Route
                path="products"
                element={
                  <Main>
                    {" "}
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <Products />
                  </Main>
                }
              >
                <Route path=":filter" element={<Products />} />
              </Route>
              <Route
                path="product"
                element={
                  <Main>
                    {" "}
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <Product rerenderParentCallback={rerenderParentCallback} />
                  </Main>
                }
              >
                <Route path=":product" />
              </Route>
              <Route
                path="contacts"
                element={
                  <Main>
                    {" "}
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <Contacts />
                  </Main>
                }
              />
              <Route
                path="checkout"
                element={
                  <Main>
                    {" "}
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <Checkout updated={update} />
                  </Main>
                }
              />
              <Route
                path="cart"
                element={
                  <Main>
                    {" "}
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <Cart />
                  </Main>
                }
              />
              <Route
                path="blog"
                element={
                  <Main>
                    {" "}
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <Blog />
                  </Main>
                }
              />
              <Route
                path="single-blog/:single"
                element={
                  <Main>
                    {" "}
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <SingleBlog />
                  </Main>
                }
              />
              <Route path="verify" element={<UserVerification />}>
                <Route path=":id" element={<UserVerification />} />
              </Route>
              <Route path="resetpassword" element={<ResetPassword />}>
                <Route path=":id" element={<ResetPassword />} />
              </Route>
              <Route path="barbershop" element={<Barbershop />} />
              <Route
                path="privacy-policy"
                element={
                  <Main>
                    <Header
                      updated={update}
                      buttonsColor="#FFFFFF"
                      headerLogo={moruqHomeLogo}
                      handleOpenMenu={handleOpenMenu}
                      openMenu={openMenu}
                    />
                    <Privacy updated={update} />
                  </Main>
                }
              />
            </>
          ) : (
            <>
              <Route path="admin-login" element={<AdminLogIn />} />
              <Route path="admin" element={<Admin />} location="/admin">
                <Route path="users" element={<UsersAdmin />}></Route>
                <Route path="products">
                  <Route path="add-new" element={<AddNewProduct />} />
                  <Route path=":product" element={<ProductAdmin />} />
                  <Route index element={<ProductsAdmin />} />
                </Route>
                <Route path="orders" element={<OrdersAdmin />}></Route>
                <Route path="blog">
                  <Route index element={<BlogAdmin />} />
                  <Route path="add-new" element={<AddNewPost />} />
                  <Route path=":post" element={<EditPost />} />
                </Route>

                <Route path="partners">
                  <Route index element={<PartnersAdmin />} />
                  <Route path=":partner/:type" element={<EditPartner />} />
                  <Route path="add-new/:type" element={<AddNewPartner />} />
                </Route>
                <Route path="promo-codes">
                  <Route index element={<Promos />} />
                  <Route path="add-new" element={<AddNewPromo />} />
                </Route>
                <Route path="brand-leaders" element={<BrandLeaders />} />
                <Route path="home" element={<HomeAdmin />} />
                <Route path="contacts" element={<ContactsAdmin />} />
                <Route path="barbershop" element={<BarbershopAdmin />} />
                <Route path="about" element={<AboutAdmin />} />
                <Route path="categories" element={<Categories />} />
              </Route>
            </>
          )}
        </Routes>
      </>
    </>
  );
};

export default App;
