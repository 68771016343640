import styled, { css } from "styled-components";

const baseFlex = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const basePadding = css`
  padding: 0 20px;
`;

export const AuthWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 5;
  overflow: hidden;
  width: 100%;
  transform: translate3d(0, 0, 0);
  max-height: 100%;
  &.hide {
    height: 0;
    transition: height 1s cubic-bezier(0.85, 0.01, 0.4, 1);
  }
  &.show {
    height: 100%;
    transition: height 1s cubic-bezier(0.85, 0.01, 0.4, 1);
  }
  background-color: #232025;
`;

export const Auth = styled.div`
  width: 100%;
  padding: 0 20px;
  height: 100%;
  overflow-y: scroll;
`;

export const AuthTitle = styled.div`
  ${baseFlex}
  ${basePadding}
  & img {
    margin: 16px 0;
  }
`;

export const AuthNavigation = styled.div`
  display: flex;
`;

export const CustomBtn = styled.button`
  min-width: 60px;
  text-align: start;
  text-transform: capitalize;
  font-size: 16px;
  color: rgba(186, 186, 186, 0.5);
  position: relative;
  border: none;
  background-color: inherit;
  cursor: pointer;
  &.is-active {
    color: #fff;
  }
  & .to-right {
    transform: translateX(100%) scaleX(1.1);
  }
`;

export const Underline = styled.div`
  transition: transform 250ms cubic-bezier(0.1, 0.76, 0.4, 0.94);
  transform: translateX(0) scaleX(0.75);
  transform-origin: left;
  position: absolute;
  bottom: -18px;
  background-color: #fff;
  width: 100%;
  height: 3px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bababa;
  padding: 0 20px;
`;

export const IconButton = styled.button`
  border: none;
  background-color: inherit;
  cursor: pointer;
`;

export const AuthContent = styled.div``;
