import React, { useState } from "react";
import { BlogPostWrapper } from "./styled";
import filledArrow from "../../assets/blog-arrow-filled.svg";
import emptyArrow from "../../assets/blog-arrow-empty.svg";

const BlogPost = ({ title, date, href, imgStyle }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <>
      <a href={href}>
        <BlogPostWrapper
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          className={hovered && "hovered"}
        >
          <div className="left-side">
            <div className="img-container" style={imgStyle}></div>
            <div className="title-date-container">
              <p className="title">{title}</p>
              <p className="date">{date}</p>
            </div>
          </div>
          <img
            className="blogpost-arrow"
            src={
              hovered || window.screen.width <= 768 ? filledArrow : emptyArrow
            }
            alt="arrow"
          />
        </BlogPostWrapper>
      </a>
    </>
  );
};

export default BlogPost;
