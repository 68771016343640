import styled from "styled-components";

export const NotFoundWrapper = styled.div`
  .section-head {
    padding: 120px 0;
    h2 {
      font-weight: 700;
      font-size: 96px;
      line-height: 117px;
      color: #bababa;
      letter-spacing: 0.3em;
      margin-bottom: 80px;
    }
    .lower-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      p {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: right;
        text-transform: uppercase;
        color: #bababa;
        margin-bottom: 60px;
      }
      button {
        max-width: 272px;
      }
    }
  }

  @media (max-width: 1230px) {
    .section-head {
      h2 {
        font-weight: 700;
        font-size: 48px;
        line-height: 55px;
      }
    }
  }
  @media (max-width: 1020px) {
    .section-head {
      width: 100%;
      padding: 60px 20px;
      h2 {
        font-size: 48px;
        line-height: 55px;
      }
    }
  }
  @media (max-width: 768px) {
    .section-head {
      h2 {
        font-size: 36px;
        line-height: 41px;
      }
      .lower-wrapper {
        button {
          min-width: 100%;
          color: #232025;
          background-color: #fff;
        }
      }
    }
  }
  @media (max-width: 480px) {
    .section-head {
      h2 {
        font-size: 30px;
        line-height: 41px;
      }
      .lower-wrapper {
        p {
          font-size: 14px;
          margin-bottom: 30px;
        }
      }
    }
  }
`;
