import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const AdminWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  position: fixed;
  & .MuiPaper-root {
    min-width: 200px;
    height: 100%;
    overflow-y: auto;
  }
  & .MuiList-root {
    width: 200px;
    font-size: 18px;
    & .MuiButtonBase-root {
      padding: 0 10px;
      &.logo {
        padding: 20px 30px;
        cursor: default;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
  & .MuiFormControl-root {
    & .Mui-focused fieldset {
      border-color: #24ae85;
    }
    & .Mui-error {
      &.Mui-focused fieldset {
        border-color: #d32f2f;
      }
    }
    & .MuiInputLabel-root {
      &.Mui-focused {
        color: #24ae85;
      }
    }
    & .Mui-focused {
      & .MuiSvgIcon-root {
        color: #24ae85;
      }
    }
  }
  & .MuiTableRow-head {
    background-color: #e8e8e8;
  }
  & .MuiTableCell-root {
    vertical-align: top;
    & .arrow-wrapper {
      display: flex;
      align-items: center;
    }
  }
`;

export const CustomLink = styled(NavLink)`
  width: 100%;
  font-size: 20px;
  padding: 6px 16px;
  color: #232025;
  &.active {
    color: #24ae85;
  }
`;

export const Content = styled.div`
  padding: 10px 20px 40px 20px;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 55px;
  overflow: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.23);
    border-radius: 5px;
  }
  & .logout-btn {
    font-weight: bold;
    font-size: 16px;
    align-self: flex-end;
    margin-top: 20px;
    background-color: #24ae85;
    &:hover {
      background-color: #24ae85;
      opacity: 0.9;
    }
  }
`;

export const FormWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 50px;
  & .add {
    max-width: 200px;
    padding: 10px;
  }
  & .MuiButton-contained {
    color: #fff !important;
    background-color: #24ae85 !important;
    font-weight: bold;
  }
  & .MuiButtonBase-root {
    color: #24ae85;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  width: 100%;
  & .MuiFormControl-root {
    width: 100%;
    max-width: 450px;
  }
  & .MuiOutlinedInput-root {
    width: 100%;
    max-width: 450px;
  }
  & .MuiInputBase-multiline {
    width: 100%;
    max-width: 450px;
  }
  & .disabled-bold {
    & .Mui-disabled {
      color: #232025;
      -webkit-text-fill-color: #232025;
      & fieldset {
        border-color: #232025;
      }
    }
  }
  & .btns {
    display: flex;
    align-items: center;
    gap: 10px;
    & .MuiButtonBase-root {
      font-size: 18px;
      border-color: #24ae85;
      padding: 8px 20px;
    }
  }
  & .save-btn {
    &.MuiButtonBase-root {
      min-width: 150px;
      font-weight: bold;
      color: #fff;
      background-color: #24ae85;
      border: 1px solid #24ae85;
      &.Mui-disabled {
        background-color: #fff !important;
        color: #24ae85 !important;
      }
    }
  }
`;

export const EmptySelect = styled.div`
  padding: 5px 10px;
`;

export const FileInputWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  gap: 20px;
  flex-direction: column;
  & input {
    display: none;
  }
  & label {
    cursor: pointer;
    color: #24ae85;
    border: 1px solid #24ae85;
    border-radius: 4px;
    padding: 10px 15px;
    text-transform: capitalize;
    width: fit-content;
    &:hover {
      opacity: 0.9;
    }
  }
  & .img-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 2px;
    & .MuiButtonBase-root {
      min-width: 0;
      padding: 0;
    }
    & img {
      width: 300px;
      height: auto;
      object-fit: contain;
    }
  }
  & .img-wrapper-admin {
    & .MuiTooltip-popper {
      margin-bottom: 5px;
    }
    & .MuiButtonBase-root {
      min-width: 0;
      padding: 0;
      float: right;
      margin-bottom: 2px;
      & .MuiSvgIcon-root {
        font-size: 30px;
        color: #a8a8a8;
        &:hover {
          color: #24ae85;
        }
      }
    }
    & .img-btns {
      float: right;
      margin-top: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      & .MuiButtonBase-root {
        padding: 4px 12px;
        font-size: 14px;
        border-color: #24ae85;
      }
    }

    & img {
      width: 100%;
      height: auto;
    }

    &.about {
      & .img-btns {
        float: unset;
      }
      & img {
        width: 400px;
        height: auto;
      }
    }
  }
  & .err {
    color: #d32f2f;
    font-size: 16px;
  }
`;

export const Products = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  & .MuiTableContainer-root {
    margin-bottom: 30px;
  }
  & .MuiTable-root {
    min-width: 900px;
  }
  & .imgs {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  & .date-input {
    width: 150px;
  }
  & .MuiTableCell-root {
    &.long-text {
      min-width: 300px;
    }
  }
  & .title {
    & .MuiTableCell-root {
      font-weight: 700;
    }
  }
`;

export const Link = styled(NavLink)`
  color: #24ae85;
  font-weight: 500;
  text-decoration: underline;
  width: max-content;
  &:before {
    content: "< ";
  }
`;

export const BtnLink = styled(NavLink)`
  background-color: #24ae85;
  padding: 10px 16px;
  color: #fff;
  width: fit-content;
  border-radius: 4px;
  text-transform: capitalize;
  &:hover {
    opacity: 0.9;
  }
  align-self: flex-end;
`;

export const Container = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  & .MuiTableContainer-root {
    margin-bottom: 30px;
  }
  & .MuiTable-root {
    min-width: 900px;
    & .expired {
      color: rgba(35, 32, 37, 0.5);
    }
    & .Mui-disabled {
      color: rgba(35, 32, 37, 0.5);
    }
  }

  & .link-cell {
    & a {
      color: #232025;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  & .title {
    & .MuiTableCell-root {
      font-weight: 700;
    }
  }
  & .small-img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
  & .MuiButtonBase-root {
    color: #24ae85;
  }
`;

export const InfoField = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  width: 100%;
  & .flex-wrapper {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    width: 100%;
  }
  & .MuiButtonBase-root {
    font-size: 18px;
    padding: 0;
    color: #a8a8a8;
    line-height: 20px;
    &:hover {
      background-color: #fff;
      color: #24ae85;
    }
  }
  & .MuiFormLabel-root {
    font-size: 18px;
    font-weight: bold;
  }
  & .barber-btn {
    &.MuiButtonBase-root {
      padding: 8px 24px;
      font-size: 18px;
      color: #fff;

      background-color: #24ae85;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  & .barber-btns {
    width: 100%;
  }
`;

export const TextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  max-width: 450px;
  & .err {
    color: #d32f2f;
    font-size: 12px;
  }
  & .helper-text {
    font-size: 12px;
  }
`;

export const EmptyPageText = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: #a8a8a8;
  margin: 20px;
`;

export const Filters = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  & .MuiFormControl-root {
    min-width: 150px;
  }
  & .searchbar {
    min-width: 200px;
  }
`;

export const ActionBtns = styled.div`
  display: flex;
  gap: 5px;

  & .MuiButtonBase-root {
    min-width: 0;
    padding: 5px;
  }
  & .MuiSvgIcon-root {
    color: #a8a8a8;
    &:hover {
      color: #24ae85;
    }
  }
`;

export const PageTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 4px 6px;
  & .title {
    font-size: 12px;
    font-weight: bold;
  }
`;

export const Title = styled.div`
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #e8e8e8;
`;
