import React, { useState, useEffect } from "react";
import { Section } from "../../shared/styled";
import {
  BarbershopParallaxWrapper,
  Divider,
} from "./styled";

const BarbershopParallax = () => {
  const [toRightAmount, setToRightAmount] = useState(0);
  const [toLeftAmount, setToLeftAmount] = useState(0);

  useEffect(() => {
    var scrollableElement = document.body; //document.getElementById('scrollableElement');
    function checkScrollDirection(event) {
      if (checkScrollDirectionIsUp(event)) {
        setToRightAmount((prevState) => prevState - 20);
        setToLeftAmount((prevState) => prevState + 20);
      } else {
        setToRightAmount((prevState) => prevState + 20);
        setToLeftAmount((prevState) => prevState - 20);
      }
    }

    function checkScrollDirectionIsUp(event) {
      if (event.wheelDelta) {
        return event.wheelDelta > 0;
      }
      return event.deltaY < 0;
    }
    scrollableElement.addEventListener("wheel", checkScrollDirection);


    var touchPos;

    // store the touching position at the start of each touch
    document.body.ontouchstart = function (e) {
      touchPos = e.changedTouches[0].clientY;
    };

    // detect wether the "old" touchPos is
    // greater or smaller than the newTouchPos
    document.body.ontouchmove = function (e) {
      let newTouchPos = e.changedTouches[0].clientY;
      if (newTouchPos > touchPos) {
        setToRightAmount((prevState) => prevState + 20);
        setToLeftAmount((prevState) => prevState - 20);
      }
      if (newTouchPos < touchPos) {
        setToRightAmount((prevState) => prevState - 20);
        setToLeftAmount((prevState) => prevState + 20);
      }
    };
  }, []);

  return (
    <BarbershopParallaxWrapper toRight={toRightAmount} toLeft={toLeftAmount}>
      <div className="lower-carousel">
        <Section className="section">
          <Divider />
        </Section>
        <div className="parallax-wrapper">
          <div className="parallax-row to-right">
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
            <p>CARE</p>
            <p className="stroke">CARE</p>
          </div>
          <Section className="section">
            <Divider />
          </Section>
          <div className="parallax-row to-left">
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
            <p className="stroke">QUALITY</p>
            <p>QUALITY</p>
          </div>
          <Section className="section">
            <Divider />
          </Section>
          <div className="parallax-row to-right">
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
            <p>SELF-CONFIDENCE</p>
            <p className="stroke">SELF-CONFIDENCE</p>
          </div>
          <Section className="section">
            <Divider />
          </Section>
        </div>
      </div>
    </BarbershopParallaxWrapper>
  );
};

export default BarbershopParallax;
