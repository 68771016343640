import styled from "styled-components";

export const Address = styled.div`
  display: flex;
  align-items: stretch;
  max-width: 250px;
  gap: 5px;
  & .MuiButtonBase-root {
    padding: 0;
    min-width: 0;
    color: #24ae85;
  }
  & .MuiTextField-root {
    min-width: 180px;
  }
  position: relative;
`;

export const Btns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ErrMessage = styled.p`
  position: absolute;
  left: 0;
  color: #d32f2f;
  bottom: -15px;
`;
