import React, { useEffect, useState } from "react";
import { ContainedButton, PageWrapper, Section } from "../../shared/styled";
import Footer from "../footer/Footer";
import { SingleProductWrapper, Divider } from "./styled";
import singleProductPlaceholder from "../../assets/single-product-placeholder.jpg";
import { ProductCardGrid } from "../../shared/product-card/styled";
import ProductCard from "../../shared/product-card/ProductCard";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Outlet, Link } from "react-router-dom";
import axios from "axios";
import PATH from "../../constants/path";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import SuccessDialog from "./SuccessDialog";

const Product = (props) => {
  const [singleProduct, setSingleProduct] = useState({});
  const [descriptionActive, setDescriptionActive] = useState(true);
  const [howToUseActive, setHowToUseActive] = useState(false);
  const [productCount, setProductCount] = useState(1);
  const [availability, setAvailability] = useState(0);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);
  const [activeClass, setActiveClass] = useState("active");
  const [embedLink, setEmbedLink] = useState("");
  const [productImages, setProductImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [recents, setRecents] = useState(
    JSON.parse(localStorage.getItem("rec")) || []
  );

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const location = useLocation();
  const token = localStorage.getItem("token");

  const handleDescriptionClick = () => {
    setDescriptionActive(true);
    setHowToUseActive(false);
  };
  const handleHowToUseClick = () => {
    setHowToUseActive(true);
    setDescriptionActive(false);
  };
  const handleSubtractClick = () => {
    setProductCount(productCount > 1 ? productCount - 1 : 1);
  };
  const handleAddClick = () => {
    setProductCount(productCount + 1);
  };

  const slug =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  useEffect(() => {
    axios
      .get(`${PATH}/api/products/${slug}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response?.data?.data?.["single-product"]?.[0];
        handleAddToRecentlyViewed(data);
        setProductImages(data?.image);
        setSingleProduct(data);
        setAvailability(data.availability);
        setEmbedLink(
          `https://www.youtube.com/embed/${data.product_info.product_how_to_use_video_link}`
        );
      });

    axios
      .get(`${PATH}/api/products/related-products/${slug}`)
      .then((response) => {
        setRelatedProducts(response.data.data["related-products"]);
      });

    axios
      .get(`${PATH}/api/products/recommended-product/all`)
      .then((response) => {
        setRecommendedProducts(response.data.data?.recommended_product);
      });
    if (token) {
      axios
        .get(`${PATH}/api/recently-viewed/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data.data.recently_viewed_product, "res");
          setRecentlyViewedProducts(
            response.data.data?.recently_viewed_product
          );
        });
    }
  }, [slug]);

  const handleOpenDialog = () => {
    setOpenSuccessDialog(true);
    setTimeout(() => setOpenSuccessDialog(false), 4000);
  };

  const addToCart = () => {
    props.rerenderParentCallback();
    if (token) {
      axios
        .post(
          `${PATH}/api/add-to-cart/store`,
          {
            product_id: singleProduct?.id,
            quantity: productCount,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(handleOpenDialog);
    } else {
      let localStorageCartArr = JSON.parse(localStorage.getItem("storageCart"));

      let includes = false;

      for (let item of localStorageCartArr) {
        if (item && item.product_id === singleProduct.id) {
          includes = item;
        }
      }
      if (!includes) {
        localStorageCartArr = [
          ...localStorageCartArr,
          {
            product_id: singleProduct.id,
            quantity: productCount,
          },
        ];
      } else {
        localStorageCartArr = localStorageCartArr.filter(
          (el) => el.product_id !== includes.product_id
        );
        localStorageCartArr = [
          ...localStorageCartArr,
          {
            product_id: includes.product_id,
            quantity: includes.quantity + productCount,
          },
        ];
      }
      localStorage.setItem("storageCart", JSON.stringify(localStorageCartArr));

      let sum = 0;
      for (let item of localStorageCartArr) {
        sum += item.quantity;
      }
      localStorage.setItem("cartItemsCount", sum);
    }
    handleOpenDialog();
  };

  const handleAddToRecentlyViewed = (product) => {
    if (!token) {
      const list = JSON.parse(localStorage.getItem("rec")) || [];
      const item = list.find((el) => el.id === product.id);
      const prod = {
        id: product.id,
        name: product?.product_info?.product_name,
        img: product?.image[0]?.image,
        price: product?.price,
        slug: product?.slug,
      };
      if (!item) {
        if (list.length < 5) {
          const newList = [...list, prod];
          setRecents(newList);
          localStorage.setItem("rec", JSON.stringify(newList));
        } else {
          const newList = [...list.slice(1), prod];
          setRecents(newList);
          localStorage.setItem("rec", JSON.stringify(newList));
        }
      }
    }
  };

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    autoplay: false,
    pauseOnHover: false,
  };

  return (
    <>
      <SingleProductWrapper>
        <PageWrapper>
          <Section className="single-product-section">
            {!!productImages?.length && (
              <img
                className="zoomed-image"
                src={
                  !!productImages.length &&
                  `${PATH}/${productImages[currentImageIndex]?.image}`
                }
                alt="moruq"
              />
            )}
            <div className="right-side">
              <h2>{singleProduct?.product_info?.product_name}</h2>
              <div className="same-category-products">
                {/* {!!relatedProducts?.length &&
                  relatedProducts.map((relatedProduct) => {
                    return (
                      <Link
                        key={relatedProduct?.slug}
                        to={`/product/${relatedProduct?.slug}`}
                        onClick={() => setProductCount(1)}
                      >
                        {relatedProduct?.name}
                      </Link>
                    );
                  })} */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <svg
                        width="24"
                        height="13"
                        viewBox="0 0 24 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.7929 0.999969L12.0001 11.7929L1.20711 0.999969H22.7929Z"
                          stroke="white"
                        />
                      </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>{singleProduct?.product_info?.product_name}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    {!!relatedProducts?.length &&
                      relatedProducts.map((relatedProduct) => {
                        return (
                          <Link
                            onClick={() => setProductCount(1)}
                            key={relatedProduct?.slug}
                            to={`/product/${relatedProduct?.slug}`}
                          >
                            {relatedProduct?.name}
                          </Link>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              </div>

              <div className="selected-product-images">
                <form>
                  {!!productImages?.length &&
                    productImages.map((img, index) => {
                      return (
                        <div key={img?.id} className="radio-img-container">
                          <label>
                            <input
                              onChange={() => {
                                setActiveClass("");
                                setCurrentImageIndex(index);
                              }}
                              type="radio"
                              name="image"
                            />
                            <img
                              className={index === 0 ? activeClass : ""}
                              src={`${PATH}/${productImages[index]?.image}`}
                              alt="img"
                            />
                          </label>
                        </div>
                      );
                    })}
                </form>
              </div>

              <p className="product-price">
                {singleProduct?.price && `${singleProduct?.price} AMD`}
              </p>
              <div className="product-counter-wrapper">
                <div className="product-counter">
                  <button onClick={handleSubtractClick} className="minus">
                    -
                  </button>
                  <p className="count">{productCount > 0 ? productCount : 1}</p>
                  <button onClick={handleAddClick} className="plus">
                    +
                  </button>
                </div>
                {!availability && singleProduct?.price && (
                  <span className="products-left">{`OUT OF STOCK`}</span>
                )}
              </div>
              <ContainedButton onClick={addToCart} className="add-to-cart">
                ADD TO CART
              </ContainedButton>
              <div className="description-navigation">
                <p
                  className={descriptionActive ? "active" : ""}
                  onClick={handleDescriptionClick}
                >
                  DESCRIPTION
                </p>
                <p
                  className={howToUseActive ? "active" : ""}
                  onClick={handleHowToUseClick}
                >
                  HOW TO USE
                </p>
              </div>
              <div className="description-content">
                <p>
                  {descriptionActive
                    ? singleProduct?.product_info?.product_description
                    : singleProduct?.product_info
                        ?.product_how_to_use_description}
                </p>
                {!descriptionActive &&
                singleProduct?.product_info?.product_how_to_use_video_link ? (
                  <iframe
                    width="100%"
                    height="315"
                    src={embedLink}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : null}
              </div>
            </div>
          </Section>
          <Section className="single-product-section-responsive">
            <div className="container">
              <Slider {...settings}>
                {productImages &&
                  productImages.map((img) => {
                    return (
                      <div key={img?.id}>
                        <img src={`${PATH}/${img?.image}`} alt="" />
                      </div>
                    );
                  })}
              </Slider>
            </div>
            <div className="accordion-wrapper">
              <Accordion>
                <AccordionSummary
                  expandIcon={
                    <svg
                      width="24"
                      height="13"
                      viewBox="0 0 24 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.7929 0.999969L12.0001 11.7929L1.20711 0.999969H22.7929Z"
                        fill="white"
                        stroke="white"
                      />
                    </svg>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>{singleProduct?.product_info?.product_name}</p>
                </AccordionSummary>
                <AccordionDetails>
                  {!!relatedProducts?.length &&
                    relatedProducts.map((relatedProduct) => {
                      return (
                        <Link
                          key={relatedProduct?.slug}
                          to={`/product/${relatedProduct?.slug}`}
                        >
                          {relatedProduct?.name}
                        </Link>
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            </div>
            <p className="product-price">
              {singleProduct?.price && `${singleProduct?.price} AMD`}
            </p>
            <div className="product-counter-wrapper">
              <div className="product-counter">
                <button onClick={handleSubtractClick} className="minus">
                  -
                </button>
                <p className="count">{productCount}</p>
                <button onClick={handleAddClick} className="plus">
                  +
                </button>
              </div>
              {!availability && singleProduct?.price && (
                <span className="products-left">{`OUT OF STOCK`}</span>
              )}
            </div>
            <ContainedButton onClick={addToCart} className="add-to-cart">
              ADD TO CART
            </ContainedButton>
            <div className="description-navigation">
              <p
                className={descriptionActive ? "active" : ""}
                onClick={handleDescriptionClick}
              >
                DESCRIPTION
              </p>
              <p
                className={howToUseActive ? "active" : ""}
                onClick={handleHowToUseClick}
              >
                HOW TO USE
              </p>
            </div>
            <div className="description-content">
              <p>
                {descriptionActive
                  ? singleProduct?.product_info?.product_description
                  : singleProduct?.product_info?.product_how_to_use_description}
              </p>
              {!descriptionActive &&
              singleProduct?.product_info?.product_how_to_use_video_link ? (
                <iframe
                  width="100%"
                  height="315"
                  src={embedLink}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : null}
            </div>
          </Section>
          {!!recommendedProducts?.length && (
            <Section className="recommendations-section">
              <h3>RECOMMENDATIONS</h3>
              <Divider />
              <ProductCardGrid className="recommendations-grid">
                {recommendedProducts.map((recommendedProduct) => {
                  return (
                    <Link
                      key={recommendedProduct?.id}
                      to={`/product/${recommendedProduct.slug}`}
                    >
                      <ProductCard
                        productImg={
                          recommendedProduct?.image
                            ? `${PATH}/${recommendedProduct?.image?.[0].image}`
                            : singleProductPlaceholder
                        }
                        productName={recommendedProduct?.name}
                        productPrice={`${recommendedProduct?.price}.00`}
                      />
                    </Link>
                  );
                })}
              </ProductCardGrid>
            </Section>
          )}
          {token && !!recentlyViewedProducts?.length && (
            <Section className="recently-viewed-section">
              <h3>RECENTLY VIEWED</h3>
              <Divider />
              {
                <ProductCardGrid className="recently-viewed-grid">
                  {recentlyViewedProducts?.map((recentlyViewedProduct) => {
                    return (
                      <a
                        key={recentlyViewedProduct?.id}
                        href={`/product/${recentlyViewedProduct.slug}`}
                      >
                        <ProductCard
                          productImg={
                            recentlyViewedProduct?.images
                              ? `${PATH}/${recentlyViewedProduct?.images}`
                              : singleProductPlaceholder
                          }
                          productName={recentlyViewedProduct?.name}
                          productPrice={`${recentlyViewedProduct?.price}.00`}
                        />
                      </a>
                    );
                  })}
                </ProductCardGrid>
              }
            </Section>
          )}
          {!token &&
            !!recents?.filter((item) => item.id !== singleProduct?.id)
              .length && (
              <Section className="recently-viewed-section">
                <h3>RECENTLY VIEWED</h3>
                <Divider />
                {
                  <ProductCardGrid className="recently-viewed-grid">
                    {recents
                      .filter((item) => item.id !== singleProduct?.id)
                      .slice(0, 4)
                      .map((item) => {
                        return (
                          <a key={item?.id} href={`/product/${item.slug}`}>
                            <ProductCard
                              productImg={`${PATH}/${item.img}`}
                              productName={item?.name}
                              productPrice={`${item?.price}.00`}
                            />
                          </a>
                        );
                      })}
                  </ProductCardGrid>
                }
              </Section>
            )}
          <Footer />
        </PageWrapper>
      </SingleProductWrapper>
      {openSuccessDialog && (
        <SuccessDialog
          handleClose={() => setOpenSuccessDialog(false)}
          open={openSuccessDialog}
          product={singleProduct}
        />
      )}
      <Outlet />
    </>
  );
};

export default Product;
