import React, { useState, useEffect } from "react";
import {
  Divider,
  SubHeaderWrapper,
  SubHeader,
  ExpandBtn,
  SubTitle,
  Form,
  Inputs,
  InputWrapper,
  Input,
  InputsWrapper,
  TextBtn,
  TextBtnWrapper,
  Btn,
  Line,
  Addresses,
  PhoneWrapper,
  Message,
} from "./styled";
import NumberInput from "../../shared/NumberInput";
import AddressCard from "../../shared/address-card/AddressCard";
import addIcon from "../../assets/account/addIcon.svg";
import EditPassword from "./EditPassword";
import AddDeliveryAddress from "../../shared/address-card/AddDeliveryAddress";
import path from "../../constants/path";
import axios from "axios";

const Settings = () => {
  const [expandDetails, setExpandDetails] = useState(false);
  const [expandAdd, setExpandAdd] = useState(false);
  const [openEditPassword, setOpenEditPassword] = useState(false);
  const [openAddDeliveryAdd, setOpenAddDeliveryAdd] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [err, setErr] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState("");

  const handleErrorRemove = (val) => {
    delete errors[val];
  };

  const [addresses, setAddresses] = useState([]);
  const [reloadAddresses, setReloadAddresses] = useState(false);

  const handleReloadAddresses = () => {
    setReloadAddresses(!reloadAddresses);
  };

  const token = localStorage.getItem("token");
  useEffect(() => {
    token &&
      axios
        .get(`${path}/api/get-user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const user = res.data.data.user;
          setName(user.name);
          setPhone(user.phone.slice(4));
          setEmail(user.email);
        });
  }, []);

  useEffect(() => {
    token &&
      axios
        .get(`${path}/api/users/address/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => setAddresses(res.data.data["user-address"]));
  }, [reloadAddresses]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    axios
      .put(
        `${path}/api/users/edit/data`,
        {
          name,
          email,
          phone: `+374${phone}`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setSuccess("Account details changed successfully");
        setErr("");
        setErrors({});
      })
      .catch((err) => {
        setSuccess("");
        setErr(err.response.data.message);
        setErrors(err.response.data.data);
      })
      .finally(() => setTimeout(() => setSuccess(""), [3000]));
  };

  return (
    <>
      <Divider />
      <>
        <SubHeaderWrapper>
          <SubHeader>account details</SubHeader>
          <ExpandBtn
            onClick={() => setExpandDetails(!expandDetails)}
            className={expandDetails && "expanded"}
          />
        </SubHeaderWrapper>
        {expandDetails && (
          <Form onSubmit={handleSubmit}>
            <InputsWrapper>
              <Inputs>
                <Line>
                  <InputWrapper>
                    <label htmlFor="name">Name</label>
                    <Input
                      id="name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        handleErrorRemove("name");
                      }}
                      className={`${errors.name && "error"}`}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <label htmlFor="email-settings">Email</label>
                    <Input
                      id="email-settings"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        handleErrorRemove("email");
                      }}
                      className={`${errors.email && "error"}`}
                    />
                  </InputWrapper>
                </Line>
                <Line>
                  <InputWrapper>
                    <label htmlFor="phone">Phone number</label>
                    <PhoneWrapper>
                      <Input
                        as={NumberInput}
                        id="phone"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                          handleErrorRemove("phone");
                        }}
                        className={`phone ${errors.phone && "error"}`}
                      />
                      <div className="suff">+374</div>
                    </PhoneWrapper>
                  </InputWrapper>
                  <div />
                </Line>
              </Inputs>
              <TextBtn type="button" onClick={() => setOpenEditPassword(true)}>
                Edit password
              </TextBtn>
            </InputsWrapper>
            {success && <Message>{success}</Message>}
            {err && <Message className="error">{err}</Message>}
            <Btn>save</Btn>
          </Form>
        )}
        <Divider />
      </>
      <>
        <SubHeaderWrapper>
          <SubHeader>delivery addresses</SubHeader>
          <ExpandBtn
            onClick={() => setExpandAdd(!expandAdd)}
            className={expandAdd && "expanded"}
          />
        </SubHeaderWrapper>
        {expandAdd && (
          <>
            <SubTitle>
              {!addresses.length
                ? "no saved address"
                : "saved delivery addresses"}
            </SubTitle>
            <Addresses>
              {addresses.map((address) => (
                <AddressCard
                  key={address.id}
                  address={address}
                  reload={handleReloadAddresses}
                />
              ))}
              <div className="btn-wrapper">
                <TextBtnWrapper>
                  <img src={addIcon} alt="" />
                  <TextBtn
                    plus="plus"
                    onClick={() => setOpenAddDeliveryAdd(true)}
                  >
                    Add new delivery address{" "}
                  </TextBtn>
                </TextBtnWrapper>
              </div>
            </Addresses>
          </>
        )}
        <Divider />
      </>
      <EditPassword
        show={openEditPassword}
        closeEdit={() => setOpenEditPassword(false)}
      />
      <AddDeliveryAddress
        show={openAddDeliveryAdd}
        handleClose={() => setOpenAddDeliveryAdd(false)}
        reload={handleReloadAddresses}
      />
    </>
  );
};

export default Settings;
