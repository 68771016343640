import React, { useState, useEffect } from "react";
import path from "../../../../constants/path";
import axios from "axios";
import { Container, EmptyPageText, Title } from "../../styled";
import {
  Partners as Leaders,
  Partner as Leader,
  ActionBtns,
} from "../partners/styled";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import DeleteDialog from "../DeleteDialog";

const BrandLeaders = () => {
  const [leaders, setLeaders] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);

  const [reload, setReload] = useState(false);

  useEffect(() => {
    axios.get(`${path}/api/products/brand-leaders/all`).then((res) => {
      setLeaders(res.data.data.brand_leaders);
      setIsEmpty(res.data.data.brand_leaders.length ? false : true);
    });
  }, [reload]);

  const handleRemoveFromList = () => {
    axios
      .post(`${path}/api/products/brand-leaders/remove`, {
        product_id: deletingItem.id,
      })
      .then(() => {
        setOpenDialog(false);
        setReload(!reload);
      });
  };

  return (
    <>
      <Container>
        <Title>Brand Leaders</Title>
        {isEmpty ? (
          <EmptyPageText>Nothing to show</EmptyPageText>
        ) : (
          <Leaders>
            {leaders.map((leader) => (
              <Leader key={leader.id}>
                <ActionBtns>
                  <Tooltip title="Remove from brand leaders." placement="top">
                    <Button
                      onClick={() => {
                        setOpenDialog(true);
                        setDeletingItem(leader);
                      }}
                    >
                      <ClearIcon />
                    </Button>
                  </Tooltip>
                </ActionBtns>
                <Tooltip
                  title={leader?.product_info?.product_name}
                  placement="bottom"
                >
                  <div className="name">
                    {leader?.product_info?.product_name}
                  </div>
                </Tooltip>
                <img src={`${path}/${leader.image?.[0]?.image}`} alt="" />
              </Leader>
            ))}
          </Leaders>
        )}
      </Container>
      <DeleteDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        handleClose={() => setOpenDialog(false)}
        handleRemove={handleRemoveFromList}
        name={`product ${deletingItem?.name}`}
      />
    </>
  );
};

export default BrandLeaders;
