import React, { useEffect, useState } from "react";
import { PageWrapper, Section } from "../../shared/styled";
import Footer from "../footer/Footer";
import { BlogWrapper, Divider } from "./styled";
import BlogPost from "../../shared/blog-post/BlogPost";
import axios from "axios";
import PATH from "../../constants/path";
import moment from "moment";

const SingleBlog = () => {
  const [limitedBlogPosts, setLimitedBlogPosts] = useState([]);
  const [singlePost, setSinglePost] = useState({});
  const [embedLink, setEmbedLink] = useState(null);
  const slug =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  useEffect(() => {
    axios.get(`${PATH}/api/blogs/limitedPosts`).then((response) => {
      setLimitedBlogPosts(response.data.data.posts);
    });
    axios.get(`${PATH}/api/blogs/${slug}`).then((response) => {
      setSinglePost(response.data.data["single-post"][0]);
      setEmbedLink(response.data.data["single-post"][0]?.video_link);
    });
  }, []);

  return (
    <BlogWrapper>
      <PageWrapper>
        <Section className="single-blog-section">
          {singlePost?.image && (
            // <img src={`${PATH}/${singlePost?.image}`} alt="Blog Post" />
            <div
              className="img-container"
              style={{
                backgroundRepeat: "no-repeat",
                backgroundImage: `url("${PATH}/${singlePost?.image}")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          )}
          <div className="title-date-container">
            <h3 className="title">{singlePost?.blog_info?.title}</h3>
            <p className="date">
              {moment(singlePost?.date).format("DD.MM.YYYY")}
            </p>
          </div>
          <p className="article">{singlePost?.blog_info?.description}</p>

          {embedLink && embedLink.includes("embed") && (
            <iframe
              width="100%"
              height="315"
              src={embedLink}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </Section>
        <Divider />
        <Section className="section-blogs">
          {!!limitedBlogPosts.length &&
            limitedBlogPosts.map((blogPost) => {
              return (
                <span key={blogPost?.id}>
                  <BlogPost
                    // TODO img={`${PATH}/storage/images/${blogPost?.image}`}
                    imgStyle={{
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url("${PATH}/${blogPost?.image}")`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    title={`${blogPost?.blog_info?.title}`}
                    href={`/single-blog/${blogPost?.slug}`}
                    date={moment(blogPost?.date).format("DD.MM.YYYY")}
                  />
                  <div className="divider"></div>
                </span>
              );
            })}
        </Section>
        <Divider className="lower-divider" />
        <Footer />
      </PageWrapper>
    </BlogWrapper>
  );
};

export default SingleBlog;
