import styled from "styled-components";
import filledArrow from "../../assets/whiteArrow.svg";

export const BlogPostWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 60px 0;
  color: #bababa;
  cursor: pointer;
  @media (max-width: 1280px) {
    .blogpost-arrow {
      width: 34px;
      height: 30px;
    }
  }
  @media (max-width: 880px) {
    padding: 30px 0;
    color: #ffffff;
    .blogpost-arrow {
      width: 20px;
      height: 18px;
    }
  }
  @media (max-width: 655px) {
    padding: 30px 0 32px;
    flex-direction: column;
    position: relative;
    .blogpost-arrow {
      position: absolute;
      right: 0;
      bottom: 35px;
    }
  }
  .left-side {
    display: flex;
    justify-content: space-between;
    .img-container {
      margin-right: 30px;
      min-width: 400px;
      min-height: 280px;
    }
    .title-date-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-transform: uppercase;
      font-weight: 700;
      .title {
        margin-right: 40px;
        font-size: 48px;
        line-height: 59px;
      }
      .date {
        font-size: 16px;
        line-height: 20px;
      }
    }
    @media (max-width: 1280px) {
      img {
        margin-right: 20px;
      }
      .title-date-container {
        .title {
          font-size: 36px;
          line-height: 36px;
        }
      }
    }
    @media (max-width: 880px) {
      .img-container {
        margin-right: 20px;
        min-width: 280px;
        min-height: 196px;
      }
      .title-date-container {
        .title {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
    @media (max-width: 655px) {
      width: 100%;
      flex-direction: column;
      img {
        margin-bottom: 20px;
        margin-right: 0;
        max-width: 100%;
      }
      .img-container {
        margin: 0 0 20px;
        min-width: 280px;
        min-height: 196px;
      }
      .title-date-container {
        .title {
          margin-bottom: 20px;
        }
      }
    }
  }
  &.hovered {
    color: #ffffff;
    .title-date-container {
      .title {
        text-decoration: underline;
      }
    }
  }
`;
