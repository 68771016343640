import React, { useState, useEffect } from "react";
import path from "../../../../constants/path";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import {
  BtnLink,
  EmptySelect,
  Filters,
  EmptyPageText,
  Title,
} from "../../styled";
import { useNavigate, Outlet } from "react-router-dom";
import CustomPagination from "../../shared-admin/CustomPagination";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ActionBtns, Products } from "./styled";
import Searchbar from "../../shared-admin/Searchbar";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../DeleteDialog";
import RecommendIcon from "@mui/icons-material/Recommend";

const ProductsAdmin = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    +window.location.hash.slice(1) || 1
  );
  const [showMore, setShowMore] = useState(0);
  const [pageInfo, setPageInfo] = useState(null);

  const [isEmpty, setIsEmpty] = useState(false);

  const navigate = useNavigate();

  const [category, setCategory] = useState("all");

  const [categories, setCategories] = useState([]);

  const [reload, setReload] = useState(false);

  const [deletingItem, setDeletingItem] = useState(0);

  useEffect(() => {
    axios.get(`${path}/api/categories/all`).then((response) => {
      setCategories(response.data.data["all-categories"]);
    });
  }, []);

  const [availability, setAvailability] = useState("all");

  const [search, setSearch] = useState("");

  useEffect(() => {
    let filters = {};
    if (category && category !== "all") {
      filters.category_id = category;
    }
    if (availability !== "all") {
      filters.availability = availability;
    }
    if (search) {
      filters.search = search;
    }

    const length = Object.keys(filters).length;
    if (length) {
      navigate(`${window.location.pathname}#${1}`);
      setCurrentPage(1);
    }
    const delayFunc = setTimeout(() => {
      !length
        ? axios
            .get(`${path}/api/products?size=10&page=${currentPage}`)
            .then((res) => {
              setProducts(res.data.data.products);
              setPageInfo(res.data.data.pagination);
              setIsEmpty(!res.data.data.products.length ? true : false);
            })
        : axios
            .post(`${path}/api/products/filter`, {
              ...filters,
              size: 10,
              page: currentPage,
            })
            .then((res) => {
              setProducts(res.data.data.products);
              setPageInfo(res.data.data.pagination);
              setIsEmpty(!res.data.data.products.length ? true : false);
            });
    }, 500);
    return () => clearTimeout(delayFunc);
  }, [currentPage, availability, category, search, reload]);

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    navigate(`/admin/products#${page}`);
  };

  const makeLeader = (id) => {
    axios
      .post(`${path}/api/products/brand-leaders/store`, {
        product_id: id,
      })
      .then(() => setReload(!reload));
  };

  const removeFromLeaders = (id) => {
    axios
      .post(`${path}/api/products/brand-leaders/remove`, {
        product_id: id,
      })
      .then(() => setReload(!reload));
  };

  const makeRecommended = (id) => {
    axios
      .post(`${path}/api/products/recommended-product/store`, {
        product_id: id,
      })
      .then(() => setReload(!reload));
  };

  const removeFromRecommended = (id) => {
    axios
      .post(`${path}/api/products/recommended-product/remove`, {
        product_id: id,
      })
      .then(() => setReload(!reload));
  };

  const handleCloseDialog = () => {
    setDeletingItem(0);
  };

  const handleDelete = () => {
    axios.delete(`${path}/api/products/delete/${deletingItem?.id}`).then(() => {
      setReload(!reload);
      setDeletingItem(null);
    });
  };

  return (
    <>
      <Products>
        <Title>Products</Title>
        <BtnLink to="/admin/products/add-new">add new product</BtnLink>
        <Filters>
          <FormControl>
            <InputLabel id="category">category</InputLabel>
            <Select
              label="Category"
              labelId="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {categories.length ? (
                categories?.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))
              ) : (
                <EmptySelect>Nothing to show</EmptySelect>
              )}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="availability">Availability</InputLabel>
            <Select
              label="Availability"
              labelId="availability"
              value={availability}
              onChange={(e) => setAvailability(e.target.value)}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={true}>Available</MenuItem>
              <MenuItem value={false}>Not available</MenuItem>
            </Select>
          </FormControl>
          <Searchbar
            value={search}
            handleChange={(e) => setSearch(e.target.value)}
          />
        </Filters>
        {isEmpty ? (
          <EmptyPageText>Nothing to show</EmptyPageText>
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className="title">
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Availability</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products?.map((product) => (
                  <React.Fragment key={product.id}>
                    <TableRow
                      className={`${showMore === product.id && "expand"}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="product">
                        {!!product.image.length && (
                          <img
                            className="small-img"
                            src={`${path}/${product.image[0].image}`}
                            alt=""
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {product.product_info?.product_name}
                      </TableCell>
                      <TableCell>
                        {product.product_info?.category_name?.[0]?.name}
                      </TableCell>
                      <TableCell>{product.price}</TableCell>
                      <TableCell>
                        {product.availability ? "Available" : "Not available"}
                      </TableCell>
                      <TableCell>{product.quantity}</TableCell>
                      <TableCell align="right">
                        <ActionBtns>
                          <Tooltip
                            title={
                              product.brand_leader
                                ? "Remove from brand leaders"
                                : "Make a brand leader"
                            }
                          >
                            <Button
                              onClick={() =>
                                product.brand_leader
                                  ? removeFromLeaders(product.id)
                                  : makeLeader(product.id)
                              }
                            >
                              <StarIcon
                                className={product.brand_leader ? "leader" : ""}
                              />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title={
                              product?.recommended_product
                                ? "Remove from recommended products"
                                : "Make a recommended product"
                            }
                          >
                            <Button
                              onClick={() =>
                                product?.recommended_product
                                  ? removeFromRecommended(product.id)
                                  : makeRecommended(product.id)
                              }
                            >
                              <RecommendIcon
                                className={
                                  product.recommended_product ? "leader" : ""
                                }
                              />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <Button
                              onClick={() =>
                                navigate(`/admin/products/${product.slug}`)
                              }
                            >
                              <EditIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Button onClick={() => setDeletingItem(product)}>
                              <DeleteIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Expand">
                            <Button
                              className="expandBtn"
                              onClick={() =>
                                showMore !== product.id
                                  ? setShowMore(product.id)
                                  : setShowMore(0)
                              }
                            >
                              <ExpandMoreIcon
                                className={`${showMore && "expanded"}`}
                              />
                            </Button>
                          </Tooltip>
                        </ActionBtns>
                      </TableCell>
                    </TableRow>
                    {showMore === product.id && (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <div className="wrapper">
                            {!!product.image.length && (
                              <div className="table-content">
                                <div className="title">Images</div>
                                <div className="images">
                                  {product.image.map((img) => (
                                    <img
                                      key={img.id}
                                      src={`${path}/${img.image}`}
                                      alt=""
                                    />
                                  ))}
                                </div>
                              </div>
                            )}
                            <div className="table-content">
                              <div className="title">Description</div>
                              <div className="desc">
                                {product.product_info?.product_description}
                              </div>
                            </div>
                            <div className="table-content">
                              <div className="title">How to use</div>
                              <div className="desc">
                                <div>
                                  {
                                    product.product_info
                                      ?.product_how_to_use_description
                                  }
                                </div>
                                <a
                                  href={
                                    product.product_info
                                      ?.product_how_to_use_video
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                />
                              </div>
                            </div>
                            <div className="table-content">
                              <div className="title">SEO word</div>
                              <div className="desc">
                                {product.product_info?.seo_word}
                              </div>
                            </div>
                            <div className="table-content">
                              <div className="title">SEO description</div>
                              <div className="desc">
                                {product.product_info?.seo_description}
                              </div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {pageInfo?.pages_count > 1 && (
          <CustomPagination
            pageCount={pageInfo?.pages_count}
            currentPage={+currentPage}
            handlePageChange={handlePageChange}
          />
        )}
      </Products>
      <Outlet />
      <DeleteDialog
        open={deletingItem ? true : false}
        onClose={handleCloseDialog}
        handleClose={handleCloseDialog}
        handleRemove={handleDelete}
        name={`${deletingItem?.product_info?.product_name || "this"} product`}
      />
    </>
  );
};

export default ProductsAdmin;
