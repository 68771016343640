import React, { useState, useEffect } from "react";
import {
  AuthForm,
  Input,
  Label,
  FieldWrapper,
  CustomButton,
  Divider,
  ErrorField,
  SuccessField,
} from "./styled";
import { PhoneWrapper } from "../account/styled";
import NumberInput from "../../shared/NumberInput";
import axios from "axios";
import path from "../../constants/path";

const RegisterForm = ({ mobile, closed }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name,
      email,
      phone: `+374${phone}`,
      password,
      password_confirmation,
    };

    const storageItems = JSON.parse(localStorage.getItem("storageCart"));
    const storageItemsQuantities = [];
    const storageItemsProductIds = [];

    for (let item of storageItems) {
      storageItemsQuantities.push(item.quantity);
      storageItemsProductIds.push(item.product_id);
    }

    axios
      .post(`${path}/api/register`, data)
      .then((res) => {
        setSuccess(true);
        return res?.data?.data?.user_id;
      })
      .then((id) => {
        if (JSON.parse(localStorage.getItem("storageCart"))?.length) {
          axios.post(`${path}/api/add-to-cart/store-login`, {
            user_id: id,
            quantities: storageItemsQuantities,
            product_ids: storageItemsProductIds,
          });
        }
        localStorage.removeItem("storageCart");
        localStorage.removeItem("cartItemsCount");
        localStorage.removeItem("rec");
      })
      .catch((err) => setErrors(err.response.data.data));
  };

  const handleErrorRemove = (val) => {
    delete errors[val];
  };

  useEffect(() => {
    if (closed) {
      setName("");
      setPassword("");
      setPassword_confirmation("");
      setEmail("");
      setPhone("");
      setErrors({});
      setSuccess("");
    }
  }, [closed]);

  return (
    <AuthForm mobile={mobile} onSubmit={handleSubmit}>
      <FieldWrapper mobile={mobile}>
        <Label htmlFor="name" mobile={mobile}>
          Name
        </Label>
        <Input
          name="name"
          id="name"
          mobile={mobile}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            handleErrorRemove("name");
          }}
          className={errors.name && "error"}
        />
      </FieldWrapper>
      <FieldWrapper mobile={mobile}>
        <Label htmlFor="email" mobile={mobile}>
          Email
        </Label>
        <Input
          name="email"
          id="email"
          mobile={mobile}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            handleErrorRemove("email");
          }}
          className={errors.email && "error"}
        />
      </FieldWrapper>
      <FieldWrapper mobile={mobile}>
        <Label htmlFor="phone" mobile={mobile}>
          Phone number
        </Label>
        <PhoneWrapper className={`register`} mobile={mobile}>
          <Input
            as={NumberInput}
            name="phone"
            id="phone"
            mobile={mobile}
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              handleErrorRemove("phone");
            }}
            className={`phone ${errors.phone && "error"}`}
          />
          <div className="suff">+374</div>
        </PhoneWrapper>
      </FieldWrapper>
      <FieldWrapper mobile={mobile}>
        <Label htmlFor="passwordreg" mobile={mobile}>
          Password
        </Label>
        <Input
          name="passwordreg"
          id="passwordreg"
          type="password"
          className={`password ${errors.password && "error"}`}
          autoComplete="new-password"
          mobile={mobile}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            handleErrorRemove("password");
          }}
        />
      </FieldWrapper>
      <FieldWrapper mobile={mobile}>
        <Label htmlFor="confirmreg" mobile={mobile}>
          Confirm
        </Label>
        <Input
          name="confirmreg"
          id="confirmreg"
          type="password"
          className={`password ${errors.password_confirmation && "error"}`}
          autoComplete="new-password"
          mobile={mobile}
          value={password_confirmation}
          onChange={(e) => {
            setPassword_confirmation(e.target.value);
            handleErrorRemove("password_confirmation");
          }}
        />
      </FieldWrapper>
      {!!Object.keys(errors).length &&
        (Object.keys(errors).length === 1 &&
        errors?.password_confirmation?.[0].includes("match") ? (
          <ErrorField mobile={mobile}>
            {errors?.password_confirmation?.[0]}
          </ErrorField>
        ) : errors?.email?.[0] && errors?.email?.[0].includes("taken") ? (
          <ErrorField mobile={mobile}>{errors.email[0]}</ErrorField>
        ) : (
          <ErrorField mobile={mobile}>
            Please make sure all fields are properly filled and try again.
          </ErrorField>
        ))}
      {success && (
        <SuccessField mobile={mobile}>Please check your email.</SuccessField>
      )}
      {!!mobile && <Divider />}
      <CustomButton type="submit" className={mobile && "mobile"}>
        register
      </CustomButton>
    </AuthForm>
  );
};

export default RegisterForm;
