import React from "react";
import {
  Card,
  CardContent,
  CardText,
  CardTextBold,
  CardContentDescription,
  RemoveButton,
  CalcButton,
  Count,
} from "./styled";
import { useNavigate } from "react-router-dom";

const CardItem = ({
  productName,
  productPrice,
  productImage,
  productCount,
  onRemoveClick,
  onPlusClick,
  onMinusClick,
  slug,
  closeCart,
}) => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardContent>
        <img
          src={productImage}
          alt="product img"
          onClick={() => {
            navigate(`/product/${slug}`);
            closeCart();
          }}
        />
        <CardContentDescription>
          <div>
            <CardTextBold
              onClick={() => {
                navigate(`/product/${slug}`);
                closeCart();
              }}
            >
              {productName}
            </CardTextBold>
            <CardText> {productPrice}</CardText>
          </div>
          <RemoveButton onClick={onRemoveClick}>remove</RemoveButton>
        </CardContentDescription>
      </CardContent>
      <div>
        <CalcButton onClick={onPlusClick}>+</CalcButton>
        <Count>{productCount}</Count>
        <CalcButton onClick={onMinusClick}>-</CalcButton>
      </div>
    </Card>
  );
};

export default CardItem;
