import styled from "styled-components";
import dot from "../../assets/dot-product.png";
import activeDot from "../../assets/active-dot-product.png";

export const ProductsWrapper = styled.div`
  .upper-section {
    padding: 120px 0;
    p {
      font-weight: 700;
      font-size: 64px;
      line-height: 78px;
      color: #232025;
      filter: drop-shadow(0px 0px 1px #bababa);
      span {
        color: #bababa;
      }
    }
    @media (max-width: 768px) {
      p {
        font-size: 48px;
        line-height: 58px;
      }
    }
    @media (max-width: 480px) {
      p {
        font-size: 36px;
        line-height: 44px;
      }
    }
  }
  .products-recommendations-grid {
    padding-top: 40px;
  }
`;
export const SingleProductWrapper = styled.div`
  .single-product-section-responsive {
    display: none;
  }
  .single-product-section {
    padding: 40px 0 120px;

    img {
      max-width: 780px;
      max-height: 780px;
      margin-right: 60px;
      &.zoomed-image {
        min-width: 780px;
        min-height: 780px;
      }
    }
    .right-side {
      display: flex;
      flex-direction: column;
      h2 {
        font-weight: 700;
        font-size: 64px;
        line-height: 78px;
        color: #bababa;
        margin-bottom: 20px;
      }
      .same-category-products {
        display: flex;
        margin-bottom: 40px !important;
        p,
        a {
          font-weight: 400;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.5);
          cursor: pointer;
          &.active {
            text-decoration: underline;
            color: #ffffff;
          }
        }
        & .MuiPaper-root {
          box-shadow: none;
          background-color: inherit;
        }
        & .MuiButtonBase-root {
          min-height: 0;
          padding: 0;
        }

        & .MuiAccordionSummary-root {
          width: max-content;
          display: flex;
          align-items: center;
          gap: 20px;
          margin: 0;
          & p {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            text-transform: uppercase;
          }
          &:hover {
            & svg {
              fill: #fff;
            }
            & p {
              color: #fff;
            }
          }
        }
        & .MuiAccordionSummary-content {
          margin: 10px 0;
          & .Mui-expanded {
            margin: 0;
          }
        }
        & .MuiAccordionDetails-root {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 0;
          & a:hover {
            color: #fff;
          }
        }
      }
      .selected-product-images {
        display: flex;
        align-items: center;
        column-gap: 40px;
        margin-bottom: 50px;
        form {
          display: flex;
          align-items: center;
          column-gap: 20px;
          .radio-img-container {
            position: relative;
            input {
              width: 100%;
              position: absolute;
              height: 100%;
              opacity: 0%;
              cursor: pointer;
              &:checked + img {
                border: 1px solid #bababa;
                padding: 10px;
              }
            }
          }
        }
        img {
          max-width: 110px;
          max-height: 110px;
          min-width: 110px;
          min-height: 110px;
          margin-right: 0;
          cursor: pointer;
          box-sizing: unset;
          &.active {
            border: 1px solid #bababa;
            padding: 10px;
          }
        }
      }
      .description-navigation {
        display: flex;
        column-gap: 20px;
        margin-bottom: 20px;
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: rgba(255, 255, 255, 0.5);
          cursor: pointer;
          &.active {
            text-decoration: underline;
            color: #ffffff;
          }
        }
      }
      .description-content {
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #bababa;
          margin-bottom: 20px;
        }
      }
      .product-price {
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        color: #bababa;
        margin-bottom: 40px;
      }
      .product-counter-wrapper {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-bottom: 20px;
        .product-counter {
          display: flex;
          max-width: max-content;
          border: 1px solid #ffffff;
          button {
            width: 32px;
            height: 32px;
            border: none !important;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            text-transform: uppercase;
            color: #ffffff;
            background-color: transparent;
            cursor: pointer;
          }
          p {
            color: #fff;
            width: 34px;
            height: 32px;
            border: 1px solid #fff;
            border-width: 0 1px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .products-left {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #ff0000;
        }
      }
      button.add-to-cart {
        max-width: 272px;
        margin-bottom: 40px;
      }
    }
  }
  .recommendations-section,
  .recently-viewed-section {
    h3 {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: #bababa;
      margin-bottom: 40px;
    }
    .recommendations-grid,
    .recently-viewed-grid {
      padding-top: 40px;
    }
  }
  @media (max-width: 1440px) {
    .single-product-section {
      img {
        max-width: 567px;
        max-height: 567px;

        margin-right: 50px;
      }
      .right-side {
        h2 {
          font-size: 48px;
          line-height: 59px;
        }
        .same-category-products {
          margin-bottom: 40px;
        }
        .selected-product-images {
          column-gap: 20px;
          margin-bottom: 40px;
          img {
            max-width: 110px;
            max-height: 110px;
            min-width: 110px;
            min-height: 110px;
          }
        }
        .description-content {
          p {
            font-size: 14px;
            line-height: 17px;
          }
        }
        .product-price {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 30px;
        }
      }
    }
  }
  @media (max-width: 1220px) {
    .single-product-section {
      display: none;
    }
    .single-product-section-responsive {
      .accordion-wrapper {
        order: 2;
        margin-top: 30px;
        .MuiPaper-root {
          box-shadow: none;
          .MuiButtonBase-root {
            padding: 0;
            .Mui-expanded {
              svg {
                path {
                  fill: transparent;
                }
              }
            }
          }
          .MuiCollapse-root {
            .MuiAccordionDetails-root {
              padding: 30px 10px;
              display: flex;
              flex-direction: column;
              row-gap: 30px;
              a {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #ffffff;
              }
            }
          }
          width: min-content;
          background-color: transparent;
          font-weight: 700;
          font-size: 22px;
          line-height: 27px;
          text-transform: uppercase;
          color: #bababa;
          .MuiAccordionSummary-content {
            p {
              width: max-content;
              margin-right: 24px;
            }
          }
        }
      }
      display: flex;
      flex-direction: column;
      .slider {
        order: 1;
        .carousel-row {
          img {
            width: 100%;
          }
        }
        width: 100%;
        .carousel-dots {
          margin-top: 10px;
          li {
            width: 29%;
            button {
              &::before {
                content: "";
                height: 1px;
                width: 100%;
                background-color: #ffffff;
              }
            }
          }
        }
      }
      .product-price {
        order: 3;
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        color: #bababa;
        margin-bottom: 40px;
      }
      .product-counter-wrapper {
        display: flex;
        align-items: center;
        column-gap: 10px;
        order: 4;
        margin-bottom: 20px;
        .product-counter {
          display: flex;
          max-width: max-content;
          border: 1px solid #ffffff;
          button {
            width: 32px;
            height: 32px;
            border: none !important;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            text-transform: uppercase;
            color: #ffffff;
            background-color: transparent;
            cursor: pointer;
          }
          p {
            color: #fff;
            width: 34px;
            height: 32px;
            border: 1px solid #fff;
            border-width: 0 1px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .products-left {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #ff0000;
        }
      }
      button.add-to-cart {
        order: 5;
        max-width: 100%;
        margin-bottom: 60px;
        background: #ffffff;
        border: 1px solid #ffffff;
        color: #232025;
      }
      .description-navigation {
        order: 6;
        display: flex;
        column-gap: 20px;
        margin-bottom: 20px;
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: rgba(255, 255, 255, 0.5);
          cursor: pointer;
          &.active {
            text-decoration: underline;
            color: #ffffff;
          }
        }
      }
      .description-content {
        order: 7;
        margin-bottom: 60px;
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #bababa;
          margin-bottom: 20px;
        }
      }
    }
  }
  .container {
    background-color: transparent;
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            div {
              div {
                &:focus-visible {
                  outline: none;
                }
                img {
                  width: 100%;
                  &:focus-visible {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
      .slick-dots {
        display: flex !important;
        justify-content: space-around;
        margin-bottom: 0px;
        li {
          button::before {
            content: url(${dot});
            opacity: 1;
            left: -30px;
          }
          &.slick-active {
            button::before {
              content: url(${activeDot});
              opacity: 1;
              left: -30px;
            }
          }
        }
      }
    }
  }
`;

export const Navigation = styled.div`
  display: flex;
  column-gap: 30px;
  max-width: 100%;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  a {
    color: #bababa;
    min-width: fit-content;
    cursor: pointer;
    &.active {
      font-weight: 700;
      text-decoration: underline;
      color: #ffffff;
    }
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bababa;
`;
