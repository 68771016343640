import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HeaderWrapper, MenuCartContainer, TextButton } from "./styled";
import AddToCart from "./AddToCart";
import axios from "axios";
import PATH from "../../constants/path";

const Header = ({ headerLogo, openMenu, handleOpenMenu, updated }) => {
  const [openCart, setOpenCart] = useState(false);
  const [cartTotalCount, setCartTotalCount] = useState(0);
  const [cartGuestTotalCount, setCartGuestTotalCount] = useState(0);
  const [update, setUpdate] = useState(false);

  const location = useLocation();

  // localStorage.setItem("cartItemsCount", 0);
  const token = localStorage.getItem("token");

  let cartItemsCount = localStorage.getItem("cartItemsCount");

  const storageItems = JSON.parse(localStorage.getItem("storageCart"));
  const storageItemsQuantities = [];
  const storageItemsProductIds = [];

  for (let item of storageItems) {
    storageItemsQuantities.push(item.quantity);
    storageItemsProductIds.push(item.product_id);
  }

  const closeCart = () => {
    setOpenCart(false);
  };

  const rerenderParentCallback = () => {
    setUpdate((prev) => !prev);
  };

  useEffect(() => {
    if (token) {
      axios
        .get(`${PATH}/api/add-to-cart/show`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setCartTotalCount(response.data.data.total_count);
        });
    } else {
      if (JSON.parse(localStorage.getItem("storageCart"))?.length) {
        axios
          .post(`${PATH}/api/add-to-cart/show-guest-items`, {
            quantities: storageItemsQuantities,
            product_ids: storageItemsProductIds,
          })
          .then((res) => {
            setCartGuestTotalCount(res.data.data?.total_count);
          });
      }
    }
  }, [cartTotalCount, cartItemsCount, updated, update]);

  return (
    <>
      <HeaderWrapper className={!openMenu && "show"}>
        <Link open={openMenu} to="/">
          <img alt="logo" src={headerLogo} />
        </Link>
        <MenuCartContainer>
          {location.pathname !== "/cart" && token && (
            <TextButton onClick={() => setOpenCart(!openCart)}>
              <div>cart({+cartItemsCount + cartTotalCount})</div>
            </TextButton>
          )}
          {location.pathname !== "/cart" && !token && (
            <TextButton onClick={() => setOpenCart(!openCart)}>
              <div>cart({cartGuestTotalCount})</div>
            </TextButton>
          )}
          <TextButton onClick={handleOpenMenu}>
            <div>menu</div>
          </TextButton>
        </MenuCartContainer>
      </HeaderWrapper>
      <AddToCart
        isUpdated={updated}
        rerenderParentCallback={rerenderParentCallback}
        closeCart={closeCart}
        open={openCart}
      />
    </>
  );
};

export default Header;
