import styled, { css, keyframes } from "styled-components";

const baseFlex = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const columnFlex = css`
  display: flex;
  flex-direction: column;
`;

const linkAnimation = keyframes`
0% {transform: scaleX(0);}
50% {transform: scaleX(1);}
100% {transform: scaleX(0);}
`;

export const ContactsWrapper = styled.div`
  padding: 120px 60px;
  ${columnFlex}
  gap: 120px;
  @media (max-width: 1024px) {
    padding: 60px 20px;
    gap: 60px;
  }
`;

export const ContactInfo = styled.div`
  ${baseFlex}
  gap:20px;
  @media (max-width: 685px) {
    ${columnFlex}
    align-items: flex-start;
    gap: 30px;
  }
`;

export const InfoTitle = styled.div`
  font-weight: 700;
  font-size: 100px;
  line-height: 122px;
  text-transform: uppercase;
  color: #bababa;
  ${columnFlex}
  gap: 40px;
  & .outlined {
    color: #232025;
    filter: drop-shadow(0px 0px 1px #bababa);
  }
  min-width: 534px;
  @media (max-width: 1440px) {
    font-size: 48px;
    line-height: 59px;
    gap: 30px;
    min-width: 256px;
  }
`;

export const InfoContent = styled.div`
  ${columnFlex}
  gap: 60px;
  width: 100%;
  @media (max-width: 1440px) {
    gap: 25px;
    align-items: flex-start;
  }
  @media (max-width: 768px) {
    gap: 15px;
    align-items: flex-start;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bababa;
`;

export const InfoItem = styled.div`
  ${baseFlex}
  width:100%;
  @media (max-width: 768px) {
    ${columnFlex}
    align-items: flex-start;
    gap: 15px;
  }
`;

export const ItemTitle = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #bababa;
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Links = styled.div`
  display: flex;
  gap: 25px;
  justify-content: flex-end;
  align-items: center;
`;

export const ItemContent = styled.a`
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  text-transform: uppercase;
  color: #fff;
  text-align: right;
  position: relative;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -2px;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: right;
  }
  @media (hover: hover) {
    &:hover:after {
      transform-origin: left;
      animation-name: ${linkAnimation};
      animation-duration: 500ms;
      animation-timing-function: cubic-bezier(0.5, 0.01, 0, 0.5);
    }
  }
  @media (hover: none) {
    text-decoration: underline;
    &:after {
      display: none;
    }
  }
  @media (max-width: 1440px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 768px) {
    text-decoration: underline;
    &:after {
      display: none;
    }
    &.break {
      width: 100%;
      word-break: break-all;
      text-align: left;
    }
  }
`;

export const BackgImage = styled.div`
  & img {
    width: 100%;
    height: auto;
  }
`;

export const Section = styled.div`
  ${columnFlex}
  gap: 40px;
`;

export const SectionHeader = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  color: #bababa;
  ${baseFlex}
  @media (max-width:685px) {
    ${columnFlex}
    align-items:flex-start;
    gap: 30px;
  }
`;

export const SectionTitle = styled.div`
  font-size: 64px;
  line-height: 50px;
  @media (max-width: 1440px) {
    font-size: 48px;
  }
  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

export const SectionHeaderContent = styled.div`
  font-size: 18px;
  line-height: 25px;
  max-width: 273px;
  width: 100%;
  text-align: right;
  @media (max-width: 1440px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    text-align: left;
    font-size: 16px;
  }
`;

export const Form = styled.form`
  ${columnFlex}
  gap: 2px;
  & .MuiTextField-root {
    width: 100%;
    border: none;
    border-bottom: 1px solid #c4c4c4;
    padding: 30px 0;
    & .MuiInput-underline:after {
      border-bottom: none;
      border: 1px solid #fff;
    }
    & .MuiOutlinedInput-root {
      min-height: 0;
      color: #bababa;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      font-family: Montserrat;
      & fieldset {
        border: none;
      }
      @media (max-width: 1440px) {
        font-size: 24px;
        line-height: 29px;
      }
    }
    input {
      padding: 0;
    }
    & .MuiInputBase-multiline {
      padding: 0;
    }
  }
`;

export const Input = styled.input`
  background-color: #232025;
  color: #bababa;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  padding: 30px 0;
  &:focus {
    outline: none;
  }
  @media (max-width: 1440px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Btn = styled.button`
  margin-top: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  max-width: 272px;
  width: 100%;
  padding: 15px 0;
  background-color: #232025;
  border: 1px solid #fff;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      color: #232025;
      background-color: #fff;
    }
  }
  @media (max-width: 768px) {
    max-width: 100%;
    color: #232025;
    background-color: #fff;
  }
`;

export const Message = styled.div`
  margin-top: 10px;
  color: #bababa;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
