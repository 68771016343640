import React, { useState, useEffect } from "react";
import {
  Wrapper,
  AuthWrapper,
  CustomTypo,
  CustomLink,
  CustomButton,
} from "./RightContent.styled";
import { Divider } from "./styled";
import axios from "axios";
import path from "../../constants/path";
import { useNavigate } from "react-router-dom";

const RightMenuLoggedIn = ({ closeMenu, handleReload }) => {
  const [user, setUser] = useState({});

  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${path}/api/get-user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setUser(res.data.data.user));
  }, []);

  const handleLogOut = () => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${path}/api/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        localStorage.clear();
        navigate("/", { replace: true })
        window.location.reload();
      });
  };

  return (
    <AuthWrapper>
      <CustomTypo>{user?.name}</CustomTypo>
      <Divider />
      <Wrapper>
        <CustomLink onClick={closeMenu} to="/account/settings">
          <div>settings</div>
        </CustomLink>
        <CustomLink onClick={closeMenu} to="/account/orders">
          <div>orders</div>
        </CustomLink>
      </Wrapper>

      <CustomButton onClick={handleLogOut}>logout</CustomButton>
    </AuthWrapper>
  );
};

export default RightMenuLoggedIn;
