import React, { useEffect, useState } from "react";
import path from "../../../../constants/path";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import {
  BtnLink,
  EmptyPageText,
  Container,
  ActionBtns,
  Title,
} from "../../styled";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../shared-admin/CustomPagination";
import moment from "moment";
import DeleteDialog from "../DeleteDialog";

const BlogAdmin = () => {
  const [posts, setPosts] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    +window.location.hash.slice(1) || 1
  );
  const [pageInfo, setPageInfo] = useState(null);

  const [reload, setReload] = useState(false);

  useEffect(() => {
    axios.get(`${path}/api/blogs?size=10&page=${currentPage}`).then((res) => {
      setPosts(res.data.data.posts);
      setPageInfo(res.data.data.pagination);
      !res.data.data.posts.length && setIsEmpty(true);
    });
  }, [currentPage, reload]);

  const navigate = useNavigate();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);

  const handleDelete = () => {
    axios.delete(`${path}/api/blogs/delete/${deletingItem.id}`).then(() => {
      setReload(!reload);
      setOpenDeleteDialog(false);
    });
  };

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    navigate(`/admin/blog#${page}`);
  };

  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <Container>
        <Title>Blog</Title>
        <BtnLink to="/admin/blog/add-new">add post</BtnLink>
        {isEmpty ? (
          <EmptyPageText>Nothing to show</EmptyPageText>
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className="title">
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Video link</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {posts.map((post) => (
                  <TableRow
                    key={post.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      {post.image && (
                        <img
                          className="small-img"
                          src={`${path}/${post.image}`}
                          alt=""
                        />
                      )}
                    </TableCell>
                    <TableCell>{post.blog_info?.title}</TableCell>
                    <TableCell className="link-cell">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={post.video_link}
                      >
                        {post.video_link}
                      </a>
                    </TableCell>
                    <TableCell>{post.blog_info?.description}</TableCell>
                    <TableCell>
                      {moment(post.date).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      <ActionBtns>
                        <Tooltip title="Edit">
                          <Button
                            onClick={() => navigate(`/admin/blog/${post.slug}`)}
                          >
                            <EditIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <Button
                            onClick={() => {
                              setDeletingItem(post);
                              setOpenDeleteDialog(true);
                            }}
                          >
                            <ClearIcon />
                          </Button>
                        </Tooltip>
                      </ActionBtns>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {pageInfo?.pages_count > 1 && (
          <CustomPagination
            pageCount={pageInfo?.pages_count}
            currentPage={+currentPage}
            handlePageChange={handlePageChange}
          />
        )}
      </Container>
      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleClose}
        handleClose={handleClose}
        handleRemove={handleDelete}
        name={`${deletingItem?.blog_info?.title} post`}
      />
    </>
  );
};

export default BlogAdmin;
