import React from "react";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { CustomLink } from "./styled";
import logo from "../../assets/moruq_logo_menu.svg";

const Menu = () => {
  return (
    <Paper sx={{ width: 320, maxWidth: "100%" }}>
      <MenuList>
        <MenuItem disableRipple className="logo">
          <img src={logo} alt="" />
        </MenuItem>
        <MenuItem>
          <CustomLink to="/admin/products">Products</CustomLink>
        </MenuItem>
        <MenuItem>
          <CustomLink to="/admin/categories">Categories</CustomLink>
        </MenuItem>
        <MenuItem>
          <CustomLink to="/admin/users">Users</CustomLink>
        </MenuItem>
        <MenuItem>
          <CustomLink to="/admin/orders">Orders</CustomLink>
        </MenuItem>
        <MenuItem>
          <CustomLink to="/admin/brand-leaders">Brand leaders</CustomLink>
        </MenuItem>
        <MenuItem>
          <CustomLink to="/admin/promo-codes">Discounts</CustomLink>
        </MenuItem>
        <MenuItem>
          <CustomLink to="/admin/blog">Blog</CustomLink>
        </MenuItem>
        <MenuItem>
          <CustomLink to="/admin/partners">Partners</CustomLink>
        </MenuItem>
        <MenuItem>
          <CustomLink to="/admin/home">Home</CustomLink>
        </MenuItem>
        <MenuItem>
          <CustomLink to="/admin/contacts">Contacts</CustomLink>
        </MenuItem>
        <MenuItem>
          <CustomLink to="/admin/about">About</CustomLink>
        </MenuItem>
        <MenuItem>
          <CustomLink to="/admin/barbershop">Barbershop</CustomLink>
        </MenuItem>
      </MenuList>
    </Paper>
  );
};

export default Menu;
