import React, { useState } from "react";
import {
  Form,
  FormWrapper,
  Link,
  TextFieldWrapper,
  FileInputWrapper,
} from "../../styled";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import path from "../../../../constants/path";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddNewPartner = () => {
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState("");
  const barber = window.location.pathname.includes("/add-new/barber") ? 1 : 0;

  const [errors, setErrors] = useState({});

  const handleErrorRemove = (val) => {
    delete errors[val];
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    const data = {
      name,
      url,
      barber,
    };

    const formData = new FormData();
    image && formData.append("image", image, image.name);

    for (const key in data) {
      formData.append(key, data[key]);
    }

    axios
      .post(`${path}/api/partners/store`, formData)
      .then(() => navigate("/admin/partners", { replace: true }))
      .catch((err) => setErrors(err.response.data.message));
  };

  return (
    <FormWrapper>
      <Link to="/admin/partners">Back to partners list</Link>
      <Form>
        <TextFieldWrapper>
          <TextField
            label="Partner name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              handleErrorRemove("name");
            }}
            error={errors.name ? true : false}
          />
          {errors.name && <div className="err">{errors.name[0]}</div>}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            label="Link to website"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              handleErrorRemove("url");
            }}
            error={errors.url ? true : false}
          />
          {errors.url && <div className="err">{errors.url[0]}</div>}
        </TextFieldWrapper>
        <FileInputWrapper>
          <input
            id="product-img"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => setImage(e.target.files[0])}
          />
          <label htmlFor="product-img">add image</label>
          {image && (
            <div className="img-wrapper">
              <img src={URL.createObjectURL(image)} alt="" />
              <button type="button" onClick={() => setImage(null)}>
                <ClearIcon />
              </button>
            </div>
          )}
        </FileInputWrapper>
      </Form>
      <Button variant="contained" className="add" onClick={handleSubmit}>
        save
      </Button>
    </FormWrapper>
  );
};

export default AddNewPartner;
