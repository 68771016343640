import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import metaThumbnail from "./assets/cart-item-placeholder.jpg";

const metaDecoratorHostname = "https://moruq.am";

// const MetaDecorator = ({ title, description, imageUrl, imageAlt }) => {
//   return (
//     <Helmet>
//       <title>{title}</title>
//       <meta property="og:title" content={title} />
//       <meta name="description" content={description} />
//       <meta property="og:description" content={description} />
//       <meta property="og:image" content={metaDecoratorHostname + imageUrl} />
//       <meta
//         property="og:url"
//         content={
//           metaDecoratorHostname +
//           window.location.pathname +
//           window.location.search
//         }
//       />
//       <meta name="twitter:card" content="summary_large_image" />

//       <meta name="twitter:image:alt" content={imageAlt} />
//     </Helmet>
//   );
// };

// MetaDecorator.propTypes = {
//   title: PropTypes.string.isRequired,
//   description: PropTypes.string.isRequired,
//   imageUrl: PropTypes.string.isRequired,
//   imageAlt: PropTypes.string.isRequired,
// };

const MetaDecorator = ({ imageUrl }) => {
  return (
    <Helmet>
      <meta property="og:image" content={metaThumbnail} />
      <meta property="image" content={metaThumbnail} />
    </Helmet>
  );
};

MetaDecorator.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export default MetaDecorator;
