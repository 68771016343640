import React, { useEffect, useState } from "react";
import { PageWrapper, Section } from "../../shared/styled";
import { ProductCardGrid } from "../../shared/product-card/styled";
import ProductCard from "../../shared/product-card/ProductCard";
import { OrdersWrapper, Divider } from "./styled";
import PATH from "../../constants/path";
import axios from "axios";
import { Container, AccountWrapper, Header, Navbar, Link } from "./styled";
import Footer from "../footer/Footer";
import moment from "moment";

const SingleOrder = () => {
  const [order, setOrder] = useState([]);
  const [shippingAddress, setShippingAddress] = useState("");

  const token = localStorage.getItem("token");
  let usersName = JSON.parse(localStorage.getItem("usersName"));
  let singleOrderId = window.location.pathname.split("/")[2];

  useEffect(() => {
    axios
      .get(`${PATH}/api/users/orders/${singleOrderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setShippingAddress(response.data.data?.user_address[0]?.address);
        setOrder(response.data.data["user-single-orders"]);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container className="single-order">
        <AccountWrapper>
          <Header>
            <div className="outlined">welcome back dear</div>
            <div> {usersName}</div>
          </Header>
          <Navbar>
            <Link to="/account/orders">back to orders</Link>
          </Navbar>
        </AccountWrapper>
        <OrdersWrapper>
          <PageWrapper>
            <Divider />
            {!!order && (
              <Section key={order?.id} className="section-order">
                <span className="order-status-number row">
                  <p className="order-number">
                    ORDER NUMBER: <span>{order?.order_number}</span>
                  </p>
                  <p className="order-status">
                    ORDER STATUS: <span>{order?.global_status?.name}</span>
                  </p>
                </span>
                <span className="row">
                  <p className="order-number">
                    SHIPPING ADDRESS:{" "}
                    <span>{shippingAddress && shippingAddress}</span>
                  </p>
                </span>
                <span className="row">
                  <p className="order-number">
                    PAYMENT METHOD: <span>{order?.payment_type}</span>
                  </p>
                </span>
                <span className="row">
                  <p className="order-number">
                    DELIVERY DATE:{" "}
                    <span>
                      {order?.delivery_date
                        ? moment(order?.delivery_date).format("DD.MM.YYYY")
                        : "NOT DELIVERED"}
                    </span>
                  </p>
                </span>
                <span className="row">
                  <p className="order-number">
                    DATE OF PAYMENT:{" "}
                    <span>
                      {order?.paid_at
                        ? moment(order?.paid_at).format("DD.MM.YYYY")
                        : "NOT PAID"}
                    </span>
                  </p>
                </span>
                <ProductCardGrid className="order-grid">
                  <a
                    href={`/product/${order?.order_details?.product?.slug}`}
                  >
                    <ProductCard
                      productImg={`${PATH}/${order?.order_details?.product?.image[0]?.image}`}
                      productName={
                        order?.order_details?.product?.product_info
                          ?.product_name
                      }
                      productPrice={`${order?.order_details?.total_price}.00`}
                      productQuantity={order?.order_details?.count}
                      quantityStyle={{ display: "unset" }}
                    />
                  </a>
                </ProductCardGrid>
                <div className="buttons-container">
                  <p className="total-price">{`TOTAL ${order?.order_details?.total_price} AMD`}</p>
                </div>
                <Divider />
              </Section>
            )}
            ;
          </PageWrapper>
        </OrdersWrapper>
      </Container>
      <Footer />
    </>
  );
};

export default SingleOrder;
