import styled, { css } from "styled-components";

const positionAbsolute = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const currPadding = css`
  /* padding: 0 20px; */
`;

const customTypo = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
`;

export const Background = styled.div`
  width: 0;
  height: 0;
  &.show {
    ${positionAbsolute}
    z-index:5;
    width: 100%;
    height: 100vh;
    background-color: #232025;
    opacity: 0.9;
  }
  @media (max-width: 768px), (max-height: 625px) {
    &.show {
      opacity: 1;
      transition: background-color;
      transition-delay: 1000ms;
    }
  }
`;

export const Dialog = styled.div`
  height: 0;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  left: calc(50% - 160px);
  bottom: 50%;
  transform: translateY(50%);
  max-width: 320px;
  background-color: #232025;
  border: 1px solid #bababa;
  box-shadow: 0px 4px 30px rgba(255, 255, 255, 0.3);
  width: 100%;
  display: none;
  overflow-y: scroll;
  &.forget-password {
    max-width: 320px;
    max-height: 320px !important;
  }
  &.show {
    display: block;
    z-index: 10;
    height: 100%;
    max-height: 510px;
  }
  &.large-f-pass {
    max-height: 320px !important;
  }
  &.large {
    max-height: 530px !important;
  }
  &.log {
    max-height: 460px;
  }
  &.reg {
    max-height: 640px;
  }
  @media (max-width: 768px), (max-height: 625px) {
    display: block;
    position: fixed;
    transform: translate3d(0, 0, 0);
    bottom: 0 !important;
    left: 0;
    max-width: 100%;
    border: none;
    box-shadow: none;
    height: 0;
    transition: all 1s cubic-bezier(0.85, 0.01, 0.4, 1);
    &.forget-password {
      position: fixed;
      transform: translate3d(0, 0, 0);
      display: block;
      bottom: 0;
      left: 0;
      max-width: 100%;
      border: none;
      box-shadow: none;
      height: 0;
      transition: all 1s cubic-bezier(0.85, 0.01, 0.4, 1);
      &.show {
        max-height: 100%;
        height: 100%;
        transition: height 1s cubic-bezier(0.85, 0.01, 0.4, 1);
      }
    }
    &.show {
      max-height: 100%;
      height: 100%;
      transition: height 1s cubic-bezier(0.85, 0.01, 0.4, 1);
    }
    &.forget-password {
      max-height: 100% !important;
    }
    &.log {
      max-height: 100%;
    }
    &.reg {
      max-height: 100%;
    }
  }
`;

export const Header = styled.div`
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #232025;
  border: none;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bababa;

  @media (max-width: 768px) {
    /* width: calc(100% - 40px); */
    /* margin-left: 20px; */
    width: 100%;
  }
  &.reg {
    align-self: flex-end;
  }
`;
export const Form = styled.form`
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  &.reg {
    padding: 20px 0;
    gap: 20px;
    &.checkout-reg {
      padding: 25px 20px;
    }
  }
`;

export const InputWrapper = styled.div`
  ${currPadding}
  ${customTypo}
  display: flex;
  flex-direction: column;
  gap: 5px;
  & .MuiTextField-root {
    width: 100%;
    border: 1px solid #fff;
    & .MuiInput-underline:after {
      border-bottom: none;
      border: 1px solid #fff;
    }
    & .MuiOutlinedInput-root {
      min-height: 0;
      color: #fff;
      font-family: Montserrat;
      & fieldset {
        border: none;
      }
    }
    input {
      padding: 0;
    }
    & .MuiInputBase-multiline {
      padding: 12.5px 15px;
    }
    &.error {
      border: 1px solid #ee000e;
    }
  }
`;

export const Input = styled.input`
  ${customTypo}
  width: 100%;
  background-color: #232025;
  border: 1px solid #fff;
  padding: 15.5px 15px;
  &:focus {
    outline: none;
  }
  &.password {
    font-size: 24px;
    letter-spacing: 1.5px;
    padding: 9.5px 15px;
  }
  &.error {
    border: 1px solid #ee000e;
  }
`;

export const ButtonWrapper = styled.div`
  ${currPadding}
  width: 100%;
  @media (max-width: 768px) {
    max-width: 100%;
  }
  align-self: flex-end;
`;

export const Button = styled.button`
  width: 100%;
  background-color: #fff;
  color: #232025;
  border: none;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 15px 0;
  cursor: pointer;
`;

export const ErrorField = styled.div`
  text-align: center;
  ${currPadding}
  font-size: 12px;
  color: #fff;
  &:before {
    content: "error: ";
    color: #ee000e;
    text-transform: uppercase;
    font-weight: 700;
  }
  margin: -20px 0;
  &.reg {
    margin: unset;
  }
`;

export const SuccessField = styled.div`
  ${currPadding}
  font-size: 12px;
  color: #fff;
  text-align: center;
`;
