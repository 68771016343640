import React, { useState, useEffect } from "react";
import {
  CheckoutWrapper,
  CheckoutHeader,
  Title,
  HeaderContent,
  Divider,
  SectionTitle,
  OrderForm,
  InputsWrapper,
  Inputs,
  InputWrapper,
  Input,
  Label,
  ExpandButton,
  RadioWrapper,
  CustomRadio,
  Method,
  Total,
  TotalTitle,
  OutlinedBtn,
  Section,
  Line,
  CheckoutHeaderWrapper,
  AuthButton,
  AuthWrapper,
} from "./styled";
import Footer from "../footer/Footer";
import radioIcon from "../../assets/radioIconWhite.svg";
import TextField from "@mui/material/TextField";
import AddressCard from "../../shared/address-card/AddressCard";
import NumberInput from "../../shared/NumberInput";
import { TextBtnWrapper, TextBtn } from "../account/styled";
import addIcon from "../../assets/account/addIcon.svg";
import AddDeliveryAddress from "../../shared/address-card/AddDeliveryAddress";
import path from "../../constants/path";
import axios from "axios";
import LoginDialog from "./LoginDialog";
import ForgetPassDialog from "./ForgetPasswDialog";

import MenuItem from "@mui/material/MenuItem";
import { ClickAwayListener } from "@mui/material";
import { ContainedButton } from "../../shared/styled";
import { Section as CheckoutEmptySection } from "../../shared/styled";

const Checkout = () => {
  const [expandAddresses, setExpandAddresses] = useState(false);

  const [openAddDeliveryAdd, setOpenAddDeliveryAdd] = useState(false);

  const token = localStorage.getItem("token");

  const [checked, setChecked] = useState(0);
  const [checkedMethod, setCheckedMethod] = useState(null);
  const [addressCheckedMethod, setAddressCheckedMethod] = useState("delivery");
  const [addresses, setAddresses] = useState([]);
  const [reloadAddresses, setReloadAddresses] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState(null);
  const [comment, setComment] = useState("");
  const [promocode, setPromocode] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [applyButtonText, setApplyButtonText] = useState("APPLY");
  const [promocodeInputDisabled, setPromocodeInputDisabled] = useState(false);
  const [quantitiesArr, setQuantitiesArr] = useState([]);
  const [idsArr, setIdsArr] = useState([]);
  const [selectOpened, setSelectOpened] = useState(false);
  const [saveAddressChecked, setSaveAddressChecked] = useState(false);
  const [saveAddressDisabled, setSaveAddressDisabled] = useState(true);
  const [shoppingCart, setShoppingCart] = useState([]);

  const [err, setErr] = useState("");
  const [errors, setErrors] = useState({});

  const handleErrorRemove = (val) => {
    delete errors[val];
  };

  const storageItems = JSON.parse(localStorage.getItem("storageCart"));
  const storageItemsQuantities = [];
  const storageItemsProductIds = [];

  for (let item of storageItems) {
    storageItemsQuantities.push(item.quantity);
    storageItemsProductIds.push(item.product_id);
  }
  const handleReloadAddresses = () => {
    setReloadAddresses(!reloadAddresses);
  };

  useEffect(() => {
    token &&
      axios
        .get(`${path}/api/users/address/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setAddresses(res.data.data["user-address"]);
        });

    if (JSON.parse(localStorage.getItem("storageCart"))?.length && !token) {
      axios
        .post(`${path}/api/add-to-cart/show-guest-items`, {
          quantities: storageItemsQuantities,
          product_ids: storageItemsProductIds,
        })
        .then((res) => {
          setTotalAmount(res.data?.data?.total_amount);
          // setShoppingCart(res.data?.data["add-to-cart"]);
        });
    } else {
      axios
        .get(`${path}/api/add-to-cart/show`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setTotalAmount(response.data?.data?.total_amount);
          const data = response?.data?.data?.["add-to-cart"];
          for (let listItem of data) {
            storageItemsQuantities.push(listItem?.quantity);
            storageItemsProductIds.push(listItem?.product?.id);
          }
          setQuantitiesArr(...quantitiesArr, storageItemsQuantities);
          setIdsArr(...idsArr, storageItemsProductIds);
        });
    }
    // eslint-disable-next-line
  }, [reloadAddresses]);

  const confirmOrder = () => {
    if (!token) {
      const data = {
        name: fullName,
        city: city,
        delivery: addressCheckedMethod === "delivery" ? 1 : 0,
        delivery_in_country_side:
          city.toLowerCase() === "yerevan" || city.toLowerCase() === "erevan"
            ? 0
            : 1,
        phone: phone,
        email: email,
        comment: comment,
        address: address,
        discount_amount: discountAmount,
        bank_transaction_id: 1,
        payment_type: checkedMethod,
        quantities: storageItemsQuantities,
        product_ids: storageItemsProductIds,
      };

      axios
        .post(`${path}/api/payment/guest`, data)
        .then((res) => {
          // console.log(res);

          let redirectURL =
            checkedMethod === "non-cash" || checkedMethod === "terminal"
              ? res.data.data?.redirectURL
              : "/accepted";
          document.location.href = redirectURL;
        })
        .catch((err) => {
          // console.log(err);
          setErrors(err.response.data.message);
        });
    } else {
      let newQuantities = [];
      let newIds = [];

      for (let i = 0; i < Math.ceil(quantitiesArr.length / 2); i++) {
        newQuantities.push(quantitiesArr[i]);
      }
      for (let i = 0; i < Math.ceil(idsArr.length / 2); i++) {
        newIds.push(idsArr[i]);
      }

      const data = {
        name: fullName,
        city: city,
        delivery: addressCheckedMethod === "delivery" ? 1 : 0,
        delivery_in_country_side:
          city.toLowerCase() === "yerevan" || city.toLowerCase() === "erevan"
            ? 0
            : 1,
        phone: phone,
        email: email,
        comment: comment,
        address: address,
        discount_amount: discountAmount,
        bank_transaction_id: 1,
        payment_type: checkedMethod,
        quantities: newQuantities,
        product_ids: newIds,
      };

      axios
        .post(`${path}/api/payment/test`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          let redirectURL =
            checkedMethod === "non-cash" || checkedMethod === "terminal"
              ? res.data.data?.redirectURL
              : "/accepted";
          document.location.href = redirectURL;

          if (saveAddressChecked && phone && address && city) {
            // SAVES ADDRESS FOR USER
            axios.post(
              `${path}/api/users/address/add`,
              {
                phone: `+374${phone}`,
                address,
                additional_info: comment,
                city,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
        })
        .catch((err) => {
          // console.log(err);
          setErrors(err.response.data.message);
        });
    }
  };

  const applyPromocode = (e) => {
    e.preventDefault();
    const data = {
      code: promocode,
    };
    if (!promocodeInputDisabled && promocode) {
      axios.post(`${path}/api/promo-codes/check`, data).then((res) => {
        // console.log(res);
        promocode && setApplyButtonText("REMOVE");
        promocode && setPromocodeInputDisabled(true);
        setDiscountAmount(res.data.data?.discount_amount);
      });
    } else if (promocodeInputDisabled && promocode) {
      axios.post(`${path}/api/promo-codes/remove`, data).then((res) => {
        setApplyButtonText("APPLY");
        setPromocodeInputDisabled(false);
        setDiscountAmount(0);
        setPromocode("");
      });
    }
  };

  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openRegDialog, setOpenRegDialog] = useState(false);
  const [openForgetPassDialog, setOpenForgetPassDialog] = useState(false);
  const [shoppingCartLoaded, setShoppingCartLoaded] = useState(false);

  let storageCartArray;

  useEffect(() => {
    if (!token) {
      storageCartArray = JSON.parse(localStorage.getItem("storageCart"));
      setShoppingCart([...shoppingCart, ...storageCartArray]);
      setShoppingCartLoaded(true);
    } else {
      axios
        .get(`${path}/api/add-to-cart/show`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setShoppingCart(response.data.data?.["add-to-cart"]);
          setShoppingCartLoaded(true);
        });
    }
  }, []);

  return (
    <>
      <CheckoutWrapper>
        <CheckoutHeaderWrapper>
          <CheckoutHeader>
            <Title>{token ? "checkout" : "guest checkout"}</Title>
            <HeaderContent>
              Make sure all the information is correct
            </HeaderContent>
          </CheckoutHeader>
          {!token && (
            <AuthWrapper>
              <AuthButton
                onClick={() => {
                  setOpenLoginDialog(true);
                }}
              >
                login
              </AuthButton>
              <p>or</p>
              <AuthButton
                onClick={() => {
                  setOpenRegDialog(true);
                }}
              >
                register
              </AuthButton>
              <p> to have additional advantages</p>
            </AuthWrapper>
          )}
        </CheckoutHeaderWrapper>
        <Divider className="checkout-divider" />

        {!!shoppingCart.length && shoppingCartLoaded ? (
          <>
            <OrderForm>
              <Section>
                <SectionTitle>delivery/pickup address</SectionTitle>
                <RadioWrapper>
                  <CustomRadio>
                    <input
                      type="radio"
                      id="delivery"
                      name="delivery-method"
                      checked={
                        addressCheckedMethod === "delivery" ? true : false
                      }
                      onChange={() => {
                        setAddressCheckedMethod("delivery");
                        setCity("");
                        setAddress("");
                      }}
                      // onClick={() => {
                      //   if (addressCheckedMethod === "delivery") {
                      //     setAddressCheckedMethod(0);
                      //   } else {
                      //     setAddressCheckedMethod("delivery");
                      //   }
                      // }}
                    />
                    <label htmlFor="delivery">
                      <div className="custom-checkbox" type="button">
                        <img src={radioIcon} alt="" />
                      </div>
                    </label>
                    <Method className="method">delivery address</Method>
                  </CustomRadio>
                  <CustomRadio>
                    <input
                      type="radio"
                      id="pickup"
                      name="delivery-method"
                      // checked={addressCheckedMethod === "pickup" ? true : false}
                      onChange={() => {
                        setAddressCheckedMethod("pickup");
                        setCity("Yerevan");
                        setAddress("Yerevan, Sayat-Nova 33");
                        setErrors([]);
                      }}
                    />
                    <label htmlFor="pickup">
                      <div className="custom-checkbox" type="button">
                        <img src={radioIcon} alt="" />
                      </div>
                    </label>
                    <Method className="method">pickup address</Method>
                  </CustomRadio>
                </RadioWrapper>

                <InputsWrapper>
                  <Inputs>
                    <Line>
                      <InputWrapper>
                        <Label htmlFor="full-name">Full name</Label>
                        <Input
                          className={errors?.name && "error"}
                          onChange={(e) => {
                            setFullName(e.target.value);
                            handleErrorRemove("name");
                            // console.log(errors);
                          }}
                          value={fullName}
                          id="full-name"
                        />
                      </InputWrapper>
                      <ClickAwayListener
                        onClickAway={() => {
                          setSelectOpened(false);
                        }}
                      >
                        <InputWrapper className="select-input">
                          <Label htmlFor="city">City / Region</Label>

                          <div
                            className={`${
                              addressCheckedMethod === "pickup"
                                ? "select-box disabled"
                                : "select-box"
                            } ${errors?.city && "error"}`}
                            id="city"
                            onClick={() => {
                              if (addressCheckedMethod !== "pickup") {
                                setSelectOpened((prev) => !prev);
                              }
                            }}
                          >
                            {city}
                            {addressCheckedMethod !== "pickup" && (
                              <svg
                                style={{
                                  transform: selectOpened
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                }}
                                width="16"
                                height="8"
                                viewBox="0 0 16 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.7929 0.5L8.00004 7.29293L1.20711 0.5L14.7929 0.5Z"
                                  stroke="white"
                                />
                              </svg>
                            )}
                          </div>

                          <div
                            className="menu-items"
                            style={{ display: selectOpened ? "block" : "none" }}
                          >
                            <MenuItem
                              onClick={(e) => {
                                setCity(e.target.innerText);
                                setSelectOpened(false);
                                handleErrorRemove("city");
                                setSaveAddressDisabled(
                                  e.target.innerText !== city ? false : true
                                );
                              }}
                              style={{
                                backgroundColor: "#232025",
                                color: "#ffffff",
                                fontFamily: "Montserrat",
                              }}
                              value="yerevan"
                            >
                              Yerevan
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                handleErrorRemove("city");
                                setCity(e.target.innerText);
                                setSelectOpened(false);
                                setSaveAddressDisabled(
                                  e.target.innerText !== city ? false : true
                                );
                              }}
                              style={{
                                backgroundColor: "#232025",
                                color: "#ffffff",
                                fontFamily: "Montserrat",
                              }}
                              value="armavir"
                            >
                              Armavir
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                handleErrorRemove("city");
                                setCity(e.target.innerText);
                                setSelectOpened(false);
                                setSaveAddressDisabled(
                                  e.target.innerText !== city ? false : true
                                );
                              }}
                              style={{
                                backgroundColor: "#232025",
                                color: "#ffffff",
                                fontFamily: "Montserrat",
                              }}
                              value="ararat"
                            >
                              Ararat
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                handleErrorRemove("city");
                                setCity(e.target.innerText);
                                setSelectOpened(false);
                                setSaveAddressDisabled(
                                  e.target.innerText !== city ? false : true
                                );
                              }}
                              style={{
                                backgroundColor: "#232025",
                                color: "#ffffff",
                                fontFamily: "Montserrat",
                              }}
                              value="kotayk"
                            >
                              Kotayk
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                handleErrorRemove("city");
                                setCity(e.target.innerText);
                                setSelectOpened(false);
                                setSaveAddressDisabled(
                                  e.target.innerText !== city ? false : true
                                );
                              }}
                              style={{
                                backgroundColor: "#232025",
                                color: "#ffffff",
                                fontFamily: "Montserrat",
                              }}
                              value="shirak"
                            >
                              Shirak
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                handleErrorRemove("city");
                                setCity(e.target.innerText);
                                setSelectOpened(false);
                                setSaveAddressDisabled(
                                  e.target.innerText !== city ? false : true
                                );
                              }}
                              style={{
                                backgroundColor: "#232025",
                                color: "#ffffff",
                                fontFamily: "Montserrat",
                              }}
                              value="lori"
                            >
                              Lori
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                handleErrorRemove("city");
                                setCity(e.target.innerText);
                                setSelectOpened(false);
                                setSaveAddressDisabled(
                                  e.target.innerText !== city ? false : true
                                );
                              }}
                              style={{
                                backgroundColor: "#232025",
                                color: "#ffffff",
                                fontFamily: "Montserrat",
                              }}
                              value="gegharkunik"
                            >
                              Gegharkunik
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                handleErrorRemove("city");
                                setCity(e.target.innerText);
                                setSelectOpened(false);
                                setSaveAddressDisabled(
                                  e.target.innerText !== city ? false : true
                                );
                              }}
                              style={{
                                backgroundColor: "#232025",
                                color: "#ffffff",
                                fontFamily: "Montserrat",
                              }}
                              value="syunik"
                            >
                              Syunik
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                handleErrorRemove("city");
                                setCity(e.target.innerText);
                                setSelectOpened(false);
                                setSaveAddressDisabled(
                                  e.target.innerText !== city ? false : true
                                );
                              }}
                              style={{
                                backgroundColor: "#232025",
                                color: "#ffffff",
                                fontFamily: "Montserrat",
                              }}
                              value="aragatsotn"
                            >
                              Aragatsotn
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                handleErrorRemove("city");
                                setCity(e.target.innerText);
                                setSelectOpened(false);
                                setSaveAddressDisabled(
                                  e.target.innerText !== city ? false : true
                                );
                              }}
                              style={{
                                backgroundColor: "#232025",
                                color: "#ffffff",
                                fontFamily: "Montserrat",
                              }}
                              value="tavush"
                            >
                              Tavush
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                handleErrorRemove("city");
                                setCity(e.target.innerText);
                                setSelectOpened(false);
                                setSaveAddressDisabled(
                                  e.target.innerText !== city ? false : true
                                );
                              }}
                              style={{
                                backgroundColor: "#232025",
                                color: "#ffffff",
                                fontFamily: "Montserrat",
                              }}
                              value="vayots-dzor"
                            >
                              Vayots Dzor
                            </MenuItem>
                          </div>
                        </InputWrapper>
                      </ClickAwayListener>
                      <InputWrapper>
                        <Label htmlFor="address">Address</Label>
                        <Input
                          className={`${
                            addressCheckedMethod === "pickup" && "disabled"
                          } ${errors?.address && "error"}`}
                          disabled={
                            addressCheckedMethod !== "pickup" ? false : true
                          }
                          onChange={(e) => {
                            setAddress(e.target.value);
                            handleErrorRemove("address");
                          }}
                          onKeyDown={(e) => setSaveAddressDisabled(false)}
                          value={address}
                          id="address"
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <Label htmlFor="phone">Phone number</Label>
                        <Input
                          onChange={(e) => {
                            setPhone(e.target.value);
                            // console.log(typeof e.target.value);
                            handleErrorRemove("phone");
                          }}
                          onKeyDown={(e) => setSaveAddressDisabled(false)}
                          value={phone}
                          as={NumberInput}
                          id="phone"
                          className={errors?.phone && "error"}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <Label htmlFor="email-check-page">Email</Label>
                        <Input
                          onChange={(e) => {
                            setEmail(e.target.value);
                            handleErrorRemove("email");
                          }}
                          value={email}
                          id="email-check-page"
                          className={errors?.email && "error"}
                        />
                      </InputWrapper>
                    </Line>
                  </Inputs>
                  <InputWrapper className="additional">
                    <Label htmlFor="info">Additional information</Label>
                    <TextField
                      onChange={(e) => setComment(e.target.value)}
                      onKeyDown={(e) => setSaveAddressDisabled(false)}
                      value={comment}
                      id="info"
                      multiline
                    />
                    {(!city ||
                      !email ||
                      !fullName ||
                      !phone ||
                      !address ||
                      !checkedMethod ||
                      errors.email) &&
                      Object.keys(errors).length !== 0 && (
                        <p className="required-message">
                          <span>ERROR:</span> Please make sure all the required
                          fields are properly filled and try again.
                        </p>
                      )}
                  </InputWrapper>
                  {/* ====================================== */}

                  {token &&
                    !saveAddressDisabled &&
                    addressCheckedMethod !== "pickup" && (
                      <CustomRadio>
                        <input
                          type="checkbox"
                          id="save-address"
                          disabled={saveAddressDisabled}
                          // checked={checkedMethod === "cash" ? true : false}
                          onChange={(e) => {
                            e.target.checked
                              ? setSaveAddressChecked(true)
                              : setSaveAddressChecked(false);
                          }}
                        />
                        <label htmlFor="save-address">
                          <div className="custom-checkbox" type="button">
                            <img src={radioIcon} alt="" />
                          </div>
                        </label>
                        <Method
                          className={`method ${
                            saveAddressDisabled ? "disabled" : ""
                          }`}
                        >
                          Save delivery address
                        </Method>
                      </CustomRadio>
                    )}
                </InputsWrapper>
                {addressCheckedMethod !== "pickup" && token && (
                  <>
                    <ExpandButton
                      type="button"
                      onClick={() => setExpandAddresses(!expandAddresses)}
                      expanded={expandAddresses}
                    >
                      <div>saved delivery addresses</div>
                    </ExpandButton>
                    {expandAddresses && (
                      <>
                        <RadioWrapper>
                          {addresses?.map((address) => (
                            <CustomRadio key={address.id}>
                              <input
                                type="radio"
                                id={`${address.id}`}
                                name="address"
                                checked={
                                  checked === `${address.id}` &&
                                  saveAddressDisabled
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  setChecked(`${address.id}`);
                                  setAddress(address?.address);
                                  setPhone(address?.phone);
                                  setComment(address?.additional_info);
                                  setCity(address?.city);
                                  setSaveAddressDisabled(true);
                                  handleErrorRemove("address");
                                  handleErrorRemove("city");
                                }}
                                onClick={() =>
                                  checked === `${address.id}`
                                    ? setChecked(0)
                                    : setChecked(`${address.id}`)
                                }
                              />
                              <label htmlFor={`${address.id}`}>
                                <div className="custom-checkbox" type="button">
                                  <img src={radioIcon} alt="" />
                                </div>
                              </label>
                              <AddressCard
                                key={address.id}
                                address={address}
                                reload={handleReloadAddresses}
                                radio={"radio"}
                              />
                            </CustomRadio>
                          ))}
                          <TextBtnWrapper className="radio">
                            <img src={addIcon} alt="" />
                            <TextBtn
                              plus="plus"
                              type="button"
                              onClick={() => setOpenAddDeliveryAdd(true)}
                            >
                              Add new delivery address
                            </TextBtn>
                          </TextBtnWrapper>
                        </RadioWrapper>
                      </>
                    )}
                  </>
                )}
              </Section>
              <Divider />
              <Section className="promocode">
                <InputWrapper>
                  <TextField
                    onChange={(e) => setPromocode(e.target.value)}
                    value={promocode}
                    id="promocode"
                    multiline
                    placeholder="DISCOUNT CODE"
                    disabled={promocodeInputDisabled}
                  />
                </InputWrapper>
                <OutlinedBtn
                  onClick={applyPromocode}
                  className="apply-promocode"
                >
                  {applyButtonText}
                </OutlinedBtn>
              </Section>
              {promocodeInputDisabled && (
                <p
                  className={`promocode-message ${
                    discountAmount > 0 ? "found" : "not-found"
                  }`}
                >
                  {discountAmount > 0
                    ? "Promo-code added successfully"
                    : "Sorry, the code entered is incorrect or invalid."}
                </p>
              )}

              {city && (
                <>
                  <Divider />
                  <Section>
                    <SectionTitle
                      className={`${errors?.payment_type && "error"} `}
                    >
                      {errors?.payment_type
                        ? "select the payment method!"
                        : "payment method"}
                    </SectionTitle>
                    <RadioWrapper>
                      <CustomRadio>
                        <input
                          type="radio"
                          id="cash"
                          name="card"
                          // checked={checkedMethod === "cash" ? true : false}
                          onChange={() => {
                            setCheckedMethod("cash");
                            handleErrorRemove("payment_type");
                          }}
                          // onClick={() =>
                          //   checkedMethod === "cash"
                          //     ? setCheckedMethod(0)
                          //     : setCheckedMethod("cash")
                          // }
                        />
                        <label htmlFor="cash">
                          <div
                            className={`custom-checkbox ${
                              errors?.payment_type && "error"
                            }`}
                            type="button"
                          >
                            <img src={radioIcon} alt="" />
                          </div>
                        </label>
                        <Method
                          className={`method ${
                            errors?.payment_type && "error"
                          }`}
                        >
                          cash
                        </Method>
                      </CustomRadio>

                      <CustomRadio>
                        <input
                          type="radio"
                          id="credit"
                          name="card"
                          // checked={checkedMethod === "non-cash" ? true : false}
                          onChange={() => {
                            setCheckedMethod("non-cash");
                            handleErrorRemove("payment_type");
                          }}
                          // onClick={() =>
                          //   checkedMethod === "non-cash"
                          //     ? setCheckedMethod(0)
                          //     : setCheckedMethod("non-cash")
                          // }
                        />
                        <label htmlFor="credit">
                          <div
                            className={`custom-checkbox ${
                              errors?.payment_type && "error"
                            }`}
                            type="button"
                          >
                            <img src={radioIcon} alt="" />
                          </div>
                        </label>
                        <Method
                          className={`method ${
                            errors?.payment_type && "error"
                          }`}
                        >
                          credit card
                        </Method>
                      </CustomRadio>
                      <CustomRadio>
                        <input
                          type="radio"
                          id="terminal"
                          name="card"
                          // checked={checkedMethod === "non-cash" ? true : false}
                          onChange={() => {
                            setCheckedMethod("terminal");
                            handleErrorRemove("payment_type");
                          }}
                          // onClick={() =>
                          //   checkedMethod === "non-cash"
                          //     ? setCheckedMethod(0)
                          //     : setCheckedMethod("non-cash")
                          // }
                        />
                        <label htmlFor="terminal">
                          <div
                            className={`custom-checkbox ${
                              errors?.payment_type && "error"
                            }`}
                            type="button"
                          >
                            <img src={radioIcon} alt="" />
                          </div>
                        </label>
                        <Method
                          className={`method ${
                            errors?.payment_type && "error"
                          }`}
                        >
                          IDRAM
                        </Method>
                      </CustomRadio>
                    </RadioWrapper>
                  </Section>
                </>
              )}
            </OrderForm>
            <Divider className="checkout-divider total" />
            <div className="shipping-discount-info">
              {discountAmount && <p>DISCOUNT CODE: -{discountAmount}%</p>}
              {addressCheckedMethod === "delivery" && city && (
                <p>
                  SHIPPING PRICE:{" "}
                  {city.toLowerCase() === "yerevan" ? 1000 : 700}AMD
                </p>
              )}
            </div>
            <Total>
              <TotalTitle>
                total{" "}
                {addressCheckedMethod === "delivery"
                  ? `${
                      totalAmount &&
                      totalAmount +
                        (city &&
                          (city.toLowerCase() === "yerevan" ? 1000 : 700)) -
                        (discountAmount / 100) *
                          (totalAmount +
                            (city &&
                              (city.toLowerCase() === "yerevan" ? 1000 : 700)))
                    }`
                  : `${
                      totalAmount &&
                      totalAmount - (discountAmount / 100) * totalAmount
                    }`}{" "}
                amd
              </TotalTitle>
              <OutlinedBtn onClick={confirmOrder}>confirm order</OutlinedBtn>
            </Total>
          </>
        ) : (
          shoppingCartLoaded && (
            <CheckoutEmptySection className="empty-shopping-cart checkout">
              <p>YOU HAVE NO ITEMS IN YOUR CART LEFT</p>
              <ContainedButton
                className="checkout"
                onClick={() => {
                  window.location.replace("/products/all");
                }}
              >
                SEE PRODUCTS
              </ContainedButton>
            </CheckoutEmptySection>
          )
        )}
      </CheckoutWrapper>
      <Footer />
      <AddDeliveryAddress
        show={openAddDeliveryAdd}
        handleClose={() => setOpenAddDeliveryAdd(false)}
        reload={handleReloadAddresses}
      />
      <LoginDialog
        right={false}
        open={openLoginDialog}
        handleClose={() => {
          setOpenLoginDialog(false);
        }}
        handleOpen={() => setOpenForgetPassDialog(true)}
      />
      <LoginDialog
        right={true}
        open={openRegDialog}
        handleClose={() => {
          setOpenRegDialog(false);
        }}
        handleOpen={() => setOpenForgetPassDialog(true)}
      />
      <ForgetPassDialog
        open={openForgetPassDialog}
        handleClose={() => setOpenForgetPassDialog(false)}
      />
    </>
  );
};

export default Checkout;
