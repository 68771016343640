import React from "react";
import { Header, IconButton } from "../../shared/dialog/styled";
import closeIcon from "../../assets/closeIcon.svg";
import checkIcon from "../../assets/addedToCartIcon.svg";
import path from "../../constants/path";
import {
  HeaderContent,
  ItemWrapper,
  Item,
  ButtonsWrapper,
  BtnLink,
  CustomLink,
  Dialog,
  Background,
  Divider,
} from "./SuccessDialog.styled";

const SuccessDialog = ({ handleClose, open, product }) => {
  return (
    <>
      <Background onClick={handleClose} className={open && "show"} />
      <Dialog>
        <Header>
          <HeaderContent>
            <img src={checkIcon} alt="" />
            <div>Added to cart</div>
          </HeaderContent>
          <IconButton onClick={handleClose}>
            <img src={closeIcon} alt="close" />
          </IconButton>
        </Header>
        <Divider />
        <ItemWrapper>
          <img src={`${path}/${product.image?.[0]?.image}`} alt="" />
          <Item>
            <p className="name">{product?.product_info?.product_name}</p>
            <p>{product.price} AMD</p>
          </Item>
        </ItemWrapper>
        <Divider />
        <ButtonsWrapper>
          <BtnLink to="/checkout">go to checkout</BtnLink>
          <CustomLink to="/cart">view cart</CustomLink>
        </ButtonsWrapper>
      </Dialog>
    </>
  );
};

export default SuccessDialog;
