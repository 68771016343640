import styled, { css } from "styled-components";

const columnFlex = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const customTypo = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

const centerFlex = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  ${columnFlex}
  gap: 20px;
  padding: 20px 10px;
  border: 3px solid #fff;
  position: relative;
  width: 280px;
  &.radio {
    height: 100%;
  }
`;

export const Info = styled.div`
  ${columnFlex}
  gap:10px;
  & img {
    height: 20px;
  }
`;

export const InfoTitle = styled.div`
  ${customTypo}
  text-transform:uppercase;
  color: #bababa;
`;

export const InfoContent = styled.div`
  ${customTypo}
  color: #fff;
  word-break: break-word;
`;

export const IconBtn = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  ${centerFlex}
  background-color: #fff;
  border: 1px solid #bababa;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      opacity: 0.9;
    }
  }
`;
