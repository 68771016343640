import React, { useEffect, useState } from "react";
import {
  Cart,
  CartHeader,
  Divider,
  CartTitle,
  IconButton,
  Cards,
  CardTextBold,
  TotalSumWrapper,
  ButtonLink,
  CustomLink,
  RemoveIcon,
  Background,
  CustomLinkWrapper,
  EmptyCard,
} from "./styled";
import closeIcon from "../../assets/closeIcon.svg";
import closeIconHovered from "../../assets/closeIconHovered.svg";
import CardItem from "./CardItem";
import axios from "axios";
import PATH from "../../constants/path";

const AddToCart = ({ closeCart, open, rerenderParentCallback, isUpdated }) => {
  const [shoppingCart, setShoppingCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [updated, setUpdated] = useState(false);
  const [closeIconIsHovered, setCloseIconIsHovered] = useState(false);

  const token = localStorage.getItem("token");

  const storageItems = JSON.parse(localStorage.getItem("storageCart"));
  const storageItemsQuantities = [];
  const storageItemsProductIds = [];

  for (let item of storageItems) {
    storageItemsQuantities.push(item.quantity);
    storageItemsProductIds.push(item.product_id);
  }

  useEffect(() => {
    if (token) {
      axios
        .get(`${PATH}/api/add-to-cart/show`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setTotalAmount(response.data?.data?.total_amount);
          setTotalCount(response.data?.data?.total_count);
          setShoppingCart(response.data?.data["add-to-cart"]);
        });
    } else {
      if (JSON.parse(localStorage.getItem("storageCart"))?.length) {
        axios
          .post(`${PATH}/api/add-to-cart/show-guest-items`, {
            quantities: storageItemsQuantities,
            product_ids: storageItemsProductIds,
          })
          .then((res) => {
            setTotalCount(res.data?.data?.total_count);
            setTotalAmount(res.data?.data?.total_amount);
            setShoppingCart(res.data?.data["add-to-cart"]);
          });
      }
    }
    // eslint-disable-next-line
  }, [updated, isUpdated]);

  const handlePlusClick = (e, itemId, itemQuantity) => {
    if (token) {
      axios
        .put(
          `${PATH}/api/add-to-cart/updateQuantity/${itemId}`,
          { quantity: ++itemQuantity },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setUpdated((prev) => !prev);
          rerenderParentCallback();
        });
    } else {
      for (let item of storageItems) {
        if (itemId === item?.product_id) {
          item.quantity = item.quantity + 1;
          localStorage.setItem("storageCart", JSON.stringify(storageItems));
        }
      }

      for (let index = 0; index < storageItemsProductIds.length; index++) {
        if (itemId === storageItemsProductIds[index]) {
          storageItemsQuantities[index] = storageItemsQuantities[index] + 1;
          axios
            .post(`${PATH}/api/add-to-cart/show-guest-items`, {
              quantities: storageItemsQuantities,
              product_ids: storageItemsProductIds,
            })
            .then((res) => {
              setShoppingCart(res.data?.data["add-to-cart"]);
              setUpdated((prev) => !prev);
              rerenderParentCallback();
            });
        }
      }
    }
  };
  const handleMinusClick = (e, itemId, itemQuantity) => {
    if (token && itemQuantity >= 2) {
      axios
        .put(
          `${PATH}/api/add-to-cart/updateQuantity/${itemId}`,
          { quantity: --itemQuantity },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setUpdated((prev) => !prev);
          rerenderParentCallback();
        });
    } else if (!token && itemQuantity >= 2) {
      for (let item of storageItems) {
        if (itemId === item?.product_id) {
          item.quantity = item.quantity - 1 >= 1 ? item.quantity - 1 : 1;
          localStorage.setItem("storageCart", JSON.stringify(storageItems));
        }
      }

      for (let index = 0; index < storageItemsProductIds.length; index++) {
        if (itemId === storageItemsProductIds[index]) {
          storageItemsQuantities[index] =
            storageItemsQuantities[index] - 1 >= 1
              ? storageItemsQuantities[index] - 1
              : 1;
          axios
            .post(`${PATH}/api/add-to-cart/show-guest-items`, {
              quantities: storageItemsQuantities,
              product_ids: storageItemsProductIds,
            })
            .then((res) => {
              setShoppingCart(res.data?.data["add-to-cart"]);
              setUpdated((prev) => !prev);
              rerenderParentCallback();
            });
        }
      }
    }
  };

  const handleRemoveItem = (itemId) => {
    if (token) {
      axios
        .delete(
          `${PATH}/api/add-to-cart/remove/${itemId}`,
          { id: itemId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setUpdated((prev) => !prev);
          rerenderParentCallback();
        });
    } else {
      for (let item of storageItems) {
        if (itemId === item?.product_id) {
          // item.quantity = 0;
          let filteredArray = storageItems.filter(
            (it) => it?.product_id !== item?.product_id
          );
          localStorage.setItem("storageCart", JSON.stringify(filteredArray));
          if (JSON.parse(localStorage.getItem("storageCart"))?.length) {
            axios
              .post(`${PATH}/api/add-to-cart/show-guest-items`, {
                quantities: storageItemsQuantities,
                product_ids: storageItemsProductIds,
              })
              .then((res) => {
                setShoppingCart(res.data?.data["add-to-cart"]);
                setUpdated((prev) => !prev);
                rerenderParentCallback();
              });
          } else {
            localStorage.removeItem("storageCart");
            window.location.reload();
          }
        }
      }
    }
  };

  return (
    <>
      <Background className={open && "show"} onClick={closeCart} />
      <Cart className={`${open ? "show" : "hide"} mobile desk`}>
        <div className="container">
          <CartHeader>
            <CartTitle>Cart({totalCount && totalCount})</CartTitle>
            <IconButton onClick={closeCart}>
              <RemoveIcon
                onMouseOver={() => setCloseIconIsHovered(true)}
                onMouseOut={() => setCloseIconIsHovered(false)}
                src={closeIconIsHovered ? closeIconHovered : closeIcon}
                alt="x"
              />
            </IconButton>
          </CartHeader>
          <Divider />
          <Cards>
            {!!shoppingCart.length &&
              shoppingCart.map((item) => {
                if (!token) {
                  return (
                    <CardItem
                      productImage={`${item?.images}`}
                      key={item?.product?.id}
                      slug={item?.product?.slug}
                      productName={item?.product?.product_info?.product_name}
                      productPrice={item?.sub_total}
                      productCount={item?.quantity}
                      onRemoveClick={() => handleRemoveItem(item?.product?.id)}
                      onPlusClick={(e) =>
                        handlePlusClick(e, item?.product?.id, item?.quantity)
                      }
                      onMinusClick={(e) => {
                        handleMinusClick(e, item?.product?.id, item?.quantity);
                      }}
                      closeCart={closeCart}
                    />
                  );
                } else {
                  return (
                    <CardItem
                      productImage={`${item?.images}`}
                      key={item?.id}
                      closeCart={closeCart}
                      productName={item?.product?.product_info?.product_name}
                      productPrice={item?.sub_total}
                      productCount={item?.quantity}
                      onRemoveClick={() => handleRemoveItem(item?.id)}
                      onPlusClick={(e) =>
                        handlePlusClick(e, item?.product?.id, item?.quantity)
                      }
                      onMinusClick={(e) => {
                        handleMinusClick(e, item?.product?.id, item?.quantity);
                      }}
                    />
                  );
                }
              })}
          </Cards>
          {!!shoppingCart.length && (
            <>
              <Divider />
              <TotalSumWrapper>
                <CardTextBold>Total </CardTextBold>
                <CardTextBold>{totalAmount && totalAmount} AMD</CardTextBold>
              </TotalSumWrapper>
            </>
          )}
          {!!shoppingCart.length && (
            <>
              <ButtonLink onClick={closeCart} to="/checkout">
                go to checkout
              </ButtonLink>

              <CustomLinkWrapper>
                <CustomLink onClick={closeCart} to="/cart">
                  view cart
                </CustomLink>
              </CustomLinkWrapper>
            </>
          )}
          {!shoppingCart.length && (
            <EmptyCard>
              <div>You haven't added any product to the cart yet.</div>
              <ButtonLink onClick={closeCart} to="/products/all">
                go to products
              </ButtonLink>
            </EmptyCard>
          )}
        </div>
      </Cart>
    </>
  );
};

export default AddToCart;
