import styled, { css } from "styled-components";
import expandIconFilled from "../../assets/expand-more-icon.svg";
import expandIcon from "../../assets/expandIconOutlined.svg";

const columnFlex = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const baseFlex = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const centerFlex = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const customTypo = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

export const CheckoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 120px 60px;
  @media (max-width: 1024px) {
    padding: 60px 20px;
  }
  & .shipping-discount-info {
    display: flex;
    gap: 6px;
    flex-direction: column;
    margin-bottom: 6px;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #bababa;
    }
  }
`;

export const CheckoutHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const AuthWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 5px;
  & p {
    font-size: 18px;
    color: #bababa;
  }
`;

export const AuthButton = styled.button`
  width: max-content;
  background-color: inherit;
  border: none;
  outline: none;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  &:after {
    content: "";
    width: 0;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 0;
    bottom: -5px;
    transition: width 50ms;
  }
  &:hover:after {
    width: 100%;
  }
`;

export const CheckoutHeader = styled.div`
  ${baseFlex}
  gap: 30px;
  @media (max-width: 768px) {
    ${columnFlex}
    align-items:flex-start
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  color: #bababa;
  text-transform: uppercase;
  @media (max-width: 1024px) {
    font-size: 48px;
    line-height: 59px;
  }
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const HeaderContent = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #bababa;
  max-width: 300px;
  text-align: right;
  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 22px;
    max-width: 250px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 22px;
    max-width: 100%;
    text-align: left;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #fff;
  &.checkout-divider {
    margin: 40px 0;
    &.total {
      margin: 40px 0 30px;
    }
  }
`;

export const Section = styled.section`
  ${columnFlex}
  &.promocode {
    flex-direction: row;
    & button {
      width: 180px;
      min-width: unset;
    }
  }
`;

export const SectionTitle = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #fff;
  /* &.error {
    color: #f00;
  } */
`;

export const OrderForm = styled.form`
  ${columnFlex}
  & .promocode-message {
    position: relative;
    bottom: 20px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    &.found {
      color: #00ad5a;
      font-weight: 600;
    }
    &.not-found {
      color: #ee000e;
    }
  }
`;

export const InputsWrapper = styled.div`
  ${columnFlex};
  max-width: 1165px;
  gap: 10px;
`;

export const Inputs = styled.div`
  ${baseFlex};
  align-items: flex-start;
  gap: 15px;
  @media (max-width: 1024px) {
    ${columnFlex}
    align-items:flex-start;
    gap: 20px;
  }
`;

export const InputWrapper = styled.div`
  ${columnFlex}
  gap: 5px;
  width: 280px;
  & .MuiTextField-root,
  & .select-box {
    width: 100%;
    border: 1px solid #fff;
    border-radius: 0;
    &.error {
      border: 1px solid red !important;
    }
    &.select-box {
      height: 52px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #fff;
      letter-spacing: unset;

      & .MuiSelect-select {
        min-height: unset;
        font-family: Montserrat;
        padding-right: unset;
      }
      svg {
        position: relative;
        right: 17px;
        cursor: pointer;
      }
    }
    &:focus-visible {
      outline: none;
    }
    & .MuiInput-underline:after {
      border-bottom: none;
      border: 1px solid #fff;
    }
    & .MuiOutlinedInput-root {
      min-height: 0;
      color: #fff;
      font-family: Montserrat;
      ${customTypo}
      & fieldset {
        border: none;
      }
    }
    input {
      padding: 0;
    }
    & .MuiInputBase-multiline {
      padding: 17px 15px;
      & .Mui-disabled {
        -webkit-text-fill-color: #aeaeae;
      }
    }
    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
  &.additional {
    width: 100%;
  }
  @media (max-width: 1024px) {
    max-width: 50%;
    &.modal {
      max-width: 100%;
    }
  }
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
  &.select-input {
    position: relative;
    &.modal {
      width: 100%;
    }
    & .select-box {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 16px 17px 16px 13px;
      background-color: #232025;
      width: 100%;
      border: 1px solid #fff;
      border-radius: 0;
      min-height: 52px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #fff;
      letter-spacing: unset;
      position: relative;
      &:focus-visible {
        outline: none;
      }
      svg {
        position: absolute;
        right: 17px;
        cursor: pointer;
      }
      &.disabled {
        color: #bababa;
        border-color: #bababa;
        cursor: unset;
        svg {
          cursor: unset;
        }
      }
    }
    &:focus-visible {
      outline: none;
    }
    & .menu-items {
      width: 100%;
      position: absolute;
      top: 75px;
      border: 1px solid #fff;
      z-index: 10;
      & li {
        &:hover {
          background-color: #413b44 !important;
        }
      }
    }
  }
  & .required-message {
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    margin-top: 10px;
    & span {
      font-weight: 700;
      color: #ee000e;
    }
  }
`;

export const Line = styled.div`
  ${baseFlex}
  gap:15px;
  max-width: 1165px;
  width: 100%;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    ${columnFlex}
    align-items:flex-start;
    max-width: 100%;
    gap: 20px;
  }
`;

export const Input = styled.input`
  ${customTypo}
  color: #fff;
  padding: 16.5px 15px;
  background-color: #232025;
  border: 1px solid #fff;
  &:focus {
    outline: none;
  }
  width: 100%;
  &.disabled {
    color: #bababa;
    border-color: #bababa;
    cursor: unset;
    svg {
      cursor: unset;
    }
  }
  &.error {
    border: 1px solid red;
  }
`;

export const Label = styled.label`
  ${customTypo}
  color: #fff;
`;

export const CheckboxWrapper = styled.div`
  ${baseFlex}
  justify-content: flex-start;
  gap: 5px;
  & div {
    ${customTypo}
    color: #bababa
  }
  & input {
    display: none;
  }

  & label {
    & .custom-checkbox {
      width: 18px;
      height: 18px;
      background-color: #232025;
      border: 1px solid #fff;
      cursor: pointer;
      ${centerFlex}
    }
  }
  & input ~ label {
    & img {
      visibility: hidden;
    }
  }
  & input:checked ~ label {
    & img {
      visibility: visible;
    }
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 30px;
`;

export const CustomRadio = styled.div`
  ${baseFlex}
  justify-content:flex-start;
  align-items: center;
  gap: 10px;
  & input {
    display: none;
  }
  & label {
    & .custom-checkbox {
      width: 20px;
      height: 20px;
      background-color: #232025;
      ${centerFlex}
      border: 1px solid #fff;
      border-radius: 50%;
      cursor: pointer;
      &.error {
        border-color: #ee000e;
      }
    }
  }
  & input ~ label {
    & img {
      visibility: hidden;
    }
  }
  & input:checked ~ label {
    & img {
      visibility: visible;
    }
  }
  & input:checked ~ .method {
    font-weight: 700;
  }
`;

export const ExpandButton = styled.button`
  ${baseFlex}
  font-weight: 400;
  font-size: 16px;
  color: #bababa;
  text-transform: uppercase;
  background-color: inherit;
  border: none;
  max-width: 280px;
  width: 100%;
  cursor: pointer;
  &:after {
    content: url(${expandIcon});
    transform: ${({ expanded }) => expanded && "rotate(180deg)"};
  }
  &:hover:after {
    content: url(${expandIconFilled});
    transform: ${({ expanded }) => expanded && "rotate(180deg)"};
  }
`;

export const Method = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  &.disabled {
    visibility: hidden;
  }
  &.error {
    color: #ee000e;
  }
`;

export const Total = styled.div`
  ${baseFlex}
  @media (max-width: 768px) {
    ${columnFlex}
    align-items:flex-start;
    gap: 30px;
  }
`;

export const TotalTitle = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-transform: uppercase;
  color: #ffffff;
  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const OutlinedBtn = styled.button`
  background-color: inherit;
  color: #fff;
  border: 1px solid #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 15px;
  min-width: 272px;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      color: #232025;
      background-color: #fff;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    color: #232025;
    background-color: #fff;
  }
`;
