import React, { useEffect, useState } from "react";
import { ContainedButton, PageWrapper, Section } from "../../shared/styled";
import { ProductCardGrid } from "../../shared/product-card/styled";
import ProductCard from "../../shared/product-card/ProductCard";
import productPlaceholder from "../../assets/product-placeholder.jpg";
import Footer from "../footer/Footer";
import { ShoppingCartWrapper, Divider } from "./styled";
import axios from "axios";
import PATH from "../../constants/path";
import { useNavigate } from "react-router-dom";

const Cart = () => {
  const [shoppingCart, setShoppingCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);
  const [updated, setUpdated] = useState(false);

  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const recents = !token ? JSON.parse(localStorage.getItem("rec")) : [];

  const storageItems = JSON.parse(localStorage.getItem("storageCart"));
  const storageItemsQuantities = [];
  const storageItemsProductIds = [];

  for (let item of storageItems) {
    storageItemsQuantities.push(item.quantity);
    storageItemsProductIds.push(item.product_id);
  }

  const handlePlusClick = (e, itemId, itemQuantity) => {
    if (token) {
      axios
        .put(
          `${PATH}/api/add-to-cart/updateQuantity/${itemId}`,
          { quantity: ++itemQuantity },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setUpdated((prev) => !prev);
        });
    } else {
      for (let item of storageItems) {
        if (itemId === item?.product_id) {
          item.quantity = item.quantity + 1;
          localStorage.setItem("storageCart", JSON.stringify(storageItems));
        }
      }

      for (let index = 0; index < storageItemsProductIds?.length; index++) {
        if (itemId === storageItemsProductIds[index]) {
          storageItemsQuantities[index] = storageItemsQuantities[index] + 1;
          axios
            .post(`${PATH}/api/add-to-cart/show-guest-items`, {
              quantities: storageItemsQuantities,
              product_ids: storageItemsProductIds,
            })
            .then((res) => {
              setShoppingCart(res.data?.data["add-to-cart"]);
              setUpdated((prev) => !prev);
              // rerenderParentCallback();
            });
        }
      }
    }
  };

  const handleMinusClick = (e, itemId, itemQuantity) => {
    if (token && itemQuantity >= 2) {
      axios
        .put(
          `${PATH}/api/add-to-cart/updateQuantity/${itemId}`,
          { quantity: --itemQuantity },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setUpdated((prev) => !prev);
          // rerenderParentCallback();
        });
    } else if (!token && itemQuantity >= 2) {
      for (let item of storageItems) {
        if (itemId === item?.product_id) {
          item.quantity = item.quantity - 1 >= 1 ? item.quantity - 1 : 1;
          localStorage.setItem("storageCart", JSON.stringify(storageItems));
        }
      }

      for (let index = 0; index < storageItemsProductIds?.length; index++) {
        if (itemId === storageItemsProductIds[index]) {
          storageItemsQuantities[index] =
            storageItemsQuantities[index] - 1 >= 1
              ? storageItemsQuantities[index] - 1
              : 1;
          axios
            .post(`${PATH}/api/add-to-cart/show-guest-items`, {
              quantities: storageItemsQuantities,
              product_ids: storageItemsProductIds,
            })
            .then((res) => {
              setShoppingCart(res.data?.data["add-to-cart"]);
              setUpdated((prev) => !prev);
              // rerenderParentCallback();
            });
        }
      }
    }
  };

  const handleRemoveItem = (itemId) => {
    if (token) {
      axios
        .delete(
          `${PATH}/api/add-to-cart/remove/${itemId}`,
          { id: itemId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setUpdated((prev) => !prev);
        });
    } else {
      for (let item of storageItems) {
        if (itemId === item?.product_id) {
          // item.quantity = 0;
          let filteredArray = storageItems.filter(
            (it) => it?.product_id !== item?.product_id
          );
          localStorage.setItem("storageCart", JSON.stringify(filteredArray));
          if (JSON.parse(localStorage.getItem("storageCart"))?.length) {
            axios
              .post(`${PATH}/api/add-to-cart/show-guest-items`, {
                quantities: storageItemsQuantities,
                product_ids: storageItemsProductIds,
              })
              .then((res) => {
                setShoppingCart(res.data?.data["add-to-cart"]);
                setUpdated((prev) => !prev);
                // rerenderParentCallback();
              });
          } else {
            localStorage.removeItem("storageCart");
            window.location.reload();
          }
        }
      }
    }
  };

  useEffect(() => {
    axios
      .get(`${PATH}/api/products/recommended-product/all`)
      .then((response) => {
        setRecommendedProducts(response.data.data?.recommended_product);
      });
    if (token) {
      axios
        .get(`${PATH}/api/recently-viewed/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setRecentlyViewedProducts(
            response.data.data?.recently_viewed_product
          );
        });
    } else {
      if (JSON.parse(localStorage.getItem("storageCart"))?.length) {
        axios
          .post(`${PATH}/api/add-to-cart/show-guest-items`, {
            quantities: storageItemsQuantities,
            product_ids: storageItemsProductIds,
          })
          .then((res) => {
            setTotalAmount(res.data?.data?.total_amount);
            setShoppingCart(res.data?.data["add-to-cart"]);
          });
      }
    }
    // eslint-disable-next-line
  }, [updated]);

  useEffect(() => {
    if (token) {
      axios
        .get(`${PATH}/api/add-to-cart/show`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setTotalAmount(response.data.data?.total_amount);
          setShoppingCart(response.data.data?.["add-to-cart"]);
        });
    }
    // eslint-disable-next-line
  }, [updated]);

  return (
    <ShoppingCartWrapper>
      <PageWrapper>
        <Section className="section-head">
          <p>SHOPPING CART</p>
          <p className="ready">READY TO COMPLETE</p>
        </Section>
        {!!shoppingCart?.length ? (
          <Section className="section-shopping-cart">
            <div className="head">
              <div className="left-side">
                <p>ITEMS</p>
              </div>
              <div className="right-side">
                <p className="left">CATEGORY</p>
                <p className="center">QUANTITY</p>
                <p className="right">SUBTOTAL</p>
              </div>
            </div>
            <ContainedButton
              onClick={() => {
                window.location.replace("/checkout");
              }}
              className="checkout-button-responsive"
            >
              GO TO CHECKOUT
            </ContainedButton>
            <Divider />
            <div className="body">
              {/* ===================== NON RESPONSIVE =================== */}
              {!!shoppingCart?.length &&
                shoppingCart.map((cartItem) => {
                  return (
                    <div key={cartItem?.id} className="cart-item">
                      <div className="left-side">
                        <div className="item-product">
                          <img
                            src={`${cartItem?.images}`}
                            alt="img"
                            onClick={() =>
                              navigate(`/product/${cartItem?.product?.slug}`)
                            }
                          />
                          <div className="item-product-right-side">
                            <div className="upper">
                              <h3
                                className="title"
                                onClick={() =>
                                  navigate(
                                    `/product/${cartItem?.product?.slug}`
                                  )
                                }
                              >
                                {cartItem?.product?.product_info?.product_name}
                              </h3>
                              <p className="price">{`${cartItem?.product?.price} AMD`}</p>
                            </div>
                            <div className="lower">
                              <p
                                onClick={() =>
                                  !token
                                    ? handleRemoveItem(cartItem?.product?.id)
                                    : handleRemoveItem(cartItem?.id)
                                }
                              >
                                REMOVE
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="right-side">
                        <p className="left">{cartItem?.category[0]}</p>
                        <div className="product-counter-wrapper">
                          <div className="product-counter">
                            <button
                              onClick={(e) =>
                                handleMinusClick(
                                  e,
                                  cartItem?.product?.id,
                                  cartItem?.quantity
                                )
                              }
                              className="minus"
                            >
                              -
                            </button>
                            <p className="count">{cartItem?.quantity}</p>
                            <button
                              onClick={(e) =>
                                handlePlusClick(
                                  e,
                                  cartItem?.product?.id,
                                  cartItem?.quantity
                                )
                              }
                              className="plus"
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <p className="product-price right">{`${cartItem?.sub_total} AMD`}</p>
                      </div>
                    </div>
                  );
                })}

              {/* =================== RESPONSIVE =================== */}
              {!!shoppingCart?.length &&
                shoppingCart.map((cartItem, index) => {
                  return (
                    <div key={cartItem?.id} className="cart-item responsive">
                      <div className="left-side">
                        <div className="item-product">
                          <img src={`${cartItem?.images}`} alt="img" />
                          <p
                            onClick={() =>
                              handleRemoveItem(cartItem?.product?.id)
                            }
                          >
                            REMOVE
                          </p>
                        </div>
                      </div>
                      <div className="item-product-right-side">
                        <div className="title-wrapper">
                          <h3 className="title">
                            {cartItem?.product?.product_info?.product_name}
                          </h3>
                          <p className="price">{`${cartItem?.product?.price} AMD`}</p>
                        </div>
                        <div className="category-wrapper">
                          <p className="category">CATEGORY</p>
                          <p className="category-title">
                            {cartItem?.category[0]}
                          </p>
                        </div>
                        <div className="counter-wrapper">
                          <p className="quantity">QUANTITY</p>
                          <div className="product-counter">
                            <button
                              onClick={(e) =>
                                handleMinusClick(
                                  e,
                                  cartItem?.product?.id,
                                  cartItem?.quantity
                                )
                              }
                              className="minus"
                            >
                              -
                            </button>
                            <p className="count">{cartItem?.quantity}</p>
                            <button
                              onClick={(e) =>
                                handlePlusClick(
                                  e,
                                  cartItem?.product?.id,
                                  cartItem?.quantity
                                )
                              }
                              className="plus"
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="price-wrapper">
                          <p className="subtotal">SUBTOTAL</p>
                          <p className="product-price">{`${cartItem?.sub_total} AMD`}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}

              <Divider />
            </div>
            <div className="total-items-container">
              <p>{`TOTAL ${totalAmount && totalAmount} AMD`}</p>
              <ContainedButton
                onClick={() => {
                  window.location.replace("/checkout");
                }}
              >
                GO TO CHECKOUT
              </ContainedButton>
            </div>
          </Section>
        ) : (
          <Section className="empty-shopping-cart">
            <p>YOU HAVE NO ITEMS IN YOUR CART LEFT</p>
            <ContainedButton
              onClick={() => {
                window.location.replace("/products/all");
              }}
            >
              SEE PRODUCTS
            </ContainedButton>
          </Section>
          // <Section className="empty-shopping-cart">
          //   <p>YOU HAVE NO ITEMS IN YOUR CART LEFT</p>
          //   <ContainedButton
          //     onClick={() => {
          //       window.location.replace("/products/all");
          //     }}
          //   >
          //     SEE PRODUCTS
          //   </ContainedButton>
          // </Section>
        )}
        {!!recommendedProducts?.length && (
          <Section className="recommendations-section">
            <h3>RECOMMENDATIONS</h3>
            <Divider />
            <ProductCardGrid className="products-page-grid products-recommendations-grid recommendations-grid">
              {recommendedProducts.map((recommendedProduct) => {
                return (
                  <a
                    key={recommendedProduct?.id}
                    href={`/product/${recommendedProduct.slug}`}
                  >
                    <ProductCard
                      productImg={`${PATH}/${recommendedProduct?.image?.[0].image}`}
                      productName={recommendedProduct?.name}
                      productPrice={`${recommendedProduct?.price}.00`}
                    />
                  </a>
                );
              })}
            </ProductCardGrid>
          </Section>
        )}
        {!token && !!recentlyViewedProducts?.length && (
          <Section className="recently-viewed-section">
            <h3>RECENTLY VIEWED</h3>
            <Divider />
            {
              <ProductCardGrid className="recently-viewed-grid">
                {recentlyViewedProducts?.map((recentlyViewedProduct) => {
                  return (
                    <a href={`/product/${recentlyViewedProduct.slug}`}>
                      <ProductCard
                        key={recentlyViewedProduct?.id}
                        productImg={
                          recentlyViewedProduct?.images
                            ? `${PATH}/${recentlyViewedProduct?.images}`
                            : productPlaceholder
                        }
                        productName={recentlyViewedProduct?.name}
                        productPrice={`${recentlyViewedProduct?.price}.00`}
                      />
                    </a>
                  );
                })}
              </ProductCardGrid>
            }
          </Section>
        )}
        {!token && !!recents?.length && (
          <Section className="recently-viewed-section">
            <h3>RECENTLY VIEWED</h3>
            <Divider />
            {
              <ProductCardGrid className="recently-viewed-grid">
                {recents.slice(0, 4).map((item) => {
                  return (
                    <a href={`/product/${item.slug}`}>
                      <ProductCard
                        key={item?.id}
                        productImg={`${PATH}/${item.img}`}
                        productName={item?.name}
                        productPrice={`${item?.price}.00`}
                      />
                    </a>
                  );
                })}
              </ProductCardGrid>
            }
          </Section>
        )}
        <Footer />
      </PageWrapper>
    </ShoppingCartWrapper>
  );
};

export default Cart;
