import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const DeleteDialog = ({ open, handleClose, handleRemove, name }) => {
  return (
    <Dialog open={open}>
      <DialogTitle sx={{ textAlign: "center" }}>
        Are you sure you want to delete {name || ""}
      </DialogTitle>
      <DialogContent>
        The item will be deleted without recovery option.
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: "#24ae85" }} onClick={handleRemove}>
          Delete
        </Button>
        <Button sx={{ color: "#24ae85" }} onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
