import React from "react";
import { PageWrapper, Section } from "../../shared/styled";
import Footer from "../footer/Footer";
import { NotFoundWrapper } from "./styled";
import { ContainedButton } from "../../shared/styled";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <NotFoundWrapper>
      <PageWrapper>
        <Section className="section-head">
          <h2>PAGE NOT FOUND</h2>
          <div className="lower-wrapper">
            <p>PLEASE, GET BACK TO HOME PAGE</p>
            <ContainedButton onClick={() => navigate("/")}>
              HOME
            </ContainedButton>
          </div>
        </Section>

        <Footer />
      </PageWrapper>
    </NotFoundWrapper>
  );
};

export default NotFound;
