import React, { useState, useEffect } from "react";
import { Container, Title, InfoField, TextFieldWrapper } from "../../styled";
import path from "../../../../constants/path";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteDialog from "../DeleteDialog";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [reload, setReload] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);

  const [newCategory, setNewCategory] = useState("");
  const [newCategoryErr, setNewCategoryErr] = useState("");

  useEffect(() => {
    axios.get(`${path}/api/categories/all`).then((response) => {
      setCategories(response.data.data["all-categories"]);
    });
  }, [reload]);

  const handleCategorySave = () => {
    axios
      .post(`${path}/api/categories/store`, {
        name: newCategory,
      })
      .then((res) => {
        setNewCategory("");
        setNewCategoryErr("");
        setCategories(res.data.data["add-new-category"]);
        localStorage.setItem(
          "categories",
          JSON.strinresgify(res.data.data["add-new-category"])
        );
      })
  };

  const handleCancelAddCategory = () => {
    setNewCategory("");
    setNewCategoryErr("");
  };

  const handleDelete = () => {
    axios
      .delete(`${path}/api/categories/delete/${deletingItem.id}`)
      .then(() => {
        setOpenDeleteDialog(false);
        setReload(!reload);
      });
  };

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
    setDeletingItem(null);
  };

  return (
    <Container>
      <Title>Categories</Title>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="title">
              <TableCell>Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories?.map((category) => (
              <TableRow
                key={category.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{category.name}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      setDeletingItem(category);
                      setOpenDeleteDialog(true);
                    }}
                  >
                    delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Title></Title>
      <Title>Add new category</Title>
      <InfoField>
        <TextFieldWrapper>
          <TextField
            error={newCategoryErr ? true : false}
            value={newCategory}
            onChange={(e) => {
              setNewCategory(e.target.value);
              setNewCategoryErr("");
            }}
          />
          {newCategoryErr && <div className="err">{newCategoryErr}</div>}
        </TextFieldWrapper>
        <Button
          variant="text"
          disabled={newCategory ? false : true}
          onClick={handleCategorySave}
        >
          add
        </Button>
        {(newCategory || newCategoryErr) && (
          <Button onClick={handleCancelAddCategory}>cancel</Button>
        )}
      </InfoField>
      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleCloseDialog}
        handleClose={handleCloseDialog}
        handleRemove={handleDelete}
        name={`${deletingItem?.name} category, all products of this category will be deleted too.`}
      />
    </Container>
  );
};

export default Categories;
