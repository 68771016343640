import React, { useEffect, useState } from "react";
import {
  Form,
  FormWrapper,
  FileInputWrapper,
  Link,
  EmptySelect,
  TextFieldWrapper,
} from "../../styled";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { CategoryWrapper } from "./styled";
import { ErrorField } from "../../../../shared/dialog/styled";
import NumberTextField from "../../shared-admin/NumberTextField";
import ClearIcon from "@mui/icons-material/Clear";
import path from "../../../../constants/path";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddNewProduct = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [category, setCategory] = useState("");
  const [desc, setDesc] = useState("");
  const [usageDesc, setUsageDesc] = useState("");
  const [usageLink, setUsageLink] = useState("");
  const [seoWord, setSeoWord] = useState("");
  const [seoDesc, setSeoDesc] = useState("");
  const [images, setImages] = useState([]);
  const [discount, setDiscount] = useState("");
  const [errors, setErrors] = useState({});
  const [imgErr, setImgErr] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios.get(`${path}/api/categories/all`).then((response) => {
      setCategories(response.data.data["all-categories"]);
    });
  }, []);

  const handleImage = (e) => {
    handleErrorRemove("images");
    setImgErr("");
    setImages(Array.from(e.target.files));
  };

  const handleRemoveImg = (image) => {
    setImgErr("");
    handleErrorRemove("images");
    setImages(images.filter((img) => img.name !== image.name));
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    const data = {
      price,
      product_name: name,
      product_description: desc,
      category_id: category,
      quantity,
    };

    if (discount) {
      data.discount = discount;
    }

    if (usageDesc) {
      data.product_how_to_use_description = usageDesc;
    }
    if (usageLink) {
      data.product_how_to_use_video_link = usageLink;
    }
    if (seoWord) {
      data.seo_word = seoWord;
    }
    if (seoDesc) {
      data.seo_description = seoDesc;
    }
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    for (let img of images) {
      formData.append("images[]", img, img.name);
    }
    axios
      .post(`${path}/api/products/store`, formData)
      .then(() => navigate("/admin/products", { replace: true }))
      .catch((err) => {
        if (+err?.request?.status === 413) {
          setImgErr(
            "Something went wrong, in most cases it is due to the size of the image."
          );
        }
        setErrors(err.response.data.message);
      });
  };

  const handleErrorRemove = (val) => {
    delete errors[val];
  };

  return (
    <FormWrapper>
      <Link to="/admin/products">Back to product list</Link>
      <Form>
        <CategoryWrapper>
          <TextFieldWrapper>
            <FormControl>
              <InputLabel id="category">category</InputLabel>
              <Select
                error={errors?.category_id ? true : false}
                label="Category"
                labelId="category"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                  handleErrorRemove("category_id");
                }}
              >
                {categories?.length ? (
                  categories?.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))
                ) : (
                  <EmptySelect>Nothing to show</EmptySelect>
                )}
              </Select>
            </FormControl>
            {errors.category_id && (
              <div className="err">{errors.category_id[0]}</div>
            )}
          </TextFieldWrapper>
        </CategoryWrapper>
        <TextFieldWrapper>
          <TextField
            error={errors.product_name ? true : false}
            label="Product name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              handleErrorRemove("product_name");
            }}
          />
          {errors.product_name && (
            <div className="err">{errors.product_name[0]}</div>
          )}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <NumberTextField
            error={errors.price ? true : false}
            customInput={TextField}
            label="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
              handleErrorRemove("price");
            }}
          />
          {errors.price && <div className="err">{errors.price[0]}</div>}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <NumberTextField
            error={errors.discount ? true : false}
            customInput={TextField}
            label="Discount (%)"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
          />
          {errors.discount && <div className="err">{errors.discount[0]}</div>}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <NumberTextField
            error={errors.quantity ? true : false}
            customInput={TextField}
            label="Quantity"
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value);
              handleErrorRemove("quantity");
            }}
          />
          {errors.quantity && <div className="err">{errors.quantity[0]}</div>}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            error={errors.product_description ? true : false}
            label="Description"
            value={desc}
            onChange={(e) => {
              setDesc(e.target.value);
              handleErrorRemove("product_description");
            }}
            multiline
          />
          {errors.product_description && (
            <div className="err">{errors.product_description[0]}</div>
          )}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            error={errors.product_how_to_use_description ? true : false}
            label="How to use (text)"
            value={usageDesc}
            onChange={(e) => {
              setUsageDesc(e.target.value);
              handleErrorRemove("product_how_to_use_description");
            }}
            multiline
          />
          {errors.product_how_to_use_description && (
            <div className="err">
              {errors.product_how_to_use_description[0]}
            </div>
          )}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            error={errors.product_how_to_use_video_link ? true : false}
            label="How to use (YouTube video link)"
            value={usageLink}
            onChange={(e) => {
              setUsageLink(e.target.value);
              handleErrorRemove("product_how_to_use_video_link");
            }}
          />
          {errors.product_how_to_use_video_link && (
            <div className="err">{errors.product_how_to_use_video_link[0]}</div>
          )}
        </TextFieldWrapper>
        <FileInputWrapper>
          <input
            id="product-img"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={handleImage}
            multiple
          />
          <label htmlFor="product-img">add image</label>
          <div className="imgs">
            {!!images.length &&
              images.map((image) => (
                <div key={image.name} className="img-wrapper">
                  <img src={URL.createObjectURL(image)} alt="" />
                  <Button onClick={() => handleRemoveImg(image)}>
                    <ClearIcon />
                  </Button>
                </div>
              ))}
          </div>
          {imgErr && <div className="err">{imgErr}</div>}
          {errors?.images && <div className="err">{errors?.images?.[0]}</div>}
        </FileInputWrapper>
        <TextFieldWrapper>
          <TextField
            error={errors.seo_word ? true : false}
            label="Title for SEO"
            value={seoWord}
            onChange={(e) => {
              setSeoWord(e.target.value);
              handleErrorRemove("seo_word");
            }}
          />
          {errors.seo_word && <div className="err">{errors.seo_word[0]}</div>}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            error={errors.seo_description ? true : false}
            multiline
            label="Description for SEO"
            value={seoDesc}
            onChange={(e) => {
              setSeoDesc(e.target.value);
              handleErrorRemove("seo_description");
            }}
          />
          {errors.seo_description && (
            <div className="err">{errors.seo_description[0]}</div>
          )}
        </TextFieldWrapper>
      </Form>
      <Button variant="contained" className="add" onClick={handleSubmit}>
        save
      </Button>
    </FormWrapper>
  );
};

export default AddNewProduct;
