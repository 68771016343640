import React, { useState, useEffect } from "react";
import { ContainedButton, PageWrapper, Section } from "../../shared/styled";
import { HomeWrapper } from "./styled";
import { ProductCardGrid } from "../../shared/product-card/styled";
import homeImg from "../../assets/home-img.jpg";
import ProductCard from "../../shared/product-card/ProductCard";
import BlogPost from "../../shared/blog-post/BlogPost";
import Footer from "../footer/Footer";
import PATH from "../../constants/path";
import axios from "axios";
import moment from "moment";
import Parallax from "./Parallax";
import ScrollContainer from "react-indiana-drag-scroll";
import MetaDecorator from "../../MetaDecorator";
import metaThumbnail from "../../assets/cart-item-placeholder.jpg";

const Home = () => {
  const [brandLeaders, setBrandLeaders] = useState([]);
  const [partners, setPartners] = useState([]);
  const [limitedBlogPosts, setLimitedBlogPosts] = useState([]);
  const [homePageText, setHomePageText] = useState("");
  const [homePageImage, setHomePageImage] = useState("");
  const [homeImageNotLoaded, setHomeImageNotLoaded] = useState(false);

  const content = {
    pageDescription: "PAGE DESCRIPTION",
    pageTitle: "PAGE TITLE",
    metaImageAlt: "META IMAGE ALT",
  };

  useEffect(() => {
    axios.get(`${PATH}/api/products/brand-leaders/all`).then((response) => {
      setBrandLeaders(response.data.data.brand_leaders);
    });
    axios.get(`${PATH}/api/blogs/limitedPosts`).then((response) => {
      setLimitedBlogPosts(response.data.data.posts);
    });
    axios.get(`${PATH}/api/partners/home`).then((response) => {
      setPartners(response.data.data.partners);
    });

    axios.get(`${PATH}/api/information/homepage_text`).then((res) => {
      setHomePageText(res.data.data.information.value);
    });
    axios
      .get(`${PATH}/api/information/homepage_background_image`)
      .then((res) => {
        setHomePageImage(res.data.data.information.image);
      })
      .catch(() => setHomeImageNotLoaded(true));
  }, []);

  return (
    <>
      <MetaDecorator imageUrl={metaThumbnail} />
      {/* <MetaDecorator
        description={content.pageDescription}
        title={content.pageTitle}
        imageAlt={content.metaImageAlt}
        imageUrl={metaThumbnail}
      /> */}
      <HomeWrapper>
        <PageWrapper>
          <Section className="section-1">
            <div className="upper">
              <p className="moruq">MORUQ</p>
              <div className="upper-right">
                <p>0% CHEMICAL</p>
                <p>100% ECO PRODUCTS</p>
              </div>
            </div>
            <div className="lower">
              <h2>
                Inspired & created by <span className="nature">nature</span>
              </h2>
              <a href="/products/all" className="see-products">
                <ContainedButton>SEE PRODUCTS</ContainedButton>
              </a>
            </div>
          </Section>
          <img
            src={homeImageNotLoaded ? homeImg : `${PATH}/${homePageImage}`}
            alt="About Moruq"
          />
          <Section className="section-3 section-about">
            <p>
              <span className="about">ABOUT MORUQ</span>
              {homePageText && homePageText}
              <a href="/about" className="see-more">
                SEE MORE
              </a>
            </p>
          </Section>
          <Section className="section-4 section-brand-leaders">
            <h3>BRAND LEADERS</h3>
            <ProductCardGrid>
              {!!brandLeaders?.length &&
                brandLeaders.map((brandLeader) => {
                  return (
                    <a
                      key={brandLeader.id}
                      href={`/product/${brandLeader.slug}`}
                    >
                      <ProductCard
                        // onClick={() => {
                        //   navigate(`/product/${brandLeader.slug}`);
                        // }}

                        productImg={`${PATH}/${brandLeader?.image[0]?.image}`}
                        productName={brandLeader?.product_info?.product_name}
                        productPrice={`${brandLeader.price}.00`}
                      />
                    </a>
                  );
                })}
            </ProductCardGrid>
            <a href="/products/all" className="see-products">
              <ContainedButton>SEE PRODUCTS</ContainedButton>
            </a>
          </Section>

          {/* ========================PARALLAX======================= */}
          <Parallax />

          <Section className="section-6 section-blog">
            <h3>
              We know how important your beard <br /> is to you and we know how
              to take care of it.
            </h3>
            <div className="divider"></div>
            {!!limitedBlogPosts.length &&
              limitedBlogPosts.map((blogPost) => {
                return (
                  <span key={blogPost?.id}>
                    <BlogPost
                      imgStyle={{
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url("${PATH}/${blogPost?.image}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      title={`${blogPost?.blog_info?.title}`}
                      href={`single-blog/${blogPost?.slug}`}
                      date={moment(blogPost?.date).format("DD.MM.YYYY")}
                    />
                    <div className="divider"></div>
                  </span>
                );
              })}
          </Section>
          <Section className="section-7 section-partners">
            <h3>PARTNERS</h3>
            <div className="slider">
              <ScrollContainer className="scroll-container">
                <div className="partners-grid slide-track">
                  {!!partners.length &&
                    partners.map((partner) => {
                      return (
                        <a
                          key={partner?.id}
                          href={partner.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="partner-container slide">
                            <img
                              src={`${PATH}/${partner?.image}`}
                              alt="partner"
                            />
                          </div>
                        </a>
                      );
                    })}

                  {/* SAM SLIDES DOUBLED */}
                  {!!partners.length &&
                    partners.map((partner) => {
                      return (
                        <a
                          key={partner?.id}
                          href={partner.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="partner-container slide">
                            <img
                              src={`${PATH}/${partner?.image}`}
                              alt="partner"
                            />
                          </div>
                        </a>
                      );
                    })}
                  {/* SAM SLIDES DOUBLED */}
                  {!!partners.length &&
                    partners.map((partner) => {
                      return (
                        <a
                          key={partner?.id}
                          href={partner.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="partner-container slide">
                            <img
                              src={`${PATH}/${partner?.image}`}
                              alt="partner"
                            />
                          </div>
                        </a>
                      );
                    })}
                  {/* SAM SLIDES DOUBLED */}
                  {!!partners.length &&
                    partners.map((partner) => {
                      return (
                        <a
                          key={partner?.id}
                          href={partner.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="partner-container slide">
                            <img
                              src={`${PATH}/${partner?.image}`}
                              alt="partner"
                            />
                          </div>
                        </a>
                      );
                    })}
                  {/* SAM SLIDES DOUBLED */}
                  {!!partners.length &&
                    partners.map((partner) => {
                      return (
                        <a
                          key={partner?.id}
                          href={partner.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="partner-container slide">
                            <img
                              src={`${PATH}/${partner?.image}`}
                              alt="partner"
                            />
                          </div>
                        </a>
                      );
                    })}
                  {/* SAM SLIDES DOUBLED */}
                  {!!partners.length &&
                    partners.map((partner) => {
                      return (
                        <a
                          key={partner?.id}
                          href={partner.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="partner-container slide">
                            <img
                              src={`${PATH}/${partner?.image}`}
                              alt="partner"
                            />
                          </div>
                        </a>
                      );
                    })}
                </div>
              </ScrollContainer>
            </div>
          </Section>
          <Footer />
        </PageWrapper>
      </HomeWrapper>
    </>
  );
};

export default Home;
