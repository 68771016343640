import React, { useState } from "react";
import {
  AuthWrapper,
  Auth,
  AuthTitle,
  AuthContent,
  AuthNavigation,
  CustomBtn,
  Divider,
  Underline,
  IconButton,
} from "./AuthNavbarMobile.styled";
import closeIcon from "../../assets/closeIcon.svg";
import LogInForm from "../auth/LogInForm";
import RegisterForm from "../auth/RegisterForm";

const AuthNavbarMobile = ({ open, setOpen, handleReload }) => {
  const [toRight, setToRight] = useState(false);
  const [closed, setClosed] = useState(false);

  return (
    <AuthWrapper className={open ? "show" : "hide"}>
      <Auth>
        <AuthTitle>
          <AuthNavigation>
            <CustomBtn
              onClick={() => setToRight(false)}
              className={!toRight ? "is-active" : ""}
            >
              <div>login</div>
              <Underline className={toRight ? "to-right" : ""} />
            </CustomBtn>
            <CustomBtn
              onClick={() => setToRight(true)}
              className={toRight ? "is-active" : ""}
            >
              register
            </CustomBtn>
          </AuthNavigation>
          <IconButton
            onClick={() => {
              setClosed(true);
              setOpen(false);
            }}
          >
            <img src={closeIcon} alt="X" />
          </IconButton>
        </AuthTitle>
        <Divider />
        <AuthContent>
          {!toRight ? (
            <LogInForm
              mobile={"mobile"}
              open={open}
              setOpen={setOpen}
              handleReload={handleReload}
              closed={closed}
            />
          ) : (
            <RegisterForm
              mobile={"mobile"}
              handleReload={handleReload}
              closed={closed}
            />
          )}
        </AuthContent>
      </Auth>
    </AuthWrapper>
  );
};

export default AuthNavbarMobile;
