import React, { useEffect } from "react";
import { AdminWrapper, Content } from "./styled";
import Menu from "./Menu";
import { Outlet, useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import Button from "@mui/material/Button";

const Admin = () => {
  const navigate = useNavigate();
  const isAdmin = localStorage.getItem("permission");

  useEffect(() => {
    !isAdmin && navigate("/admin-login", { replace: true });
    isAdmin &&
      window.location.pathname.endsWith("admin") &&
      navigate("/admin/products");
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("permission");
    navigate("/admin-login", { replace: true });
  };

  return (
    <AdminWrapper>
      {isAdmin && (
        <>
          <Menu />
          <Divider orientation="vertical" />
          <Content>
            <Button
              variant="contained"
              className="logout-btn"
              onClick={handleLogout}
            >
              log out
            </Button>
            <Outlet />
          </Content>
        </>
      )}
    </AdminWrapper>
  );
};

export default Admin;
