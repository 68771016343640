import React, { useState } from "react";
import {
  Divider,
  Form,
  InputWrapper,
  Input,
  Button,
  ButtonWrapper,
  ErrorField,
} from "../../shared/dialog/styled";
import axios from "axios";
import path from "../../constants/path";
import { ForgotPassBtn } from "./Dialog.styled";

const Login = ({ handleClose, handleOpen }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { email, password };

    const storageItems = JSON.parse(localStorage.getItem("storageCart"));
    const storageItemsQuantities = [];
    const storageItemsProductIds = [];

    for (let item of storageItems) {
      storageItemsQuantities.push(item.quantity);
      storageItemsProductIds.push(item.product_id);
    }

    axios
      .post(`${path}/api/login`, data)
      .then((res) => {
        localStorage.setItem("token", res.data.data.access_token);
        localStorage.removeItem("rec");
      })
      .then((res) => {
        // store storage cart
        const token = localStorage.getItem("token");

        if (JSON.parse(localStorage.getItem("storageCart"))?.length) {
          axios.post(
            `${path}/api/add-to-cart/store-login`,
            {
              quantities: storageItemsQuantities,
              product_ids: storageItemsProductIds,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }

        localStorage.removeItem("storageCart");
        localStorage.removeItem("cartItemsCount");
      })
      .then((res) => {
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setErr(err.response?.data?.message);
      });
  };

  const handleCloseDialog = () => {
    setErr("");
    setEmail("");
    setPassword("");
    handleClose();
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <InputWrapper>
          <label htmlFor="email-log-check">Email</label>
          <Input
            id="email-log-check"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </InputWrapper>{" "}
        <InputWrapper>
          <label htmlFor="password">Password</label>
          <Input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </InputWrapper>
        {err && <ErrorField>{err}</ErrorField>}
        <ForgotPassBtn
          type="button"
          style={{ color: "#fff" }}
          onClick={() => {
            handleCloseDialog();
            handleOpen();
          }}
        >
          Forgot password?
        </ForgotPassBtn>
        <Divider className="forget-password" />
        <ButtonWrapper>
          <Button>login</Button>
        </ButtonWrapper>
      </Form>
    </>
  );
};

export default Login;
