import React, { useState, useEffect } from "react";
import path from "../../../../constants/path";
import axios from "axios";
import {
  Form,
  TextFieldWrapper,
  FileInputWrapper,
  Title,
  Container,
  InfoField,
  FormWrapper,
  Divider,
} from "../../styled";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const AboutAdmin = () => {
  const [text, setText] = useState("");
  const [image, setImage] = useState("");

  const [list, setList] = useState([]);

  const [reload, setReload] = useState(false);

  const [editingItem, setEditingItem] = useState("");
  const [editText, setEditText] = useState("");

  const [newText, setNewText] = useState("");
  const [newImg, setNewImg] = useState(null);

  const [imgErr, setImgErr] = useState("");

  const handleAddNew = () => {
    const formData = new FormData();

    if (newText.length < 40) {
      formData.append("big_description", newText);
      formData.append("small_description", "");
    } else {
      formData.append("small_description", newText);
      formData.append("big_description", "");
    }

    newImg && formData.append("image", newImg, newImg.name);
    axios
      .post(`${path}/api/about/store`, formData)
      .then(() => {
        setNewImg(null);
        setNewText();
        setImgErr({});
      })
      .catch((err) => {
        if (+err?.request?.status === 413) {
          setImgErr({
            id: "about",
            text: "Something went wrong, in most cases it is due to the size of the image.",
          });
        }
      });
  };

  useEffect(() => {
    axios
      .get(`${path}/api/about`)
      .then((res) => setList(res.data.data.about_sections));
  }, [reload]);

  const handleEdit = () => {
    const formData = new FormData();

    if (text.length < 40) {
      formData.append("big_description", text);
      formData.append("small_description", "");
    } else {
      formData.append("small_description", text);
      formData.append("big_description", "");
    }
    image && formData.append("image", image, image.name);

    formData.append("_method", "PUT");
    axios
      .post(`${path}/api/about/edit/${editingItem}`, formData)
      .then(() => {
        setImgErr({});
        setEditingItem(0);
        handleCancel();
      })
      .catch((err) => {
        if (+err?.request?.status === 413) {
          setImgErr({
            id: editingItem,
            text: "Something went wrong, in most cases it is due to the size of the image.",
          });
        }
      });
  };

  const handleCancel = () => {
    setEditText(0);
    setReload(!reload);
    setImgErr({});
  };

  return (
    <Container>
      <Title>About</Title>
      <FormWrapper>
        {list.map((item) => (
          <Form key={item.id}>
            <InfoField>
              <TextFieldWrapper>
                <TextField
                  className="disabled-bold"
                  label="Text"
                  value={
                    item.id === editText
                      ? text
                      : item.small_description || item.big_description || ""
                  }
                  onChange={(e) => {
                    setText(e.target.value);
                    setImgErr({});
                  }}
                  disabled={editText === item.id ? false : true}
                  multiline
                />
              </TextFieldWrapper>
              {editText !== item.id ? (
                <Button
                  onClick={() => {
                    setEditingItem(item.id);
                    setEditText(item.id);
                    setImgErr({});
                    setText(
                      item.small_description || item.big_description || ""
                    );
                  }}
                >
                  edit
                </Button>
              ) : (
                <Button onClick={handleCancel}>cancel</Button>
              )}
            </InfoField>
            <FileInputWrapper>
              <input
                id={`product-img-${item?.id}`}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => {
                  setImgErr({});
                  e.target.files[0] && setImage(e.target.files[0]);
                  setEditingItem(item.id);
                }}
                multiple
              />
              <label htmlFor={`product-img-${item?.id}`}>change image</label>

              <div
                key={item.AboutAdminimage || image?.name}
                className="img-wrapper-admin about"
              >
                {(image || item.image) && (
                  <img
                    src={
                      image?.name && item.id === editingItem
                        ? URL.createObjectURL(image)
                        : `${path}/${item.image}`
                    }
                    alt=""
                  />
                )}
                {image && editingItem === item.id && (
                  <div className="img-btns">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setImage(null);
                        setImgErr({});
                      }}
                    >
                      cancel
                    </Button>
                  </div>
                )}
              </div>
              {imgErr?.id === item.id && (
                <div className="err">{imgErr?.text}</div>
              )}
            </FileInputWrapper>
            {editingItem === item.id && (
              <div className="btns">
                <Button
                  className="save-btn "
                  variant="contained"
                  onClick={handleEdit}
                >
                  save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEditingItem(0);
                    handleCancel();
                  }}
                >
                  cancel
                </Button>
              </div>
            )}
            <Divider />
          </Form>
        ))}
      </FormWrapper>
      <Title>Add new</Title>
      <FormWrapper>
        <Form>
          <InfoField>
            <TextFieldWrapper>
              <TextField
                className="disabled-bold"
                value={newText}
                onChange={(e) => {
                  setNewText(e.target.value);
                }}
                multiline
              />
            </TextFieldWrapper>
          </InfoField>
          <FileInputWrapper>
            <input
              id="product-img-add"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => {
                e.target.files[0] && setNewImg(e.target.files[0]);
              }}
            />
            <label htmlFor="product-img-add">
              {newImg ? "change image" : "add image"}
            </label>
            <div className="img-wrapper-admin about">
              {newImg && (
                <>
                  <img src={URL.createObjectURL(newImg)} alt="" />
                  <div className="img-btns">
                    <Button variant="outlined" onClick={() => setNewImg(null)}>
                      delete
                    </Button>
                  </div>
                </>
              )}
            </div>
            {imgErr?.id === "about" && (
              <div className="err">{imgErr?.text}</div>
            )}
          </FileInputWrapper>
          <div className="btns">
            <Button
              className="save-btn"
              variant="contained"
              onClick={handleAddNew}
              disabled={!newText || !newImg}
            >
              save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setNewImg(null);
                setNewText(null);
              }}
              disabled={!newText || !newImg}
            >
              cancel
            </Button>
          </div>
        </Form>
      </FormWrapper>
    </Container>
  );
};

export default AboutAdmin;
