import React from "react";
import { PageWrapper, Section, ContainedButton } from "../../shared/styled";
import { OrderAcceptedWrapper } from "./styled";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";

const OrderDeclined = () => {
  const navigate = useNavigate();

  return (
    <OrderAcceptedWrapper>
      <PageWrapper>
        <Section className="section-head">
          <div className="lower-wrapper order-accepted">
            <p>
              Your order has not been approved. Please, contact the phone
              number: <a href="tel:+37455110406">+37455110406</a>
            </p>
            <ContainedButton onClick={() => navigate("/")}>
              HOME
            </ContainedButton>
          </div>
        </Section>
        <Footer />
      </PageWrapper>
    </OrderAcceptedWrapper>
  );
};

export default OrderDeclined;
