import React, { useEffect, useState } from "react";
import { PageWrapper, Section } from "../../shared/styled";
import { ProductsWrapper, Navigation, Divider } from "./styled";
import { NavLink } from "react-router-dom";
import { ProductCardGrid } from "../../shared/product-card/styled";
import ProductCard from "../../shared/product-card/ProductCard";
import Footer from "../footer/Footer";
import CustomPagination from "../../shared/CustomPagination";
import PATH from "../../constants/path";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Products = () => {
  const [categories, setCategories] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [allProducts, setAllProducts] = useState([]);
  const [recommendedProducts, setRecommendedProducts] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const arr = localStorage.getItem("categories");
    setCategories(JSON.parse(arr));
    axios
      .get(`${PATH}/api/products/recommended-product/all`)
      .then((response) => {
        setRecommendedProducts(response.data.data.recommended_product);
      });
    // eslint-disable-next-line
  }, []);

  const getAllProducts = () => {
    const selectedPage = window.location.hash.substring(1);
    setCurrentPage(selectedPage || 1);
    axios
      .get(`${PATH}/api/products?size=12&page=${selectedPage}`)
      .then((response) => {
        setPagination(response.data.data.pagination);
        setAllProducts(response.data.data.products);
      });
  };

  const getFilteredProducts = (id) => {
    const selectedPage = window.location.hash.substring(1);
    setCurrentPage(selectedPage || 1);
    axios
      .post(`${PATH}/api/products/filter`, {
        category_id: id,
        size: 12,
        page: currentPage,
      })
      .then((response) => {
        setPagination(response.data.data.pagination);
        setAllProducts(response.data.data.products);
      });
  };

  useEffect(() => {
    const categoryId = window.location.pathname.includes("filter")
      ? window.location.pathname.split("id")[1].substring("1")
      : "";

    window.location.pathname.includes("filter")
      ? getFilteredProducts(categoryId)
      : getAllProducts();
    // eslint-disable-next-line
  }, [currentPage, window.location.pathname]);

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    window.location.hash = page;
    window.location.reload();
  };

  const handleFilterClick = (e) => {
    getFilteredProducts(+e.id);
    setCurrentPage(1);
  };
  const handleAllClick = (e) => {
    getAllProducts(+e.id);
    setCurrentPage(1);
  };

  return (
    <ProductsWrapper>
      <PageWrapper>
        <Section className="upper-section">
          <p>
            A <span>PLEASURE</span> TO USE.
          </p>{" "}
          <p>IMPOSSIBLE TO IGNORE.</p>
        </Section>
        <Section>
          <Navigation>
            <NavLink onClick={handleAllClick} to="/products/all">
              ALL
            </NavLink>
            {!!categories?.length &&
              categories.map((category) => {
                return (
                  <NavLink
                    onClick={(e) => handleFilterClick(category)}
                    key={category.id}
                    to={`/products/filter&id=${category.id}`}
                  >
                    {category?.name}
                  </NavLink>
                );
              })}
          </Navigation>
        </Section>
        <Divider className="products-divider" />
        <Section className="products-grid-section">
          <ProductCardGrid>
            {!!allProducts?.length &&
              allProducts.map((product) => {
                return (
                  <a key={product?.id} href={`/product/${product?.slug}`}>
                    <ProductCard
                      // onClick={() => navigate(`/product/${product?.slug}`)}

                      productImg={`${PATH}/${product?.image[0]?.image}`}
                      productName={product?.product_info?.product_name}
                      productPrice={`${product?.price}.00`}
                    />
                  </a>
                );
              })}
          </ProductCardGrid>
          {pagination.pages_count > 1 && (
            <CustomPagination
              page={+currentPage}
              pageCount={pagination.pages_count}
              handlePageChange={handlePageChange}
            />
          )}
        </Section>
        {!!recommendedProducts?.length && (
          <Section className="products-recommentaions-section">
            <h3>RECOMMENDATIONS</h3>
            <Divider />
            <ProductCardGrid className="products-page-grid products-recommendations-grid">
              {recommendedProducts.map((recommendedProduct) => {
                return (
                  <a
                    key={recommendedProduct?.id}
                    href={`/product/${recommendedProduct?.slug}`}
                  >
                    <ProductCard
                      onClick={() =>
                        navigate(`/product/${recommendedProduct?.slug}`)
                      }
                      productImg={`${PATH}/${recommendedProduct?.image?.[0].image}`}
                      productName={recommendedProduct?.name}
                      productPrice={`${recommendedProduct?.price}.00`}
                    />
                  </a>
                );
              })}
            </ProductCardGrid>
          </Section>
        )}
        <Footer />
      </PageWrapper>
    </ProductsWrapper>
  );
};

export default Products;
