import React, { useEffect, useState } from "react";
import {
  ContactsWrapper,
  ContactInfo,
  InfoTitle,
  InfoContent,
  InfoItem,
  Divider,
  ItemTitle,
  ItemContent,
  Links,
  BackgImage,
  Section,
  SectionHeader,
  SectionTitle,
  SectionHeaderContent,
  Form,
  Input,
  Btn,
  Message,
} from "./styled";
import Footer from "../footer/Footer";
import largeBckg from "../../assets/contacts/contactsBckgLarge.jpg";
import TextField from "@mui/material/TextField";
import PATH from "../../constants/path";
import axios from "axios";

const Contacts = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");

  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [phone, setPhone] = useState("");
  const [contactsEmail, setContactsEmail] = useState("");
  const [contactsImage, setContactsImage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${PATH}/api/contact-us`, { name, email, message })
      .then((res) => {
        setResponse(res.data.message);
      })
      .catch((err) => {
        setResponse("Something went wrong");
      })
      .finally(() => {
        setTimeout(() => {
          setResponse("");
          setName("");
          setEmail("");
          setMessage("");
        }, 5000);
      });
  };

  useEffect(() => {
    axios.get(`${PATH}/api/information/facebook_link`).then((res) => {
      setFacebookLink(res.data.data.information.value);
    });
    axios.get(`${PATH}/api/information/instagram_link`).then((res) => {
      setInstagramLink(res.data.data.information.value);
    });
    axios.get(`${PATH}/api/information/phone`).then((res) => {
      setPhone(res.data.data.information.value);
    });
    axios.get(`${PATH}/api/information/email`).then((res) => {
      setContactsEmail(res.data.data.information.value);
    });

    axios
      .get(`${PATH}/api/information/contacts_background_image`)
      .then((res) => {
        setContactsImage(`${PATH}/${res.data.data.information.image}`);
      });
  }, []);
  return (
    <>
      <ContactsWrapper>
        <ContactInfo>
          <InfoTitle>
            <div>ready</div>
            <div className="outlined"> when</div>
            <div>you are</div>
          </InfoTitle>
          <InfoContent>
            <Divider />
            <InfoItem>
              <ItemTitle>call us</ItemTitle>
              <ItemContent href="tel:+37455110406">
                {phone && phone}
              </ItemContent>
            </InfoItem>
            <Divider />
            <InfoItem>
              <ItemTitle>mail us</ItemTitle>
              <ItemContent
                className="break"
                href="mailto:moruqecosolutions@business.com"
              >
                {contactsEmail && contactsEmail}
              </ItemContent>
            </InfoItem>
            <Divider />
            <InfoItem>
              <ItemTitle>find us</ItemTitle>
              <Links>
                <ItemContent
                  target="_blank"
                  href={facebookLink && facebookLink}
                >
                  facebook
                </ItemContent>
                <ItemContent
                  target="_blank"
                  href={instagramLink && instagramLink}
                >
                  instagram
                </ItemContent>
              </Links>
            </InfoItem>
            <Divider />
          </InfoContent>
        </ContactInfo>
        <BackgImage>
          <img src={contactsImage} alt="" />
        </BackgImage>
        <Section>
          <SectionHeader>
            <SectionTitle>let's talk</SectionTitle>
            <SectionHeaderContent>
              one of Our MORUQAVOR would love to hear you.
            </SectionHeaderContent>
          </SectionHeader>
          <Form onSubmit={handleSubmit}>
            <Input
              placeholder="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              multiline
              placeholder="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {response && <Message>{response}</Message>}
            <Btn>send</Btn>
          </Form>
        </Section>
      </ContactsWrapper>
      <Footer />
    </>
  );
};

export default Contacts;
