import styled, {css} from "styled-components";

export const Container = styled.div`
margin:120px 80px;
 @media (max-width:1024px) {
   margin:60px 20px
  }
`

export const ContentWrapper = styled.div`
display:flex;
flex-direction:column;
gap:120px;
margin-top:40px;
&:first-child {
  margin-top: 0;
}
@media (max-width:1024px) {
   gap:60px
}
`

export const Divider = styled.div`
width:100%;
height:1px;
background-color: #fff;
`

export const Title = styled.h1`
font-weight: 700;
font-size: 64px;
line-height: 78px;
color:#fff;
 @media (max-width: 780px) {
   font-size: 40px;
   line-height: 46px;
  }
`

export const List = styled.ol`
list-style-position: inside;
`

const typo = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`

export const ListItem = styled.li`
  color: #BABABA;
  margin-top:40px ;
  &:first-child {
    margin-top: 0;
  }

`

export const ListItemTitle = styled.h5`
${typo}
  display: inline-block;
  text-transform: uppercase;
`

export const ListItemDesc = styled.p`
  ${typo}
  margin-top: 20px;
  & ul {
    list-style-position: outside;
    padding-left:20px;
  }
`