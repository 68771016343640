import React, { useState, useEffect } from "react";
import path from "../../../../constants/path";
import axios from "axios";
import {
  Form,
  TextFieldWrapper,
  FileInputWrapper,
  Title,
  Container,
  InfoField,
} from "../../styled";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const HomeAdmin = () => {
  const [text, setText] = useState("");
  const [image, setImage] = useState("");
  const [defImage, setDefImage] = useState("");

  const [reload, setReload] = useState(false);

  const [err, setErr] = useState("");
  const errMessage = "This is a required field";

  const [editingItem, setEditingItem] = useState("");

  useEffect(() => {
    axios.get(`${path}/api/information/homepage_text`).then((res) => {
      setText(res.data.data.information?.value);
    });
    axios
      .get(`${path}/api/information/homepage_background_image`)
      .then((res) => {
        setImage(res.data.data.information?.image);
        setDefImage(res.data.data.information?.image);
      });
  }, [reload]);

  const handleEdit = (key, value) => {
    !value
      ? setErr(key)
      : axios
          .put(`${path}/api/information/edit/${key}`, {
            value,
          })
          .then(() => {
            setEditingItem("");
            setErr("");
          });
  };

  const handleCancel = () => {
    setEditingItem("");
    setErr("");
    setReload(!reload);
  };

  const handleEditImage = () => {
    const formData = new FormData();
    formData.append("image", image, image.name);

    formData.append("_method", "PUT");

    axios
      .post(`${path}/api/information/edit/homepage_background_image`, formData)
      .then(() => {
        setEditingItem("");
        setErr("");
        setReload(!reload);
      });
  };

  return (
    <Container>
      <Title>Home</Title>
      <Form>
        <InfoField>
          <TextFieldWrapper>
            <TextField
              className="disabled-bold"
              label="Text"
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
              error={err === "homepage_text" ? true : false}
              disabled={editingItem === "homepage_text" ? false : true}
              multiline
            />
            {err === "homepage_text" && <div className="err">{errMessage}</div>}
          </TextFieldWrapper>
          {editingItem !== "homepage_text" ? (
            <Button onClick={() => setEditingItem("homepage_text")}>
              edit
            </Button>
          ) : (
            <>
              <Button onClick={() => handleEdit("homepage_text", text)}>
                save
              </Button>
              <Button onClick={handleCancel}>cancel</Button>
            </>
          )}
        </InfoField>
        <FileInputWrapper>
          <input
            id="product-img"
            type="file"
            accept="image/png, image/gif, image/jpeg, image/webp"
            onChange={(e) => e.target.files[0] && setImage(e.target.files[0])}
            multiple
          />
          <label htmlFor="product-img">change image</label>

          <div key={image?.id || image?.name} className="img-wrapper-admin">
            {image && (
              <img
                src={
                  image.name ? URL.createObjectURL(image) : `${path}/${image}`
                }
                alt=""
              />
            )}
            {image && image !== defImage && (
              <div className="img-btns">
                <Button
                  className="save-btn"
                  variant="contained"
                  onClick={handleEditImage}
                >
                  save image
                </Button>
                <Button variant="outlined" onClick={() => setImage(defImage)}>
                  cancel
                </Button>
              </div>
            )}
          </div>
        </FileInputWrapper>
      </Form>
    </Container>
  );
};

export default HomeAdmin;
