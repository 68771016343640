import React, { useState, useEffect } from "react";
import {
  AuthForm,
  Input,
  Label,
  FieldWrapper,
  CustomButton,
  Divider,
  TextBtn,
  ErrorField,
} from "./styled";
import ForgetPasswordDialog from "./ForgetPasswordDialog";
import path from "../../constants/path";
import axios from "axios";

const LogInForm = ({ mobile, handleReload, closed }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { email, password };

    const storageItems = JSON.parse(localStorage.getItem("storageCart"));
    const storageItemsQuantities = [];
    const storageItemsProductIds = [];

    for (let item of storageItems) {
      storageItemsQuantities.push(item.quantity);
      storageItemsProductIds.push(item.product_id);
    }

    axios
      .post(`${path}/api/login`, data)
      .then((res) => {
        localStorage.removeItem("rec");
        localStorage.setItem("token", res.data.data.access_token);
      })
      .then((res) => {
        // store storage cart
        const token = localStorage.getItem("token");

        if (JSON.parse(localStorage.getItem("storageCart"))?.length) {
          axios.post(
            `${path}/api/add-to-cart/store-login`,
            {
              quantities: storageItemsQuantities,
              product_ids: storageItemsProductIds,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }

        localStorage.removeItem("storageCart");
        localStorage.removeItem("cartItemsCount");
      })
      .then((res) => {
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setError(err.response?.data?.message);
      });
  };

  useEffect(() => {
    setOpenDialog(false);
    setEmail("");
    setPassword("");
    setError("");
  }, [closed]);

  return (
    <>
      <AuthForm mobile={mobile} onSubmit={handleSubmit}>
        <FieldWrapper mobile={mobile}>
          <Label htmlFor="emaillog" mobile={mobile}>
            Email
          </Label>
          <Input
            name="emaillog"
            id="emaillog"
            mobile={mobile}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </FieldWrapper>
        <FieldWrapper mobile={mobile}>
          <Label htmlFor="passwordlog" mobile={mobile}>
            Password
          </Label>
          <Input
            name="passwordlog"
            id="passwordlog"
            type="password"
            className={`password`}
            mobile={mobile}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            autoComplete="new-password"
          />
        </FieldWrapper>
        {error && <ErrorField mobile={mobile}>{error}</ErrorField>}
        {!!mobile && <Divider />}
        <CustomButton mobile={mobile} type="submit">
          login
        </CustomButton>
        <TextBtn
          type="button"
          mobile={mobile}
          onClick={() => setOpenDialog(true)}
        >
          Forgot password?
        </TextBtn>
      </AuthForm>
      <ForgetPasswordDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        mobile={mobile}
      />
    </>
  );
};

export default LogInForm;
