import styled from "styled-components";

export const ShoppingCartWrapper = styled.div`
  .section-head {
    padding: 120px 0;
    p {
      font-weight: 700;
      font-size: 64px;
      line-height: 78px;
      color: #bababa;
      &.ready {
        color: #232025;
        filter: drop-shadow(0px 0px 1px #bababa);
      }
    }
  }
  .section-shopping-cart {
    .head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #ffffff;
        min-width: 25%;
        max-width: 25%;
        &.left {
          text-align: left;
        }
        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
      }
      .right-side {
        min-width: 592px;
        display: flex;
        justify-content: space-between;
      }
    }
    .checkout-button-responsive {
      display: none;

      color: #232025;
      background-color: #ffffff;
    }
    .body {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      padding: 30px 0;
      .cart-item {
        display: flex;
        justify-content: space-between;
        .left-side {
          .item-product {
            display: flex;
            img {
              margin-right: 15px;
              width: 200px;
              height: 200px;
              cursor: pointer;
            }
            .item-product-right-side {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              row-gap: 10px;
              .upper {
                color: #ffffff;
                .title {
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 22px;
                  text-transform: uppercase;
                  cursor: pointer;
                }
                .price {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 20px;
                }
              }
              .lower {
                p {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 20px;
                  text-decoration-line: underline;
                  text-transform: uppercase;
                  color: #ffffff;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .right-side {
          display: flex;
          justify-content: space-between;
          min-width: 592px;
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            color: #ffffff;
            min-width: 25%;
            max-width: 25%;
            &.left {
              text-align: left;
            }
            &.right {
              text-align: right;
            }
          }
          .product-counter-wrapper {
            min-width: 25%;
            .product-counter {
              margin: 0 auto;
              display: flex;
              max-width: max-content;
              border: 1px solid #ffffff;
              max-height: 33px;
              button {
                width: 32px;
                height: 32px;
                border: none !important;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                text-transform: uppercase;
                color: #ffffff;
                background-color: transparent;
                cursor: pointer;
              }
              p {
                color: #fff;
                min-width: 34px;
                height: 32px;
                border: 1px solid #fff;
                border-width: 0 1px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
        &.responsive {
          display: none;
        }
      }
    }
    .total-items-container {
      padding: 40px 0 126px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        text-transform: uppercase;
        color: #ffffff;
      }
      button {
        padding: 14px 60px;
        max-width: 272px;
        color: #ffffff;
        background-color: #232025;
        &.checkout-button-responsive {
          color: #232025;
          background-color: #ffffff;
        }
        &:hover {
          color: #232025;
          background-color: #ffffff;
        }
      }
    }
  }
  .recommendations-section,
  .recently-viewed-section {
    h3 {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: #bababa;
      margin-bottom: 40px;
    }
    .recommendations-grid,
    .recently-viewed-grid {
      padding-top: 40px;
    }
  }
  @media (max-width: 1440px) {
    .section-shopping-cart {
      .head {
        .right-side {
          min-width: 472px;
        }
      }
      .body {
        .cart-item {
          .right-side {
            min-width: 472px;
            .product-counter {
            }
          }
        }
      }
    }
  }
  @media (max-width: 996px) {
    .section-head {
      padding: 60px 0;
      p {
        font-weight: 700;
        font-size: 48px;
        line-height: 58px;
      }
    }
    .section-shopping-cart {
      .head {
        margin-bottom: 30px;

        .right-side {
          min-width: 400px;
        }
      }
      .body {
        .cart-item {
          .left-side {
            .item-product {
              img {
                max-width: 165px;
                height: auto;
              }
              .item-product-right-side {
                .upper {
                  .title {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 10px;
                  }
                }
                .lower {
                  p {
                    font-size: 14px;
                    line-height: 17px;
                  }
                }
              }
            }
          }
          .right-side {
            min-width: 400px;
          }
        }
      }

      .total-items-container {
        padding: 30px 0 60px;
        flex-direction: column;
        align-items: flex-start;
        p {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 30px;
        }
        button {
          max-width: unset;
          background-color: #ffffff;
          color: #232025;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .section-head {
      padding: 60px 0;
      p {
        font-size: 36px;
        line-height: 44px;
      }
    }
    .section-shopping-cart {
      .head {
        display: none;
      }
      .checkout-button-responsive {
        display: block;
        padding: 14px 60px;
        margin-bottom: 30px;
      }
      .body {
        row-gap: 20px;
        padding: 20px 0 0;
        .cart-item {
          display: none;
          &.responsive {
            display: flex;
            justify-content: space-between;
            .left-side {
              .item-product {
                display: flex;
                flex-direction: column;
                img {
                  margin-bottom: 10px;
                }
                p {
                  cursor: pointer;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 17px;
                  text-decoration-line: underline;
                  text-transform: uppercase;
                  color: #ffffff;
                }
                @media (max-width: 480px) {
                  img {
                    max-width: 80%;
                    height: auto;
                  }
                }
              }
            }
            .item-product-right-side {
              min-width: 40%;
              display: flex;
              flex-direction: column;
              .title-wrapper {
                margin-bottom: 20px;
                .title {
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 17px;
                  text-transform: uppercase;
                  color: #ffffff;
                  margin-bottom: 5px;
                }
                .price {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 17px;
                  color: #ffffff;
                }
              }
              .category-wrapper {
                margin-bottom: 20px;
                p {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 17px;
                  text-transform: uppercase;
                  &.category {
                    color: #bababa;
                    margin-bottom: 5px;
                  }
                  &.category-title {
                    color: #ffffff;
                  }
                }
              }
              .counter-wrapper {
                margin-bottom: 20px;
                .quantity {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 17px;
                  text-transform: uppercase;
                  color: #bababa;
                  margin-bottom: 5px;
                }
                .product-counter {
                  min-width: 25%;
                  display: flex;
                  max-width: max-content;
                  border: 1px solid #ffffff;
                  max-height: 33px;
                  button {
                    width: 32px;
                    height: 32px;
                    border: none !important;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #ffffff;
                    background-color: transparent;
                    cursor: pointer;
                  }
                  p {
                    color: #fff;
                    min-width: 34px;
                    height: 32px;
                    border: 1px solid #fff;
                    border-width: 0 1px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
              .price-wrapper {
                p {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 17px;
                  text-transform: uppercase;
                  &.subtotal {
                    color: #bababa;
                    margin-bottom: 5px;
                  }
                  &.product-price {
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
      .total-items-container {
        padding: 30px 0 60px;
        p {
          font-size: 18px;
          line-height: 22px;
        }
        button {
          padding: 14px 60px;
          max-width: unset;
        }
      }
    }
    .recommendations-section,
    .recently-viewed-section {
      h3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #bababa;
        margin-bottom: 40px;
      }
      .recommendations-grid,
      .recently-viewed-grid {
        padding-top: 40px;
      }
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bababa;
`;
