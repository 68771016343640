import React, { useState, useEffect } from "react";
import path from "../../../../constants/path";
import axios from "axios";
import {
  Form,
  FormWrapper,
  FileInputWrapper,
  EmptySelect,
  TextFieldWrapper,
} from "../../styled";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { CategoryWrapper, LinkBtn } from "./styled";
import NumberTextField from "../../shared-admin/NumberTextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams, useNavigate } from "react-router-dom";

const ProductAdmin = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [category, setCategory] = useState("");
  const [desc, setDesc] = useState("");
  const [usageDesc, setUsageDesc] = useState("");
  const [usageLink, setUsageLink] = useState("");
  const [seoWord, setSeoWord] = useState("");
  const [seoDesc, setSeoDesc] = useState("");
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [deletingImages, setDeletingImages] = useState([]);
  const [discount, setDiscount] = useState("");
  const [availability, setAvailability] = useState("");
  const [id, setId] = useState(0);
  const [errors, setErrors] = useState({});
  const [imgErr, setImgErr] = useState("");
  const { product } = useParams();

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    axios.get(`${path}/api/categories/all`).then((response) => {
      setCategories(response.data.data["all-categories"]);
    });
  }, []);

  const handleImage = (e) => {
    handleErrorRemove("images");
    setImgErr("");
    setNewImages([...newImages, ...Array.from(e.target.files)]);
  };

  const handleRemoveImg = (image) => {
    handleErrorRemove("images");
    setImgErr("");
    if (image.name) {
      setNewImages(newImages.filter((img) => img.name !== image.name));
    } else {
      setImages(images.filter((img) => img.id !== image.id));
      setDeletingImages([...deletingImages, image.id]);
    }
  };

  useEffect(() => {
    axios
      .get(`${path}/api/products/${product}`)
      .then((res) => {
        return res.data.data["single-product"][0];
      })
      .then((product) => {
        setId(product.id);
        setName(product.product_info?.product_name);
        setPrice(product?.price || "");
        setQuantity(product?.quantity || "");
        setAvailability(product?.availability);
        setCategory(product.product_info?.category_name?.[0]?.id);
        setDesc(product.product_info?.product_description || "");
        setUsageDesc(
          product.product_info?.product_how_to_use_description || ""
        );
        setUsageLink(product.product_info?.product_how_to_use_video_link || "");
        setImages(product?.image);
        setDiscount(product?.discount || "");
        setSeoWord(product.product_info?.seo_word || "");
        setSeoDesc(product.product_info?.seo_description || "");
      });
  }, []);

  const handleErrorRemove = (val) => {
    delete errors[val];
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    const data = {
      price,
      product_name: name,
      product_description: desc,
      category_id: category,
      quantity,
    };

    if (discount) {
      data.discount = discount;
    }

    if (usageDesc) {
      data.product_how_to_use_description = usageDesc;
    }
    if (usageLink) {
      data.product_how_to_use_video_link = usageLink;
    }
    if (seoWord) {
      data.seo_word = seoWord;
    }
    if (seoDesc) {
      data.seo_description = seoDesc;
    }

    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    if (newImages.length) {
      for (let img of newImages) {
        formData.append("images[]", img, img.name);
      }
    }

    formData.append("_method", "PUT");

    if (deletingImages.length) {
      deletingImages.map((image) =>
        setTimeout(
          () =>
            axios.delete(`${path}/api/products/remove/image/${image}`, {
              product_id: id,
            }),
          [100]
        )
      );
    }

    axios
      .post(`${path}/api/products/edit/${id}`, formData)
      .then((res) => {
        navigate(-1);
      })
      .catch((err) => {
        if (+err?.request?.status === 413) {
          setImgErr(
            "Something went wrong, in most cases it is due to the size of the image."
          );
        }
        // setErrors(err.response.data.message);
      });
  };

  return (
    <FormWrapper>
      <LinkBtn onClick={() => navigate(-1)}>Back to product list</LinkBtn>
      <Form>
        <CategoryWrapper>
          <FormControl>
            <InputLabel id="category">category</InputLabel>
            <Select
              label="Category"
              labelId="category"
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
                handleErrorRemove("category_id");
              }}
            >
              {categories.length ? (
                categories?.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))
              ) : (
                <EmptySelect>Nothing to show</EmptySelect>
              )}
            </Select>
          </FormControl>
          {errors.category_id && (
            <div className="err">{errors.category_id[0]}</div>
          )}
        </CategoryWrapper>
        <TextFieldWrapper>
          <TextField
            error={errors.product_name ? true : false}
            label="Product name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              handleErrorRemove("product_name");
            }}
          />
          {errors.product_name && (
            <div className="err">{errors.product_name[0]}</div>
          )}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <NumberTextField
            error={errors.price ? true : false}
            customInput={TextField}
            label="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
              handleErrorRemove("price");
            }}
          />
          {errors.price && <div className="err">{errors.price[0]}</div>}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <NumberTextField
            error={errors.discount ? true : false}
            customInput={TextField}
            label="Discount (%)"
            value={discount}
            onChange={(e) => {
              setDiscount(e.target.value);
              handleErrorRemove("discount");
            }}
          />
          {errors.discount && <div className="err">{errors.discount[0]}</div>}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <FormControl>
            <InputLabel id="availability">Availability</InputLabel>
            <Select
              label="Availability"
              labelId="availability"
              value={availability}
              onChange={(e) => setAvailability(e.target.value)}
            >
              <MenuItem value={1}>Available</MenuItem>
              <MenuItem value={0}>Not available</MenuItem>
            </Select>
          </FormControl>
        </TextFieldWrapper>
        <TextFieldWrapper>
          <NumberTextField
            error={errors.quantity ? true : false}
            customInput={TextField}
            label="Quantity"
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value);
              handleErrorRemove("quantity");
            }}
          />
          {errors.quantity && <div className="err">{errors.quantity[0]}</div>}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            error={errors.product_description ? true : false}
            label="Description"
            value={desc}
            onChange={(e) => {
              setDesc(e.target.value);
              handleErrorRemove("product_description");
            }}
            multiline
          />
          {errors.product_description && (
            <div className="err">{errors.product_description[0]}</div>
          )}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            error={errors.product_how_to_use_description ? true : false}
            label="How to use (text)"
            value={usageDesc}
            onChange={(e) => {
              setUsageDesc(e.target.value);
              handleErrorRemove("product_how_to_use_description");
            }}
            multiline
          />
          {errors.product_how_to_use_description && (
            <div className="err">
              {errors.product_how_to_use_description[0]}
            </div>
          )}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            error={errors.product_how_to_use_video_link ? true : false}
            label="How to use (video link)"
            value={usageLink}
            onChange={(e) => {
              setUsageLink(e.target.value);
              handleErrorRemove("product_how_to_use_video_link");
            }}
          />
          {errors.product_how_to_use_video_link && (
            <div className="err">{errors.product_how_to_use_video_link[0]}</div>
          )}
        </TextFieldWrapper>
        <FileInputWrapper>
          <input
            id="product-img"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={handleImage}
            multiple
          />
          <label htmlFor="product-img">add image</label>
          <div className="imgs">
            {(!!images.length || !!newImages.length) &&
              [...images, ...newImages].map((image) => (
                <div key={image.id || image.name} className="img-wrapper">
                  <img
                    src={
                      image.name
                        ? URL.createObjectURL(image)
                        : `${path}/${image.image}`
                    }
                    alt=""
                  />
                  <Button type="button" onClick={() => handleRemoveImg(image)}>
                    <ClearIcon />
                  </Button>
                </div>
              ))}
          </div>
          {imgErr && <div className="err">{imgErr}</div>}
          {errors?.images && <div className="err">{errors?.images?.[0]}</div>}
        </FileInputWrapper>
        <TextFieldWrapper>
          <TextField
            error={errors.seo_word ? true : false}
            label="Title for SEO"
            value={seoWord}
            onChange={(e) => {
              setSeoWord(e.target.value);
              handleErrorRemove("seo_word");
            }}
          />
          {errors.seo_word && <div className="err">{errors.seo_word[0]}</div>}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            error={errors.seo_description ? true : false}
            multiline
            label="Describtion for SEO"
            value={seoDesc}
            onChange={(e) => {
              setSeoDesc(e.target.value);
              handleErrorRemove("seo_description");
            }}
          />
          {errors.seo_description && (
            <div className="err">{errors.seo_description[0]}</div>
          )}
        </TextFieldWrapper>
      </Form>
      <Button variant="contained" className="add" onClick={handleSubmit}>
        save
      </Button>
    </FormWrapper>
  );
};

export default ProductAdmin;
