import React, { useState, useEffect } from "react";
import {
  CustomLink,
  CustomButton,
  ProductsNameExpandButton,
  ProductsNameList,
  ProductName,
} from "./responsive.styled";
import { ExternalLinks, ExternalLink } from "./LeftContent.styled";
import expandIcon from "../../assets/expandIcon.svg";
import blackArrow from "../../assets/blackArrow.svg";
import outlinedArrow from "../../assets/disabledArrow.svg";
import LoggedInMobile from "./LoggedInMobile";
import AuthNavbarMobile from "../auth/AuthNavbarMobile";
import path from "../../constants/path";
import axios from "axios";

const NavBarMobile = ({ closeMenu }) => {
  const [logged, setLogged] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);

  const [reload, setReload] = useState(false);

  const [fbLink, setFbLink] = useState("")
  const [igLink, setIgLink] = useState("")

  useEffect(() => {
    axios.get(`${path}/api/information/facebook_link`).then((res) => {
      setFbLink(res.data.data.information?.value);
    });
    axios.get(`${path}/api/information/instagram_link`).then((res) => {
      setIgLink(res.data.data.information?.value);
    });
  }, []);

  const handleReload = () => {
    setOpenAuth(false);
    setReload(!reload);
  };

  const categories = JSON.parse(localStorage.getItem("categories"));

  useEffect(() => {
    const token = localStorage.getItem("token");
    token ? setLogged(true) : setLogged(false);
  }, [reload]);

  useEffect(() => {
    const closeAuth = () => {
      if (window.innerWidth > 992) {
        setOpenAuth(false);
      }
    };
    window.addEventListener("resize", closeAuth);
    return () => window.removeEventListener("resize", closeAuth);
  }, []);

  return (
    <>
      {logged ? (
        <LoggedInMobile closeMenu={closeMenu} handleReload={handleReload} />
      ) : (
        <>
          <CustomButton onClick={() => setOpenAuth(true)}>
            login/register
          </CustomButton>
          <AuthNavbarMobile
            open={openAuth}
            setOpen={setOpenAuth}
            handleReload={handleReload}
          />
        </>
      )}
      <ProductsNameExpandButton
        onClick={() => setExpanded(!expanded)}
        expanded={expanded}
      >
        <div>products</div>
        <img src={expandIcon} alt="" />
      </ProductsNameExpandButton>
      <ProductsNameList className={expanded ? "show" : ""}>
        {categories?.map((category) => (
          <ProductName
            key={category.id}
            to={`products/filter&id=${category.id}`}
            onClick={closeMenu}
          >
            <div>{category.name}</div>
            <img className="filled" src={blackArrow} alt="->" />
            <img className="outlined" src={outlinedArrow} alt="->" />
          </ProductName>
        ))}
      </ProductsNameList>
      <CustomLink to="/about" onClick={closeMenu}>
        about
      </CustomLink>
      <CustomLink to="/blog" onClick={closeMenu}>
        blog
      </CustomLink>
      <CustomLink to="/contacts" onClick={closeMenu}>
        contacts
      </CustomLink>
      <CustomLink to="/barbershop" onClick={closeMenu}>
        barbershop
      </CustomLink>
      <ExternalLinks>
        {fbLink && <ExternalLink href={fbLink} target="_blank">
          facebook
        </ExternalLink>}
        {igLink && <ExternalLink href={igLink} target="_blank">
          instagram
        </ExternalLink>}
      </ExternalLinks>
    </>
  );
};

export default NavBarMobile;
