import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container, AccountWrapper, Header, Navbar, Link } from "./styled";
import Footer from "../footer/Footer";

const Account = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  let usersName = JSON.parse(localStorage.getItem("usersName"));

  return (
    <>
      <Container className="account">
        <AccountWrapper>
          <Header>
            <div className="outlined">welcome back dear</div>
            <div> {usersName}</div>
          </Header>
          <Navbar>
            <Link to="settings">settings</Link>
            <Link to="orders">orders</Link>
          </Navbar>
        </AccountWrapper>
        <Outlet />
      </Container>
      <Footer />
    </>
  );
};

export default Account;
