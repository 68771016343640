import styled from "styled-components";
import dot from "../../assets/dot.svg";
import activeDot from "../../assets/active-dot.svg";
import dotResponsive from "../../assets/dot-responsive.svg";
import activeDotResponsive from "../../assets/active-dot-responsive.svg";

export const BarbershopWrapper = styled.div`
  background-color: #0a3934;
  color: #ffebe4;
  overflow-x: hidden;
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 3%;
    a {
      color:#FFEBE4;
    }
    .left-side {
      display: flex;
      svg {
        margin-right: 20px;
        &:hover{
          path {
            fill:#FFEBE4
          }
        }
      }
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
      }
    }
  }
  .upper-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;
    box-shadow: inset 0px 0px 40px rgba(255, 235, 228, 0.2);
    // &:hover > .barbershop-container p {
    //   color: #bababa;
    //   filter: drop-shadow(0px 0px 0px #bababa);
    // }

    &:hover > .barbershop-container {
      animation-play-state: paused;
    }
    .barbershop-container {
      width: -webkit-fill-available;
      user-select: none;
      display: flex;
      align-items: center;
      column-gap: 50px;
      justify-content: flex-start;
      position: relative;
      padding: 60px 0;
      animation-name: barbershop-running;
      animation-duration: 115s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
      @keyframes barbershop-running {
        from {
          left: 0%;
        }
        to {
          left: -400%;
        }
      }
      p {
        min-width: max-content;
        font-weight: 500;
        font-size: 24px;
        color: #ffebe4;
        display: flex;
      }
    }
  }
  .section-bio {
    margin-bottom: 120px;
    p {
      font-weight: 700;
      font-size: 54px;
      line-height: 70px;
      letter-spacing: 0.05em;
      color: #ffebe4;
      margin-bottom: 120px;
      span {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: unset;
        text-transform: uppercase;
        margin-right: 50px;
      }
    }
    img {
      width: 100%;
    }
  }
  .section-prices {
    margin-bottom: 120px;
    h3 {
      font-weight: 700;
      font-size: 36px;
      line-height: 50px;
      text-align: center;
      text-transform: uppercase;
      color: #ffebe4;
      margin-bottom: 60px;
    }
    .prices-table {
      display: flex;
      flex-direction: column;
      .head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          text-transform: uppercase;
        }
      }
      .service-row {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;
        p {
          font-weight: 700;
          font-size: 48px;
          line-height: 59px;
          text-transform: uppercase;
          .service-price {
            min-width:fit-content;
          }
        }
      }
    }
  }
  .section-partners {
    overflow: hidden;
    margin-bottom:120px;
    h3 {
      font-weight: 700;
      font-size: 36px;
      line-height: 50px;
      text-align: center;
      text-transform: uppercase;
      color: #FFEBE4;
      margin-bottom:60px;
    }    
    .slider {
      margin: auto;
      position: relative;
      width: 90%;
      display: grid;
      place-items: center;

      .partners-grid {
        width: calc(339px * 10);
        display: flex;
        column-gap: 30px;
        animation: scroll 40s linear infinite;
        @media (max-width: 1440px) {
          column-gap: 22px;
          width: calc(242px * 10);
        }
        .partner-container {
          width: 328px;
          aspect-ratio: 1;
          border: 1px solid #bababa;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: #fff;
          img {
            max-width:200px;
          }
          @media (max-width: 1440px) {
            width: 238px;
          }
          @media (max-width: 480px) {
            img {
            max-width:100px;
            max-height:100px;
          }
          }
        }
        &:hover {
          animation-play-state: paused;
        }
      }
      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-339px * 5));
        }
      }
      @media (max-width: 1440px) {
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(calc(-242px * 5));
          }
        }
      }
    }

    @media (max-width: 768px) {
      .partners-grid {
        column-gap: 20px;
        .partner-container {
          width: 180px;
          border: 1px solid #bababa;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    @media (max-width: 480px) {
      h3 {
        font-size: 16px !important;
        line-height: 20px !important;
        max-width:200px;
        text-align:center;
        margin: 0 auto;
      }
      .partners-grid {
        width: unset;
        .partner-container {
          width: 130px !important;
        }
      }
    }
  }
  }
  .section-store {
    margin-bottom:120px;
    h3 {
      font-weight: 700;
      font-size: 36px;
      line-height: 50px;
      text-align: center;
      text-transform: uppercase;
      color: #FFEBE4;
      margin-bottom:20px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      text-transform: uppercase;
      color: #FFEBE4;
    }
      .slider {
        .carousel-row {          
          img {
            width: 100%;
          }
        }
        width: 100%;
        .carousel-dots {
          margin-top: 30px;
          text-align:left;
          padding-left:80px;
          li {
            width: 10px;
            height:10px;
            &.carousel-dots-active {
              width:54px;
            }
            button {
              &::before {
                border-radius: 50px;
                content: "";
                height: 10px;
                width: 100%;
                background-color: #FFEBE4;
              }
            }
          }
        }
      }
      .container {
        padding: 3% 0 3% 3%;
        background-color:transparent;
        .slick-slider {
          .slick-list {
            .slick-track {
              .slick-slide {
                div {
                  .slide {
                    width: 95% !important;
                    height: auto;
                    display: block;
                    overflow:hidden;
                    min-height: 600px;    
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover; 
                    &.slide-one {
                      background-image: url(${(prop) => prop.imgOne});
                    }
                    &.slide-two {
                      background-image: url(${(prop) => prop.imgTwo});
                    }
                    &.slide-three {
                      background-image: url(${(prop) => prop.imgThree});
                    }
                    &.slide-four {
                      background-image: url(${(prop) => prop.imgFour});
                    }
                    &.slide-five {
                      background-image: url(${(prop) => prop.imgFive});
                    }
                    &:focus-visible {
                      outline:none
                    }
                    img {
                      margin: unset;
                      max-width: 95%;
                      min-width: 95%;
                      &:focus-visible {
                        outline:none
                      }
                    }
                    @media (max-width:1440px) {
                      min-height: 520px;
                    }
                    @media (max-width:1280px) {
                      min-height: 400px;
                    }
                    @media (max-width:900px) {
                      min-height: 320px;
                    }
                    @media (max-width:760px) {
                      min-height: 200px;
                    }
                    @media (max-width:480px) {
                      min-height: 160px;
                    }
                    @media (max-width:380px) {
                      min-height: 120px;
                    }
                  }
                }
              }
            }
          }
          .slick-dots {
            position: relative;
            bottom:-24px;
            left: -5px;
            display:flex !important;
            li {
              button::before {
                content:url(${dot});
                opacity:1
              }
              &.slick-active {
                width:54px;
                button::before {
                  content:url(${activeDot});
                  opacity:1
                }
              }
            }
            @media (max-width: 768px) {
              bottom:-4px;
              left:-4px;
              li {
                width: 0px;
                button::before {
                  content:url(${dotResponsive});
                  width:0;
                }
                &.slick-active {
                  width:30px;
                  margin-right: 0;
                  button::before {
                    content:url(${activeDotResponsive});
                  }
                }
              }
              
            }
          }
        }
      }
  }


.section-footer {
  padding:60px 0;
  display:flex;
  align-items:center;
  justify-content:space-between;
  .left-side {
    p {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }
  .right-side {
    display:flex;
    align-items:center;
    column-gap:20px;
    p, a {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-decoration: underline;
      color: #FFEBE4;
    }
  }
}
@media (max-width: 768px) {
  .section-header {
    padding: 12px 20px;
    .left-side {
      display: flex;
      a {
        margin-right: 20px;
      }
    }
  }

  .upper-carousel {
    margin-bottom:60px;
    .barbershop-container {
      column-gap: 28px;
      padding: 34px 0;
      p {
        font-weight: 400;
        font-size: 32px;
      }
    }
  }
  .section-bio {
    margin-bottom: 60px;
    p {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 60px;
      span {
        display:block;
        margin-bottom: 20px;
      }
    }
  }

  .section-prices {
    margin-bottom: 60px;
    h3 {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 30px;
    }
    .prices-table {
      .service-row {
        padding: 45px 0;
        p {
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
  }
  .section-partners {
    margin-bottom:60px;
    h3 {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 30px;
    }
  }
  .section-store {
    margin-bottom:60px;
    h3 {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 30px;
    }
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 11px;
      margin-bottom:30px;
    }
    .slider {
      .carousel-dots {
        padding-left:20px;
      }
    }
  }
  .lower-carousel {
    .section {
      width:100%;
    }
    .parallax-wrapper {
      .parallax-row {
        padding:50px 0;
      }
    }
  }
  .section-footer {
    padding:30px 0;
    .left-side {
      p {
        font-size: 14px;
        line-height: 17px;
      }
    }
    .right-side {
      p, a {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}
@media (max-width: 480px) {
  .section-header {
    padding: 16px 20px;
    .left-side {
      .responsive {
        display:none;
      }
      a {
        margin-right: 10px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .center {
      svg {
        width:34px;
      }
    }
    .right {
      svg {
        width:102px;
      }
    }
  }
  .upper-carousel {
    .barbershop-container {
      img {
        width:24px;
      }
    }
    margin-bottom: 60px;
    .barbershop-container {
      column-gap: 20px;
      padding: 22px 0;
      p {
        font-size: 16px;
      }
    }
  }
  .section-bio {
    margin-bottom: 60px;
    p {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 30px;
    }
  }
  .section-prices {
    margin-bottom: 60px;
    h3 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 30px;
    }
    .prices-table {
      .head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          text-transform: uppercase;
          display:none
        }
      }
      .service-row {
        padding:30px 0;
        flex-direction:column;
        gap:10px;
        p {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
  }
  .section-store {
    margin-bottom:60px;
    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      margin-bottom:10px;      
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
      .slider {
        .carousel-dots {
          margin-top: 10px;
          padding-left:20px;
          li {
            width: 6px;
            height:6px;
            &.carousel-dots-active {
              width:32px;
            }
            button {
              &::before {
                height: 6px;
              }
            }
          }
        }
      }
  }
  
  .section-footer {
    padding:60px 0;
    flex-direction:column-reverse;
    align-items:flex-start;
    .left-side {
      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
      }
    }
    .right-side {
      display:flex;
      flex-direction:column;
      align-items:flex-start;
      width:92%;
      row-gap:20px;
      margin-bottom:20px;
      p, a {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-decoration: underline;
        color: #FFEBE4;
      }
    }
  }

`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bababa;
`;

export const BarbershopParallaxWrapper = styled.div`
  .lower-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    .parallax-wrapper {
      width: -webkit-fill-available;
      .parallax-row {
        padding: 94px 0;
        display: flex;
        column-gap: 30px;
        row-gap: 30px;
        justify-content: center;
        width: 100%;
        transition: transform 1s;
        transition-timing-function: ease-in-out;
        user-select: none;
        &.to-right {
          transform: translateX(${(prop) => prop.toRight}px);
        }
        &.to-left {
          transform: translateX(${(prop) => prop.toLeft}px);
        }
        p {
          min-width: max-content;
          font-weight: 700;
          font-size: 48px;
          line-height: 59px;
          letter-spacing: 0.1em;
          color: #ffebe4;
          &.stroke {
            color: #0a3934;
            filter: drop-shadow(0px 0px 1px #ffebe4);
          }
        }
      }
    }
    @media (max-width: 768px) {
      .parallax-wrapper {
        .parallax-row {
          padding: 50px 0;
          display: flex;
          column-gap: 15px;
          row-gap: 30px;
          p {
            font-size: 24px;
            line-height: 0;
          }
        }
      }
    }
    @media (max-width: 480px) {
      .parallax-wrapper {
        width: -webkit-fill-available;
        .parallax-row {
          padding: 32px 0;
          display: flex;
          column-gap: 15px;
          row-gap: 30px;
          &.to-right {
            transform: translateX(${(prop) => prop.toRight}px);
          }
          &.to-left {
            transform: translateX(${(prop) => prop.toLeft}px);
          }
          p {
            font-size: 18px;
          }
          &.to-right {
            transform: translateX(${(prop) => prop.toRight}px);
          }
          &.to-left {
            transform: translateX(${(prop) => prop.toLeft}px);
          }
        }
      }
    }
  }
`;
