import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";
import { ContainedButton } from "../../shared/styled";
import blackArrowIcon from "../../assets/blackArrow.svg";
import blackOutlinedArrow from "../../assets/blackOutlinedArrow.svg";
import arrowIcon from "../../assets/outlinedRightArrow.svg";
import whiteArrowIcon from "../../assets/whiteArrow.svg";

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 140px);
  justify-content: space-between;
  align-items: flex-end;
  gap: 40px;
  @media (max-width: 992px) {
    width: 100%;
    align-items: flex-start;
    min-height: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 280px;
  width: 280px;
  align-items: center;
`;

export const AuthWrapper = styled(Wrapper)`
  gap: 15px;
  @media (max-width: 992px) {
    display: none;
  }
`;

export const CustomTypo = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #232025;
`;

export const CustomLink = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  background-color: #9d9d9d;
  border: 1px solid #bababa;
  padding: 10px 20px;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  transition: all 0.5s;
  &:hover {
    background-color: #232025;
  }
  &:after {
    width: 22px;
    height: 20px;
    content: url(${arrowIcon});
  }
  &:hover:after {
    content: url(${whiteArrowIcon});
  }
  @media (max-width: 768px) {
    &:after {
      content: url(${whiteArrowIcon});
    }
  }
`;

export const CustomButton = styled(ContainedButton)`
  margin-top: 5px;
  &:hover {
    background-color: #9d9d9d;
    color: #fff;
  }
`;

export const AddInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
  width: 100%;
  @media (max-width: 992px) {
    align-items: flex-start;
    padding-bottom: 30px !important;
  }
`;

export const InfoTypo = styled(CustomTypo)`
  font-size: 14px;
  text-align: right;
  line-height: 17px;
  text-transform: uppercase;
  width: 100%;
  @media (max-width: 992px) {
    text-align: left;
  }
  & span {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
`;

const linkAnimation = keyframes`
0% {transform: scaleX(0);}
50% {transform: scaleX(1);}
100% {transform: scaleX(0);}
`;

export const TelLink = styled.a`
  font-weight: 700;
  font-size: 22px;
  text-transform: uppercase;
  color: #232025;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: -2px;
    background-color: #232025;
    transform: scaleX(0);
    transform-origin: right;
  }
  @media (hover: hover) {
    &:hover:after {
      transform-origin: left;
      animation-name: ${linkAnimation};
      animation-duration: 500ms;
      animation-timing-function: cubic-bezier(0.5, 0.01, 0, 0.5);
    }
  }
  @media (hover: none) {
    text-decoration: underline;
    &:after {
      display: none;
    }
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const CustomInput = styled.input`
  background-color: #e5e5e5;
  width: 100%;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #232025;
  border-bottom: 1px solid #232025;
  padding: 0 15px 15px 0;
  &:focus {
    outline: none;
  }
  &::placeholder {
    text-transform: uppercase;
    color: rgba(35, 32, 37, 0.5);
  }
`;

export const IconButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 5px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #e5e5e5;
  cursor: pointer;
  &:after {
    width: 22px;
    height: 20px;
    content: url(${blackOutlinedArrow});
  }
  &:hover:after {
    content: url(${blackArrowIcon});
  }
  @media (max-width: 768px) {
    &:after {
      content: url(${blackArrowIcon});
    }
  }
`;

export const Message = styled.div`
  color: #232025;
  font-size: 12px;
  position: absolute;
  bottom: -17px;
  left: 0;
`;

export const AuthNav = styled.div`
  display: flex;
  width: 100%;
`;

export const AuthLink = styled.button`
  width: 50%;
  text-align: center;
  text-transform: capitalize;
  font-size: 16px;
  color: rgba(35, 32, 37, 0.5);
  position: relative;
  border: none;
  background-color: inherit;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      color: #232025;
    }
  }
  &.is-active {
    color: #232025;
  }
  & .to-right {
    transform: translateX(100%);
  }
`;

export const CustomDivider = styled.div`
  transition: transform 250ms cubic-bezier(0.1, 0.76, 0.4, 0.94);
  transform: translateX(0);
  position: absolute;
  bottom: -17px;
  background-color: #232025;
  width: 100%;
  height: 3px;
`;

export const FieldWrapper = styled(Wrapper)`
  align-items: flex-start;
  gap: 5px;
`;

export const Input = styled.input`
  width: 100%;
  background-color: #e5e5e5;
  border: 1px solid #232025;
  min-height: 50px;
  font-size: 16px;
  padding: 12px;
  &:focus {
    outline: none;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  line-height: 17px;
  color: #232025;
`;
