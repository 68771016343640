import React from 'react'
import Footer from '../footer/Footer'
import {Container, ContentWrapper, Divider, Title , List, ListItem, ListItemTitle, ListItemDesc} from './styled'

const Privacy = () => {
  return (
    <>
    <Container>
      <ContentWrapper>
        <Title>Privacy Policy</Title>
        <Divider/>
      </ContentWrapper>
      <ContentWrapper>
        <List>
          <ListItem>
            <ListItemTitle>Real physical address, phone number</ListItemTitle>
            <ListItemDesc>Sayat-Nova 33 055-11-04-06</ListItemDesc>
          </ListItem>
          <ListItem>
            <ListItemTitle>email</ListItemTitle>
            <ListItemDesc>moruqbarbershop@gmail.com</ListItemDesc>
          </ListItem>
          <ListItem>
            <ListItemTitle>cost of delivery</ListItemTitle>
            <ListItemDesc as="div">
              <ul>
                <li>The cost of delivery within Yerevan is AMD 1000.</li>
                <li>To all other regions in Armenia the cost of delivery is
3Kgs and below = AMD 500
Above 3Kgs = AMD 3,000
Delivery time up to 4 working days.</li>
                <li>Delivered items cannot be returned.</li>
              </ul>
            </ListItemDesc>
          </ListItem>
        </List>
        <Divider/>
      </ContentWrapper>
    </Container>
      <Footer/>
    </>
  )
}

export default Privacy