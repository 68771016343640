import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import path from "../../../../constants/path";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import axios from "axios";
import { Products as Users, Title, EmptyPageText, Filters } from "../../styled";
import CustomPagination from "../../shared-admin/CustomPagination";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Searchbar from "../../shared-admin/Searchbar";

const UsersAdmin = () => {
  const [users, setUsers] = useState([]);
  const [arrowAsc, setArrowAsc] = useState(false);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(
    +window.location.hash.slice(1) || 1
  );
  const [isEmpty, setIsEmpty] = useState(false);

  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    navigate(`/admin/users#${page}`);
  };

  useEffect(() => {
    let filters = {};

    if (search) {
      filters.search = search;
    }
    filters.orderBy = arrowAsc ? "asc" : "desc";
    const length = Object.keys(filters)?.length;

    if (length) {
      navigate(`${window.location.pathname}#${1}`);
      setCurrentPage(1);
    }

    const delayFunc = setTimeout(() => {
      !length
        ? axios
            .get(`${path}/api/users?size=10&page=${currentPage}`)
            .then((res) => {
              setPagination(res.data.data.pagination);
              setUsers(res.data.data.users);
              setIsEmpty(!res.data.data.users.length ? true : false);
            })
        : axios
            .post(
              `${path}/api/users/filter?size=10&page=${currentPage}`,
              filters
            )
            .then((res) => {
              setPagination(res.data.data.pagination);
              setUsers(res.data.data.users);
              setIsEmpty(!res.data.data.users.length ? true : false);
            });
    }, 500);
    return () => clearTimeout(delayFunc);
  }, [currentPage, search, arrowAsc]);

  return (
    <Users className="admin-users-wrapper">
      <Title>Users</Title>
      <Filters>
        <Searchbar
          orders="orders"
          value={search}
          handleChange={(e) => setSearch(e.target.value)}
        />
      </Filters>
      {isEmpty ? (
        <EmptyPageText>Nothing to show</EmptyPageText>
      ) : (
        <TableContainer >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="title">
                <TableCell>ID</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Adresses</TableCell>
                <TableCell>
                  <p className="arrow-wrapper">
                  <p> Orders Count</p>
                  <ArrowDropUpIcon
                    onClick={() => setArrowAsc(!arrowAsc)}
                    style={{
                      cursor: "pointer",
                      transform: arrowAsc ? "rotate(180deg)" : "rotate(0)",
                    }}
                  />
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user) => (
                <TableRow
                  key={user?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="product">
                    <p>{user?.id}</p>
                  </TableCell>
                  <TableCell component="th" scope="product">
                    <p>
                      {!isNaN(user?.email_verified_at)
                        ? "Unverified"
                        : moment(user?.email_verified_at).format("DD/MM/YYYY")}
                    </p>
                  </TableCell>
                  <TableCell className="name">
                    <p>{user?.name}</p>
                  </TableCell>
                  <TableCell>
                    <p>{user?.phone}</p>
                  </TableCell>
                  <TableCell>
                    <p>{user?.email}</p>
                  </TableCell>
                  <TableCell>
                    <p>{user?.user_status[0]?.name}</p>
                  </TableCell>
                  <TableCell>
                    {user?.addresses.map((addressInfo, index) => {
                      return (
                        <p key={addressInfo?.id}>{`${addressInfo?.address}`}</p>
                      );
                    })}
                  </TableCell>
                  <TableCell>
                    <p>{user?.orders_count}</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {pagination.pages_count > 1 && (
        <CustomPagination
          page={+currentPage}
          pageCount={pagination.pages_count}
          handlePageChange={handlePageChange}
        />
      )}
    </Users>
  );
};

export default UsersAdmin;
