import styled, { css, keyframes } from "styled-components";
import { NavLink } from "react-router-dom";
import minusIconOutlined from "../../assets/account/minusIconOutlined.svg";
import minusIcon from "../../assets/account/minusIcon.svg";
import plusIconOutlined from "../../assets/account/plusIconOutlined.svg";
import plusIcon from "../../assets/account/plusIcon.svg";

const columnFlex = css`
  display: flex;
  flex-direction: column;
`;

const centerFlex = css`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  padding: 120px 80px;
  ${columnFlex};
  gap: 40px;
  @media (max-width: 1440px) {
    padding: 60px 20px;
    gap: 30px;
  }
  /* &.center {
    align-items: center;
  } */
  @media (max-width: 768px) {
    & button {
      max-width: 100%;
    }
  }
  &.account,
  &.single-order {
    padding: 120px 0;
    max-width: 92%;
    margin: 0 auto;
    @media (max-width: 1024px) {
      padding: 60px 20px;
      gap: 30px;
      max-width: 100%;
    }
  }
`;

export const AccountWrapper = styled.div`
  ${columnFlex}
  gap:120px;
  @media (max-width: 1440px) {
    gap: 60px;
  }
`;

export const Header = styled.div`
  ${columnFlex}
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  text-transform: capitalize;
  color: #fff;
  & .outlined {
    color: #232025;
    filter: drop-shadow(0px 0px 1px #bababa);
  }
  @media (max-width: 1440px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const Navbar = styled.div`
  ${centerFlex}
  gap:30px;
`;

export const Link = styled(NavLink)`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #bababa;
  &.active {
    color: #fff;
    text-decoration: underline;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #fff;
`;

export const SubHeaderWrapper = styled.div`
  ${centerFlex};
  justify-content: space-between;
`;

export const SubHeader = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  @media (max-width: 1440px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const ExpandBtn = styled.button`
  ${centerFlex};
  justify-content: center;
  position: relative;
  background-color: #232025;
  border: none;
  cursor: pointer;
  position: relative;
  @media (max-width: 1440px) {
    transform: scale(0.5);
  }

  @media (hover: hover) {
    &:after {
      content: url(${plusIconOutlined});
      width: 100%;
      height: 100%;
    }
    &.expanded:after {
      content: url(${minusIconOutlined});
      width: 100%;
    }
    &:hover:after {
      content: url(${plusIcon});
    }
    &.expanded:hover:after {
      content: url(${minusIcon});
    }
  }
  @media (hover: none) {
    &:after {
      content: url(${plusIcon});
      width: 100%;
      height: 100%;
    }
    &.expanded:after {
      content: url(${minusIcon});
      width: 100%;
    }
  }
`;

export const SubTitle = styled.div`
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #bababa;
`;

export const Form = styled.form`
  ${columnFlex}
  gap:40px;
  @media (max-width: 1440px) {
    gap: 30px;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 1080px) {
    ${columnFlex}
    align-items:flex-start;
  }
`;

export const Inputs = styled.div`
  ${centerFlex}
  justify-content: flex-start;
  gap: 15px;
  @media (max-width: 1080px) {
    width: 100%;
    ${columnFlex}
    align-items:flex-start;
    gap: 20px;
  }
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  @media (max-width: 1080px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    ${columnFlex}
    align-items:flex-start;
    gap: 20px;
  }
`;

export const InputWrapper = styled.div`
  ${columnFlex}
  gap:5px;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  min-width: 280px;
  @media (max-width: 1080px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const PhoneWrapper = styled.div`
  position: relative;
  & .suff {
    position: absolute;
    top: 18px;
    left: 15px;
    font-size: 14px;
    line-height: 17px;
  }
  & .phone {
    padding-left: 50px;
  }
  &.register {
    width: 100%;
    color: ${({ mobile }) => (mobile ? "#fff" : "#232025")};
    & .suff {
      font-size: 16px;
      top: 16.5px;
    }
  }
`;

export const Input = styled.input`
  background-color: #232025;
  border: 1px solid #fff;
  &:focus {
    outline: none;
  }
  padding: 16.5px 15px;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  width: 100%;
  &.error {
    border: 1px solid #ee000e;
  }
`;

export const TextBtnWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
  & img {
    width: 14px;
    height: 14px;
  }
  &.radio {
    height: 100%;
    align-self: center;
  }
`;

const animation = keyframes`
0% {transform: scaleX(0);}
50% {transform: scaleX(1);}
100% {transform: scaleX(0);}
`;

export const TextBtn = styled.button`
  background-color: #232025;
  border: none;
  color: #fff;
  position: relative;
  cursor: pointer;
  margin-top: 12.5px;
  min-width: 95px;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: right;
  }
  @media (hover: hover) {
    &:hover:after {
      transform-origin: left;
      animation-name: ${animation};
      animation-duration: 500ms;
      animation-timing-function: cubic-bezier(0.5, 0.01, 0, 0.5);
    }
  }
  @media (hover: none) {
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const Btn = styled.button`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  max-width: 272px;
  width: 100%;
  padding: 15px 0;
  background-color: #232025;
  border: 1px solid #fff;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      color: #232025;
      background-color: #fff;
    }
  }
  @media (max-width: 768px) {
    max-width: 100%;
    color: #232025;
    background-color: #fff;
  }
`;

export const Addresses = styled.div`
  ${centerFlex}
  gap:20px;
  flex-wrap: wrap;
  align-items: stretch;
  & .btn-wrapper {
    display: flex;
    align-items: center;
  }
`;

export const OrdersWrapper = styled.div`
  .section-order {
    padding: 20px 0 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .order-number,
    .order-status {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #bababa;
      margin-bottom: 10px;
      span {
        font-weight: 700;
      }
    }
    .row {
      margin-bottom: 20px;
    }
    .order-status-number {
      display: flex;
      gap: 20px;
    }
  }
  .order-grid {
    padding: 10px 0 40px;
    gap: 40px;
    .ordered-quantity {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #ffffff;
      margin-top: 10px;
    }
  }
  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    button,
    a {
      max-width: 272px;
      height: fit-content;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      font-weight: 400;
      padding: 14px 70px;
      width: 100%;
      min-width: 280px;
      color: #fff;
      background-color: #232025;
      border: 1px solid #fff;
      text-transform: uppercase;
      cursor: pointer;
    }
    .total-price {
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  .footer-container {
    margin-top: 80px;
  }
  @media (max-width: 768px) {
    .order-status-number {
      flex-direction: column;
    }
    .order-grid {
      padding: 10px 0 40px;
      gap: 20px;
      .ordered-quantity {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        margin-top: 10px;
      }
    }
    .buttons-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      align-items: flex-start;
      .total-price {
        margin-bottom: 20px;
        font-size: 32px;
      }
      button {
        max-width: 100%;
        margin-bottom: 20px;
        background-color: #ffffff;
        color: #232025;
      }
    }
  }
  @media (max-width: 480px) {
    .order-grid {
      padding: 10px 0 40px;
      column-gap: 10px;
      row-gap: 30px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      div {
        width: 100%;
      }
    }
    .buttons-container {
      margin-bottom: 10px;
      .total-price {
        margin-bottom: 20px;
        font-size: 24px;
      }
    }
  }
`;

export const Message = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: #fff;
  &.error:before {
    content: "error: ";
    color: #ee000e;
    text-transform: uppercase;
    font-weight: 700;
  }
`;

export const Empty = styled.h3`
  color: #bababa;
  font-size: 24px;
  font-weight: bold;
  margin: 30px 0;
`;
