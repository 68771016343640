import React, { useState, useEffect } from "react";
import path from "../../../../constants/path";
import axios from "axios";
import { BtnLink, Container, EmptyPageText, Title as Main } from "../../styled";
import {
  PartnersWrapper,
  Partners,
  Title,
  Partner,
  ActionBtns,
} from "./styled";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "../DeleteDialog";

const PartnersAdmin = () => {
  const [partners, setPartners] = useState([]);
  const [partnersIsEmpty, setPartnersIsEmpty] = useState(false);
  const [barberPartners, setBarberPartners] = useState([]);
  const [barberPartnersIsEmpty, setBarberPartnersIsEmpty] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);

  const [reload, setReload] = useState(false);

  useEffect(() => {
    axios.get(`${path}/api/partners/home`).then((res) => {
      setPartners(res.data.data.partners);
      !res.data.data.partners.length && setPartnersIsEmpty(true);
    });
    axios.get(`${path}/api/partners/barber`).then((res) => {
      setBarberPartners(res.data.data.partners);
      !res.data.data.partners.length && setBarberPartnersIsEmpty(true);
    });
  }, [reload]);

  const navigate = useNavigate();

  const handleRemovePartner = () => {
    axios.delete(`${path}/api/partners/${deletingItem.id}/delete`).then(() => {
      setReload(!reload);
      setOpenDialog(false);
    });
  };

  return (
    <Container>
      <Main>Partners</Main>
      <PartnersWrapper>
        <div className="subheader">
          <Title>Partners</Title>
          <BtnLink to="/admin/partners/add-new/custom">add new partner</BtnLink>
        </div>
        <Partners>
          {!partnersIsEmpty ? (
            partners.map((partner) => (
              <Partner key={partner.id}>
                <ActionBtns>
                  <Tooltip title="edit" placement="top">
                    <Button
                      onClick={() =>
                        navigate(`/admin/partners/${partner.id}/home`)
                      }
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="remove" placement="top">
                    <Button
                      onClick={() => {
                        setDeletingItem(partner);
                        setOpenDialog(true);
                      }}
                    >
                      <ClearIcon />
                    </Button>
                  </Tooltip>
                </ActionBtns>
                <Tooltip title={partner.name} placement="bottom">
                  <div className="name">{partner.name}</div>
                </Tooltip>
                <img src={`${path}/${partner.image}`} alt="" />
                <Tooltip title={partner.url} placement="bottom">
                  <a
                    className="link"
                    href={
                      partner.url.includes("https")
                        ? partner.url
                        : `https://${partner.url}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {partner.url}
                  </a>
                </Tooltip>
              </Partner>
            ))
          ) : (
            <EmptyPageText>Nothing to show</EmptyPageText>
          )}
        </Partners>
      </PartnersWrapper>
      <PartnersWrapper>
        <div className="subheader barber">
          <Title>Barbershop Partners</Title>
          <BtnLink to="/admin/partners/add-new/barber">
            add new barbershop partner
          </BtnLink>
        </div>
        <Partners>
          {!barberPartnersIsEmpty ? (
            barberPartners.map((partner) => (
              <Partner key={partner.id}>
                <ActionBtns>
                  <Tooltip title="edit" placement="top">
                    <Button
                      onClick={() =>
                        navigate(`/admin/partners/${partner.id}/barber`)
                      }
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="remove" placement="top">
                    <Button
                      onClick={() => {
                        setDeletingItem(partner);
                        setOpenDialog(true);
                      }}
                    >
                      <ClearIcon />
                    </Button>
                  </Tooltip>
                </ActionBtns>
                <Tooltip title={partner.name} placement="bottom">
                  <div className="name">{partner.name}</div>
                </Tooltip>
                <img src={`${path}/${partner.image}`} alt="" />
                <Tooltip title={partner.url} placement="bottom">
                  <a
                    className="link"
                    href={
                      partner.url.includes("https")
                        ? partner.url
                        : `https://${partner.url}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {partner.url}
                  </a>
                </Tooltip>
              </Partner>
            ))
          ) : (
            <EmptyPageText>Nothing to show</EmptyPageText>
          )}
        </Partners>
      </PartnersWrapper>
      <DeleteDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        handleClose={() => setOpenDialog(false)}
        handleRemove={handleRemovePartner}
        name={`partner ${deletingItem?.name}`}
      />
    </Container>
  );
};

export default PartnersAdmin;
