import React, { useState } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import path from "../../../constants/path";
import { useNavigate } from "react-router-dom";
import { TextFieldWrapper } from "../styled";
import logo from "../../../assets/moruq_logo_menu.svg";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  justify-content: center;
  & .MuiFormControl-root {
    & .Mui-focused fieldset {
      border-color: #24ae85;
    }
    & .MuiInputLabel-root {
      &.Mui-focused {
        color: #24ae85;
      }
    }
    & .Mui-focused {
      & .MuiSvgIcon-root {
        color: #24ae85;
      }
    }
  }
`;

const Form = styled.form`
  margin-top: 50px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  & .MuiButtonBase-root {
    width: 100%;
    max-width: 450px;
    padding: 10px 0;
    background-color: #24ae85 !important;
    font-weight: bold;
  }
`;

const Error = styled.div`
  font-size: 18px;
  &::before {
    content: "Error: ";
    color: red;
    font-size: 16px;
  }
`;

const AdminLogIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [err, setErr] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    axios
      .post(`${path}/api/adminLogin`, { email, password })
      .then((res) => {
        localStorage.setItem("token", res.data.data.access_token);
        localStorage.setItem("permission", true);
        localStorage.removeItem("rec");
        navigate("/admin/products", { replace: true });
      })
      .catch(() => setErr(true));
  };
  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Container onKeyDown={onKeyDown}>
      <Form onSubmit={handleSubmit}>
        <div>
          <img src={logo} alt="" />
        </div>
        <TextFieldWrapper>
          <TextField
            type="email"
            label="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErr(false);
            }}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            autoComplete="off"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setErr(false);
            }}
            label="Password"
          />
        </TextFieldWrapper>
        {err && <Error>Wrong Email or Password.</Error>}
        <Button variant="contained" onClick={handleSubmit}>
          login
        </Button>
      </Form>
    </Container>
  );
};

export default AdminLogIn;
