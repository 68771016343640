import styled from "styled-components";
import { TextButton } from "../headers/styled";

export const MenuCartContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bababa;
`;

export const MainMenu = styled.div`
  background-color: #e5e5e5;
  position: fixed;
  z-index: 20;
  bottom: 0;
  right: 0;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  &.close-menu {
    height: 0;
    transition: height 1s cubic-bezier(0.85, 0.01, 0.4, 1);
  }
  &.open-menu {
    height: 100vh;
    transition: height 1s cubic-bezier(0.85, 0.01, 0.4, 1);
  }
  padding: 0 80px;
  @media (max-width: 992px) {
    padding: 0 20px;
  }
  display: flex;
  flex-direction: column;
`;

export const MenuHeader = styled.div`
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: scaleY(0);
  transition: transform 0.8s;
  transform-origin: top;
  &.show {
    transform: scaleY(1);
    transition: transform 1s;
    transform-origin: bottom;
  }
`;

export const MenuContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 50px;
  width: 100%;
  padding: 30px 0;
  @media (max-width: 992px) {
    flex-direction: column;
    height: 100%;
  }
`;

export const MenuTextButton = styled(TextButton)`
  color: #232025;
  @media (hover: hover) {
    &:hover {
      color: rgba(35, 32, 37, 0.5);
    }
  }
  @media (hover: none) {
    color: #232025;
  }
`;
