import React, { useState } from "react";
import {
  Background,
  Dialog,
  IconButton,
  Divider,
} from "../../shared/dialog/styled";
import closeIcon from "../../assets/closeIcon.svg";
import {
  AuthTitle,
  AuthNavigation,
  CustomBtn,
  Underline,
} from "../auth/AuthNavbarMobile.styled";
import Login from "./Login";
import Register from "./Register";

const LoginDialog = ({ open, handleClose, right, handleOpen }) => {
  const [toRight, setToRight] = useState(right);

  return (
    <>
      <Background onClick={handleClose} className={open && "show"} />
      <Dialog className={`login ${open && "show"} ${toRight ? "reg" : "log"}`}>
        <AuthTitle>
          <AuthNavigation>
            <CustomBtn
              onClick={() => setToRight(false)}
              className={!toRight ? "is-active" : ""}
            >
              <div>login</div>
              <Underline className={toRight ? "to-right" : ""} />
            </CustomBtn>
            <CustomBtn
              onClick={() => setToRight(true)}
              className={toRight ? "is-active" : ""}
            >
              register
            </CustomBtn>
          </AuthNavigation>
          <IconButton onClick={handleClose}>
            <img src={closeIcon} alt="X" />
          </IconButton>
        </AuthTitle>
        <Divider className="forget-password" />
        {!toRight ? (
          <Login handleClose={handleClose} handleOpen={handleOpen} />
        ) : (
          <Register handleClose={handleClose} />
        )}
      </Dialog>
    </>
  );
};

export default LoginDialog;
