import React, { useState } from "react";
import {
  Background,
  Dialog,
  Header,
  IconButton,
  Divider,
  Form,
  InputWrapper,
  Input,
  Button,
  ButtonWrapper,
  ErrorField,
} from "../../shared/dialog/styled";
import closeIcon from "../../assets/closeIcon.svg";
import path from "../../constants/path";
import axios from "axios";

const EditPassword = ({ closeEdit, show }) => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  const [errors, setErrors] = useState({});
  const [errMessage, setErrMessage] = useState("");

  const handleErrorRemove = (val) => {
    delete errors[val];
  };

  const closeDialog = () => {
    closeEdit();
    setPassword("");
    setNewPassword("");
    setConfirm("");
    setErrors({});
    setErrMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    axios
      .post(
        `${path}/api/users/edit/password`,
        {
          current_password: password,
          new_password: newPassword,
          new_password_confirmation: confirm,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => closeDialog())
      .catch((err) => {
        err.response.data.data
          ? setErrors(err.response.data.data)
          : setErrMessage(err.response.data.message);
      });
  };

  return (
    <>
      <Background onClick={closeDialog} className={show && "show"} />
      <Dialog
        className={`${show && "show"} ${
          (!!Object.keys(errors)?.length || errMessage) && "large"
        }`}
      >
        <Header>
          <div>Edit password</div>
          <IconButton onClick={closeDialog}>
            <img src={closeIcon} alt="close" />
          </IconButton>
        </Header>
        <Divider />
        <Form onSubmit={handleSubmit}>
          <input
            name="username"
            autoComplete="username"
            style={{ display: "none" }}
          />
          <InputWrapper>
            <label>Current password</label>
            <Input
              type="password"
              autoComplete="new-password"
              className={`password ${errors?.current_password && "error"}`}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                handleErrorRemove("current_password");
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <label>New password</label>
            <Input
              type="password"
              autoComplete="new-password"
              className={`password ${errors?.new_password && "error"}`}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                handleErrorRemove("new_password");
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <label>Confirm password</label>
            <Input
              type="password"
              autoComplete="new-password"
              className={`password ${
                errors?.new_password_confirmation && "error"
              }`}
              value={confirm}
              onChange={(e) => {
                setConfirm(e.target.value);
                handleErrorRemove("new_password_confirmation");
              }}
            />
          </InputWrapper>
          {(!!Object.keys(errors)?.length || errMessage) && (
            <ErrorField>
              {errMessage || "Make sure all fields are filled correctly."}
            </ErrorField>
          )}
          <Divider />
          <ButtonWrapper>
            <Button>edit</Button>
          </ButtonWrapper>
        </Form>
      </Dialog>
    </>
  );
};

export default EditPassword;
