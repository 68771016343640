import React, { useState, useEffect } from "react";
import { SuccessField } from "../../shared/dialog/styled";
import {
  Divider,
  Form,
  InputWrapper,
  Input,
  Button,
  ButtonWrapper,
  ErrorField,
} from "../../shared/dialog/styled";
import NumberInput from "../../shared/NumberInput";
import axios from "axios";
import path from "../../constants/path";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name,
      email,
      phone: `+374${phone}`,
      password,
      password_confirmation,
    };

    const storageItems = JSON.parse(localStorage.getItem("storageCart"));
    const storageItemsQuantities = [];
    const storageItemsProductIds = [];

    for (let item of storageItems) {
      storageItemsQuantities.push(item.quantity);
      storageItemsProductIds.push(item.product_id);
    }

    axios
      .post(`${path}/api/register`, data)
      .then((res) => {
        setSuccess(true);
        return res?.data?.data?.user_id;
      })
      .then((id) => {
        if (JSON.parse(localStorage.getItem("storageCart"))?.length) {
          axios.post(`${path}/api/add-to-cart/store-login`, {
            user_id: id,
            quantities: storageItemsQuantities,
            product_ids: storageItemsProductIds,
          });
        }
        localStorage.removeItem("storageCart");
        localStorage.removeItem("cartItemsCount");
        localStorage.removeItem("rec");
      })
      .catch((err) => setErrors(err.response.data.data));
  };

  const handleErrorRemove = (val) => {
    delete errors[val];
  };

  return (
    <Form onSubmit={handleSubmit} className="reg checkout-reg">
      <InputWrapper>
        <label htmlFor="name">Name</label>
        <Input
          id="name"
          className={`${errors?.name && "error"}`}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            handleErrorRemove("name");
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="phone">Phone</label>
        <Input
          as={NumberInput}
          className={`${errors?.phone && "error"}`}
          id="phone"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
            handleErrorRemove("phone");
          }}
        />
      </InputWrapper>

      <InputWrapper>
        <label htmlFor="email-checkout">Email</label>
        <Input
          id="email-checkout"
          className={`${errors?.email && "error"}`}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            handleErrorRemove("email");
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="password">Password</label>
        <Input
          id="password"
          className={`${errors?.password && "error"}`}
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            handleErrorRemove("password");
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="password_confirmation">Confirm password</label>
        <Input
          id="password_confirmation"
          type="password"
          className={`${errors?.password_confirmation && "error"}`}
          value={password_confirmation}
          onChange={(e) => {
            setPassword_confirmation(e.target.value);
            handleErrorRemove("password_confirmation");
          }}
        />
      </InputWrapper>
      {!!Object.keys(errors).length && (
        <ErrorField className="reg">
          Make sure all fields are filled correctly
        </ErrorField>
      )}
      {success && <SuccessField>Please check your email.</SuccessField>}
      <Divider className="reg" />
      <ButtonWrapper className="reg">
        <Button>register</Button>
      </ButtonWrapper>
    </Form>
  );
};

export default Register;
