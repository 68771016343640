import React, { useState, useEffect } from "react";
import {
  MainMenu,
  MenuHeader,
  MenuCartContainer,
  MenuContent,
  MenuTextButton,
} from "./styled";
import { Link } from "react-router-dom";
import logo from "../../assets/moruq_logo_menu.svg";
import LeftContent from "./LeftContent";
import RightMenuLoggedOut from "./RightMenuLoggedOut";
import RightMenuLoggedIn from "./RightMenuLoggedIn";
import AddInfo from "./AddInfo";
import { RightContent } from "./RightContent.styled";
import NavBarMobile from "./NavBarMobile";
import { MobileMenu } from "./responsive.styled";

const Menu = ({ closeMenu, open }) => {
  const [logged, setLogged] = useState(false);

  const [reload, setReload] = useState(false);

  const handleReload = () => setReload(!reload);

  const [closed, setClosed] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    token ? setLogged(true) : setLogged(false);
  }, [reload]);

  return (
    <MainMenu open={open} className={open ? "open-menu" : "close-menu"}>
      <MenuHeader className={open && "show"}>
        <Link to="/" onClick={closeMenu}>
          <img alt="logo" src={logo} />
        </Link>
        <MenuCartContainer>
          <MenuTextButton
            onClick={() => {
              setClosed(true);
              closeMenu();
            }}
          >
            <div>close</div>
          </MenuTextButton>
        </MenuCartContainer>
      </MenuHeader>
      <MenuContent>
        <MobileMenu>
          <NavBarMobile closeMenu={closeMenu} />
        </MobileMenu>
        <LeftContent closeMenu={closeMenu} />
        <RightContent>
          {logged ? (
            <RightMenuLoggedIn
              closeMenu={closeMenu}
              handleReload={handleReload}
            />
          ) : (
            <RightMenuLoggedOut
              closeMenu={closeMenu}
              handleReload={handleReload}
              closed={closed}
            />
          )}
          <AddInfo />
        </RightContent>
      </MenuContent>
    </MainMenu>
  );
};

export default Menu;
