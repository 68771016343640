import React, { useState } from "react";
import {
  AuthWrapper,
  AuthNav,
  AuthLink,
  CustomDivider,
} from "./RightContent.styled";
import { Divider } from "./styled";
import LogInForm from "../auth/LogInForm";
import RegisterForm from "../auth/RegisterForm";

const RightMenuLoggedOut = ({ handleReload, closed }) => {
  const [toLeft, setToLeft] = useState(true);

  return (
    <AuthWrapper>
      <AuthNav>
        <AuthLink
          onClick={() => setToLeft(true)}
          className={toLeft ? "is-active" : ""}
        >
          <div>login</div>
          <CustomDivider className={!toLeft ? "to-right" : ""} />
        </AuthLink>
        <AuthLink
          onClick={() => setToLeft(false)}
          className={!toLeft ? "is-active" : ""}
        >
          register
        </AuthLink>
      </AuthNav>
      <Divider />
      {toLeft ? (
        <LogInForm handleReload={handleReload} closed={closed} />
      ) : (
        <RegisterForm closed={closed} />
      )}
    </AuthWrapper>
  );
};

export default RightMenuLoggedOut;
