import styled from "styled-components";
import { Wrapper } from "../menu/RightContent.styled";
import { NavLink } from "react-router-dom";
import { ContainedButton } from "../../shared/styled";

export const AuthForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 280px;
  width: 280px;
  align-items: center;
  gap: ${({ mobile }) => (mobile ? "20px" : "15px")};
  padding: ${({ mobile }) => mobile && "30px 0"};
  max-width: ${({ mobile }) => mobile && "100%"};
  width: ${({ mobile }) => mobile && "100%"};
`;

export const CustomTypo = styled.div`
  font-size: 16px;
  text-align: center;
  color: #232025;
`;

export const CustomLink = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  background-color: #9d9d9d;
  border: 1px solid #bababa;
  padding: 10px 20px;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  transition: all 0.5s;
  &:hover {
    background-color: #232025;
  }
  & img {
    width: 22px;
    height: 20px;
  }
`;

export const CustomButton = styled(ContainedButton)`
  margin-top: 5px;
  @media (hover: hover) {
    &:hover {
      background-color: #9d9d9d;
      color: #fff;
    }
  }
  @media (max-width: 992px) {
    color: #232025;
    background-color: #fff;
    &:hover {
      color: #232025;
      background-color: #fff;
    }
  }
`;

export const AddInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
  max-width: 425px;
  width: 100%;
  @media (max-width: 992px), (max-height: 880px) {
    align-items: flex-start;
  }
`;

export const InfoTypo = styled(CustomTypo)`
  font-size: 14px;
  text-align: right;
  line-height: 17px;
  text-transform: uppercase;
  width: 100%;
  @media (max-width: 992px), (max-height: 880px) {
    text-align: left;
  }
`;

export const TelLink = styled.a`
  font-weight: 700;
  font-size: 22px;
  text-transform: uppercase;
  color: #232025;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: -2px;
    background-color: #232025;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 250ms cubic-bezier(0.31, 0, 0.05, 0.93);
  }
  @media (hover: hover) {
    &:hover:after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const CustomInput = styled.input`
  background-color: #e5e5e5;
  width: 100%;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #232025;
  border-bottom: 1px solid #232025;
  padding: 0 15px 15px 0;
  &:focus {
    outline: none;
  }
  &::placeholder {
    text-transform: uppercase;
    color: rgba(35, 32, 37, 0.5);
  }
`;

export const IconButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 5px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #e5e5e5;
  cursor: pointer;
`;

export const AuthNav = styled.div`
  display: flex;
  width: 100%;
`;

export const AuthLink = styled.button`
  width: 50%;
  text-align: center;
  text-transform: capitalize;
  font-size: 16px;
  color: rgba(35, 32, 37, 0.5);
  position: relative;
  border: none;
  background-color: inherit;
  cursor: pointer;
  &.is-active {
    color: #232025;
  }
  & .to-right {
    transform: translateX(100%);
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bababa;
  margin: 30px 0;
`;

export const CustomDivider = styled.div`
  transition: transform 250ms cubic-bezier(0.1, 0.76, 0.4, 0.94);
  transform: translateX(0);
  position: absolute;
  bottom: -17px;
  background-color: #232025;
  width: 100%;
  height: 3px;
`;

export const FieldWrapper = styled(Wrapper)`
  align-items: flex-start;
  gap: 5px;
  max-width: ${({ mobile }) => mobile && "100%"};
  width: ${({ mobile }) => mobile && "100%"};
`;

export const Input = styled.input`
  width: 100%;
  background-color: ${({ mobile }) => (mobile ? "#232025" : "#e5e5e5")};
  color: ${({ mobile }) => (mobile ? "#fff" : "#232025")};
  border: ${({ mobile }) =>
    mobile ? "1px solid #bababa" : "1px solid #232025"};
  min-height: 50px;
  font-size: 16px;
  padding: 12px;
  &:focus {
    outline: none;
  }
  &.password {
    font-size: 24px;
    letter-spacing: 1.5px;
    padding: 9.5px 15px;
  }
  &.error {
    border: 1px solid #ee000e;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: ${({ mobile }) =>
      mobile ? "0 0 0px 1000px #232025 inset" : "0 0 0px 1000px #e5e5e5 inset"};
    -webkit-text-fill-color: ${({ mobile }) =>
      mobile ? "#fff !important" : "#232025"};
  }
`;

export const Label = styled.label`
  font-size: 14px;
  line-height: 17px;
  color: ${({ mobile }) => (mobile ? "#fff" : "#232025")};
`;

export const TextBtn = styled.button`
  border: none;
  background-color: inherit;
  color: ${({ mobile }) => (mobile ? "#fff" : "#232025")};
  cursor: pointer;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  margin-top: 10px;
`;

export const ErrorField = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${({ mobile }) => (mobile ? "#fff" : "#232025")};
  &:before {
    content: "error: ";
    color: #ee000e;
    text-transform: uppercase;
    font-weight: 700;
  }
`;

export const SuccessField = styled.div`
  font-size: 16px;
  color: ${({ mobile }) => (mobile ? "#fff" : "#232025")};
`;

export const ResetPage = styled.div`
  position: absolute;
  inset: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #232025;
  display: flex;
  justify-content: center;
  padding: 30px 60px;
  @media (max-width: 768px) {
    padding: 30px;
  }
`;

export const ResetForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
  max-width: 320px;
  width: 100%;
`;

export const ResetField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

export const ResetLabel = styled(Label)`
  color: #fff !important;
`;

export const ResetInput = styled(Input)`
  background-color: #232025;
  color: #fff;
  border-color: #fff;
`;

export const ChangeButton = styled(ContainedButton)`
  background-color: #fff;
  color: #232025;
  min-width: 0;
`;

export const ResetHeader = styled.div`
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
`;

export const ResetDivider = styled(Divider)`
  margin: 10px 0;
`;

export const ErrorFieldReset = styled(ErrorField)`
  color: #fff;
`;

export const SuccessFieldReset = styled(SuccessField)`
  color: #fff;
`;
