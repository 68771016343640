import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import path from "../../../../constants/path";
import axios from "axios";
import {
  Products as Orders,
  EmptySelect,
  Filters,
  EmptyPageText,
  TooltipContent,
  Title,
} from "../../styled";
import CustomPagination from "../../shared-admin/CustomPagination";
import Searchbar from "../../shared-admin/Searchbar";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { Address, Btns, ErrMessage } from "./styled";

const OrdersAdmin = () => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    +window.location.hash.slice(1) || 1
  );
  const [pageInfo, setPageInfo] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios.get(`${path}/api/categories/all`).then((response) => {
      setCategories(response.data.data["all-categories"]);
    });
  }, []);
  const [categoryId, setCategoryId] = useState("all");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [roleId, setRoleId] = useState("all");
  const [search, setSearch] = useState("");
  const [roles, setRoles] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [products, setProducts] = useState([]);
  const [productId, setProductId] = useState("all");
  const [isDisabled, setIsDisabled] = useState(false);

  const [deliveryDate, setDeliveryDate] = useState(null);
  const [deliveryDateOrderId, setDeliveryDateOrderId] = useState(null);

  const [address, setAddress] = useState("");
  const [editingAddress, setEditingAddress] = useState(0);
  const [addressErr, setAddressErr] = useState("");

  const [reload, setReload] = useState(!false);

  const navigate = useNavigate();

  useEffect(() => {
    let filters = {};
    if (categoryId && categoryId !== "all") {
      filters.cateory_id = categoryId;
    }
    if (roleId && roleId !== "all") {
      filters.user_role_id = roleId;
    }
    if (productId && productId !== "all") {
      filters.product_id = productId;
    }
    if (start && !isNaN(start) && end && !isNaN(end)) {
      filters.date_from = moment(start).format("DD.MM.YYYY");
      filters.date_to = moment(end).format("DD.MM.YYYY");
    } else if (start && !isNaN(start) && (!end || isNaN(end))) {
      filters.date_from = moment(start).format("DD.MM.YYYY");
      filters.date_to = moment(start).format("DD.MM.YYYY");
    } else if (end && !isNaN(end) && (!start || isNaN(start))) {
      filters.date_from = moment(end).format("DD.MM.YYYY");
      filters.date_to = moment(end).format("DD.MM.YYYY");
    }

    if (search) {
      filters.search = search;
    }

    const length = Object.keys(filters)?.length;

    if (length) {
      navigate(`${window.location.pathname}#${1}`);
      setCurrentPage(1);
    }

    const delayFunc = setTimeout(() => {
      !length
        ? axios
            .get(`${path}/api/orders?size=5&page=${currentPage}`)
            .then((res) => {
              setPageInfo(res.data.data.pagination);
              setOrders(res.data.data.orders);
              setIsEmpty(!res.data.data.orders.length ? true : false);
            })
        : axios
            .post(`${path}/api/orders/filter?size=10&page=${currentPage}`, {
              ...filters,
            })
            .then((res) => {
              setOrders(res.data.data.orders);
              setPageInfo(res.data.data.pagination);
              setIsEmpty(!res.data.data.orders.length ? true : false);
            });
    }, 500);
    return () => clearTimeout(delayFunc);
  }, [reload, currentPage, categoryId, roleId, productId, start, end, search]);

  useEffect(() => {
    axios
      .get(`${path}/api/users/get-roles`)
      .then((res) => setRoles(res.data.data.roles));
  }, []);

  useEffect(() => {
    axios
      .get(`${path}/api/orders/global-statuses`)
      .then((res) => setStatuses(res.data.data.orders));
  }, []);

  useEffect(() => {
    axios
      .get(`${path}/api/products/allProducts`)
      .then((res) => setProducts(res.data.data.products));
  }, []);

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    navigate(`/admin/orders#${page}`);
  };

  const handleStatusChange = (value, order) => {
    axios
      .post(`${path}/api/orders/${order.id}/change-status`, {
        status_id: value,
      })
      .then(() => {
        // setReload(!reload);
        window.location.reload();
      });
  };

  const handleDeliveryDateChange = (newDate, id) => {
    setDeliveryDateOrderId(id);
    setDeliveryDate(newDate);
  };

  const editDeliveryDate = (id) => {
    axios
      .put(`${path}/api/orders/edit/${id}`, {
        delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
      })
      .then(() => setReload(!reload));
  };

  const handleEditAddress = (id) => {
    axios
      .put(`${path}/api/orders/edit/${id}`, {
        address,
      })
      .then(() => {
        setEditingAddress(0);
        setAddressErr("");
        setReload(!reload);
      })
      .catch((err) => setAddressErr("Smth went wrong"));
  };

  return (
    <Orders>
      <Title>Orders</Title>
      <Filters>
        <FormControl>
          <InputLabel id="category">Category</InputLabel>
          <Select
            label="Category"
            labelId="category"
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
          >
            <MenuItem value="all">All</MenuItem>
            {categories.length ? (
              categories?.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))
            ) : (
              <EmptySelect>Nothing to show</EmptySelect>
            )}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="product">Product</InputLabel>
          <Select
            label="Product"
            labelId="category"
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
          >
            <MenuItem value="all">All</MenuItem>
            {products.length ? (
              products?.map(
                (product) =>
                  product.product_info && (
                    <MenuItem
                      key={product.product_info.product_id}
                      value={product.product_info.product_id}
                    >
                      {product.product_info.product_name}
                    </MenuItem>
                  )
              )
            ) : (
              <EmptySelect>Nothing to show</EmptySelect>
            )}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="role">User role</InputLabel>
          <Select
            label="User role"
            labelId="role"
            value={roleId}
            onChange={(e) => setRoleId(e.target.value)}
          >
            <MenuItem value="all">All</MenuItem>
            {roles.length ? (
              roles?.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))
            ) : (
              <EmptySelect>Nothing to show</EmptySelect>
            )}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="From "
            inputFormat="dd/MM/yyyy"
            value={start}
            onChange={(newDate) => {
              setStart(newDate);
            }}
            renderInput={(params) => (
              <TextField {...params} className="date-input" />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="To"
            inputFormat="dd/MM/yyyy"
            value={end}
            onChange={(newDate) => {
              setEnd(newDate);
            }}
            renderInput={(params) => (
              <TextField {...params} className="date-input" />
            )}
          />
        </LocalizationProvider>
        <Searchbar
          orders="orders"
          value={search}
          handleChange={(e) => setSearch(e.target.value)}
        />
      </Filters>
      {isEmpty ? (
        <EmptyPageText>Nothing to show</EmptyPageText>
      ) : (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="title">
                <TableCell>Costumer</TableCell>
                <TableCell>Payment type</TableCell>
                <TableCell>Paid status</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Discount </TableCell>
                <TableCell>Promo code</TableCell>
                <TableCell>Ordered Products</TableCell>
                {/* <TableCell>Total price</TableCell> */}
                <TableCell>Delivery date</TableCell>
                <TableCell>Delivery address</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders?.map((order) => (
                <TableRow key={order?.id}>
                  <TableCell className="name">
                    <Tooltip
                      title={
                        <TooltipContent>
                          <div>
                            <span>email:</span>{" "}
                            <span className="title">
                              {order?.user_id?.email}
                            </span>{" "}
                          </div>
                          <div>
                            <span>phone:</span>{" "}
                            <span className="title">
                              {order?.user_id?.phone}
                            </span>{" "}
                          </div>
                        </TooltipContent>
                      }
                    >
                      <p>{order.user_id?.name}</p>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <p>{order.payment_type === 'cash' ? "Cash" : order.payment_type === 'terminal' ? 'Idram' : 'Card'}</p>
                  </TableCell>
                  <TableCell>
                    <p>{order.paid_status ? "paid" : "unpaid"}</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      {!!order?.paid_at
                        ? moment(order.paid_at).format("DD/MM/YYYY")
                        : ""}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>{order.discount_amount}</p>
                  </TableCell>
                  <TableCell>
                    <p> {order?.promo_code_id || ""}</p>
                  </TableCell>
                  <TableCell>
                    {!!order?.order_info.length && (
                      <>
                        <TableRow>
                          <TableCell>Product name</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Count</TableCell>
                          <TableCell>Total price of product</TableCell>
                        </TableRow>
                        {order?.order_info?.map((item) => (
                          <TableRow key={item.id} align="right">
                            <TableCell>
                              <p>{item.product?.product_info?.product_name}</p>
                            </TableCell>
                            <TableCell>
                              <p>{item.price}</p>
                            </TableCell>
                            <TableCell>
                              <p>{item.count}</p>
                            </TableCell>
                            <TableCell>
                              <p>{item.total_price}</p>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableCell>
                  {/* <TableCell>
                    <p>{order?.order_info?.total_price}</p>
                  </TableCell> */}
                  <TableCell>
                    {order?.delivery_date ? (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date"
                          inputFormat="dd/MM/yyyy"
                          value={order?.delivery_date}
                          onChange={() => {}}
                          disabled
                          renderInput={(params) => (
                            <TextField {...params} className="date-input" />
                          )}
                        />
                      </LocalizationProvider>
                    ) : (
                      <>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Date"
                            inputFormat="dd/MM/yyyy"
                            value={
                              deliveryDateOrderId === order.id
                                ? deliveryDate
                                : null
                            }
                            onChange={(newDate) => {
                              handleDeliveryDateChange(newDate, order.id);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} className="date-input" />
                            )}
                          />
                        </LocalizationProvider>
                        {deliveryDateOrderId === order.id && (
                          <>
                            <Button
                              sx={{ color: "#24ae85" }}
                              onClick={() => editDeliveryDate(order.id)}
                            >
                              save date
                            </Button>
                            <Button
                              sx={{ color: "#24ae85" }}
                              onClick={() => setDeliveryDateOrderId(0)}
                            >
                              cancel
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <Address>
                      {editingAddress === order?.id ? (
                        <TextField
                          label="address"
                          error={addressErr ? true : false}
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                            setAddressErr("");
                          }}
                        />
                      ) : (
                        <TextField disabled value={order?.address} />
                      )}
                      <Btns>
                        {editingAddress !== order?.id ? (
                          <Button onClick={() => setEditingAddress(order.id)}>
                            edit
                          </Button>
                        ) : (
                          <>
                            <Button onClick={() => handleEditAddress(order.id)}>
                              save
                            </Button>
                            <Button
                              onClick={() => {
                                setEditingAddress(0);
                                setAddressErr("");
                                setAddress("");
                              }}
                            >
                              cancel
                            </Button>
                          </>
                        )}
                      </Btns>
                      {addressErr && <ErrMessage>{addressErr}</ErrMessage>}
                    </Address>
                  </TableCell>
                  <TableCell>
                    <FormControl sx={{ minWidth: "150px" }}>
                      <InputLabel id="status">Status</InputLabel>
                      <Select
                        disabled={isDisabled}
                        label="Status"
                        labelId="status"
                        value={order.global_status.id}
                        onChange={(e) => {
                          handleStatusChange(e.target.value, order);
                          if (e.target.value !== 1) {
                            setIsDisabled(true);
                          } else {
                            setIsDisabled(false);
                          }
                        }}
                      >
                        {statuses.length ? (
                          statuses?.map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                              {status.name}
                            </MenuItem>
                          ))
                        ) : (
                          <EmptySelect>Nothing to show</EmptySelect>
                        )}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {pageInfo?.pages_count > 1 && (
        <CustomPagination
          pageCount={pageInfo?.pages_count}
          currentPage={+currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </Orders>
  );
};

export default OrdersAdmin;
