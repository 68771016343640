import React from "react";
import Pagination from "@mui/material/Pagination";
import styled from "styled-components";

const AdminPagination = styled(Pagination)`
  & .MuiButtonBase-root {
    color: #232025;
    border: none;
  }
  align-self: flex-end;
  margin-bottom: 20px;
`;

const CustomPagination = ({ handlePageChange, pageCount, currentPage }) => {
  return (
    <AdminPagination
      variant="outlined"
      page={currentPage}
      count={pageCount}
      siblingCount={2}
      onChange={handlePageChange}
    />
  );
};

export default CustomPagination;
