import React, { useState, useEffect } from "react";
import { AboutWrapper } from "./styled";
import { PageWrapper, Section } from "../../shared/styled";
import Footer from "../footer/Footer";
import PATH from "../../constants/path";
import axios from "axios";

const About = () => {
  const [aboutSections, setAboutSections] = useState([]);

  useEffect(() => {
    axios.get(`${PATH}/api/about`).then((response) => {
      setAboutSections(response.data.data.about_sections);
    });
  }, []);

  return (
    <AboutWrapper>
      <PageWrapper>
        <Section className="section-head">
          <h2>ABOUT</h2>
        </Section>
        {!!aboutSections.length &&
          aboutSections.map((section) => {
            return (
              <Section key={section?.id} className="section">
                <div className="left-side">
                  <p
                    className={`upper ${
                      section?.small_description ? "small" : "big"
                    }`}
                  >
                    {section?.small_description
                      ? section?.small_description
                      : section?.big_description}
                  </p>
                </div>
                <div className="right-side">
                  <img src={`${PATH}/${section?.image}`} alt="about" />
                </div>
              </Section>
            );
          })}
        <Footer />
      </PageWrapper>
    </AboutWrapper>
  );
};

export default About;
