import React, { useState, useEffect } from "react";
import { ParallaxWrapper } from "./styled";

const Parallax = () => {
  const [toRightAmount, setToRightAmount] = useState(0);
  const [toLeftAmount, setToLeftAmount] = useState(0);

  useEffect(() => {
    var scrollableElement = document.body; //document.getElementById('scrollableElement');
    function checkScrollDirection(event) {
      if (checkScrollDirectionIsUp(event)) {
        setToRightAmount((prevState) => prevState - 20);
        setToLeftAmount((prevState) => prevState + 20);
      } else {
        setToRightAmount((prevState) => prevState + 20);
        setToLeftAmount((prevState) => prevState - 20);
      }
    }

    function checkScrollDirectionIsUp(event) {
      if (event.wheelDelta) {
        return event.wheelDelta > 0;
      }
      return event.deltaY < 0;
    }
    scrollableElement.addEventListener("wheel", checkScrollDirection);
  }, []);

  useEffect(() => {
    var touchPos;

    // store the touching position at the start of each touch
    document.body.ontouchstart = function (e) {
      touchPos = e.changedTouches[0].clientY;
    };

    // detect wether the "old" touchPos is
    // greater or smaller than the newTouchPos
    document.body.ontouchmove = function (e) {
      let newTouchPos = e.changedTouches[0].clientY;
      if (newTouchPos > touchPos) {
        setToRightAmount((prevState) => prevState + 20);
        setToLeftAmount((prevState) => prevState - 20);
      }
      if (newTouchPos < touchPos) {
        setToRightAmount((prevState) => prevState - 20);
        setToLeftAmount((prevState) => prevState + 20);
      }
    };
  }, []);

  return (
    <ParallaxWrapper toRight={toRightAmount} toLeft={toLeftAmount}>
      <div className="section-5 section-parallax">
        <h3>Products based with gradients:</h3>
        <div className="parallax-wrapper">
          <div className="parallax-row to-right">
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
          </div>
          <div className="parallax-row to-left">
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
          </div>
          <div className="parallax-row to-right">
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
          </div>
          <div className="parallax-row to-left">
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
            <p className="stroke">NATURAL</p>
            <p>NATURAL</p>
          </div>
        </div>
      </div>
    </ParallaxWrapper>
  );
};

export default Parallax;
