import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Form,
  FormWrapper,
  Link,
  TextFieldWrapper,
  FileInputWrapper,
} from "../../styled";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import path from "../../../../constants/path";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const EditPartner = () => {
  const { partner } = useParams();
  const { type } = useParams();
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");

  const [errors, setErrors] = useState({});

  const handleErrorRemove = (val) => {
    delete errors[val];
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    const data = {
      name,
      url,
      barber: type === "barber" ? 1 : 0,
    };

    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    image?.name && formData.append("image", image, image.name);

    axios
      .post(`${path}/api/partners/${partner}/edit`, formData)
      .then(() => navigate("/admin/partners"))
      .catch((err) => setErrors(err.response.data.message));
  };

  useEffect(() => {
    axios.get(`${path}/api/partners/show/${partner}`).then((res) => {
      const data = res.data.data["single-partner"][0];
      setImage(data.image);
      setName(data.name);
      setUrl(data.url);
    });
  }, []);

  return (
    <FormWrapper>
      <Link to="/admin/partners">Back to partners list</Link>
      <Form>
        <TextFieldWrapper>
          <TextField
            label="Partner name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              handleErrorRemove("name");
            }}
            error={errors.name ? true : false}
          />
          {errors.name && <div className="err">{errors.name[0]}</div>}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            label="Link to website"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              handleErrorRemove("url");
            }}
            error={errors.url ? true : false}
          />
          {errors.url && <div className="err">{errors.url[0]}</div>}
        </TextFieldWrapper>
        <FileInputWrapper>
          <input
            id="product-img"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => setImage(e.target.files[0])}
          />
          <label htmlFor="product-img">add image</label>
          {image && (
            <div className="img-wrapper">
              <img
                src={
                  image.name ? URL.createObjectURL(image) : `${path}/${image}`
                }
                alt=""
              />
              <Button type="button" onClick={() => setImage(null)}>
                <ClearIcon />
              </Button>
            </div>
          )}
        </FileInputWrapper>
      </Form>
      <Button variant="contained" className="add" onClick={handleSubmit}>
        save
      </Button>
    </FormWrapper>
  );
};

export default EditPartner;
