import styled from "styled-components";
import { Link } from "react-router-dom";

export const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const Btns = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ActionBtns = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  max-width: 140px;
  float: right;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: rgba(20, 110, 200, 0.03);
  & .expandBtn {
    border-radius: 50%;
    padding: 5px;
    min-width: 0;
  }
  & .expanded {
    transform: rotate(180deg);
  }
  & .MuiButtonBase-root {
    min-width: 0;
    padding: 5px;
  }
  & .MuiSvgIcon-root {
    color: #a8a8a8;
    &:hover {
      color: #24ae85;
    }
    &.leader {
      color: #24ae85;
    }
  }
`;

export const CustomLink = styled(Link)`
  color: #24ae85;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
`;

export const Products = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  & .MuiTable-root {
    min-width: 900px;
  }
  & .title {
    & .MuiTableCell-root {
      font-weight: 700;
    }
  }
  & .MuiTableRow-root {
    & .wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    & .table-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    & .title {
      font-weight: 700;
    }

    & .desc {
      width: 100%;
      word-break: break-word;
    }

    & .small-img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }

    & .images {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      & img {
        width: 150px;
        height: 150px;
        object-fit: contain;
      }
    }
  }
`;

export const LinkBtn = styled.button`
  width: max-content;
  color: #24ae85;
  background-color: #fff;
  border: none;
  outline: none;
  font-weight: 500;
  text-decoration: underline;
  &:before {
    content: "< ";
  }
  cursor: pointer;
`;
