import React, { useState } from "react";
import { Form, FormWrapper, Link, TextFieldWrapper } from "../../styled";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import NumberTextField from "../../shared-admin/NumberTextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import path from "../../../../constants/path";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const AddNewPromo = () => {
  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const handleSubmit = () => {
    const data = {
      code,
      discount_amount: +discount,
      valid_from: moment(start).format("DD.MM.YYYY"),
      valid_to: moment(end).format("DD.MM.YYYY"),
    };
    axios
      .post(`${path}/api/promo-codes/store`, data)
      .then(() => {
        navigate("/admin/promo-codes", { replace: true });
      })
      .catch((err) => setErrors(err.response.data.data));
  };

  const handleErrorRemove = (val) => {
    delete errors[val];
  };

  return (
    <FormWrapper>
      <Link to="/admin/promo-codes">Back to promo codes list</Link>
      <Form>
        <TextFieldWrapper>
          <TextField
            label="Code"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
              handleErrorRemove("code");
            }}
            error={errors.code ? true : false}
          />
          {errors.code && <div className="err">{errors.code[0]}</div>}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <NumberTextField
            customInput={TextField}
            label="Discount (%)"
            value={discount}
            onChange={(e) => {
              setDiscount(e.target.value);
              handleErrorRemove("discount_amount");
            }}
            error={errors.discount_amount ? true : false}
          />
          {errors.discount_amount && (
            <div className="err">{errors.discount_amount[0]}</div>
          )}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Valid from "
              inputFormat="MM/dd/yyyy"
              value={start}
              onChange={(newDate) => {
                setStart(newDate);
                handleErrorRemove("valid_from");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.valid_from ? true : false}
                />
              )}
            />
          </LocalizationProvider>
          {errors.valid_from && (
            <div className="err">{errors.valid_from[0]}</div>
          )}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Valid to"
              inputFormat="MM/dd/yyyy"
              value={end}
              onChange={(newDate) => {
                setEnd(newDate);
                handleErrorRemove("valid_to");
              }}
              renderInput={(params) => (
                <TextField {...params} error={errors.valid_to ? true : false} />
              )}
            />
          </LocalizationProvider>
          {errors.valid_to && <div className="err">{errors.valid_to[0]}</div>}
        </TextFieldWrapper>
      </Form>
      <Button variant="contained" className="add" onClick={handleSubmit}>
        save
      </Button>
    </FormWrapper>
  );
};

export default AddNewPromo;
