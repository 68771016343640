import styled, { keyframes } from "styled-components";

export const FooterContainer = styled.div`
  overflow-x: hidden;
  .section-barbershop {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    /* cursor: default; */
    margin-bottom: 120px;
    &:hover > .barbershop-container p {
      color: #bababa;
      filter: drop-shadow(0px 0px 0px #bababa);
    }

    &:hover > .barbershop-container {
      animation-play-state: paused;
      @media (hover: hover) {
        &:hover {
          animation-play-state: paused;
        }
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #bababa;
    }

    .barbershop-container {
      width: -webkit-fill-available;
      user-select: none;
      display: flex;
      justify-content: flex-start;
      position: relative;
      padding: 60px 0;
      animation-name: barbershop-running;
      animation-duration: 250s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
      @keyframes barbershop-running {
        from {
          left: 0%;
        }
        to {
          left: -800%;
        }
      }
      p {
        font-weight: 700;
        font-size: 64px;
        line-height: 50px;
        color: #232025;
        filter: drop-shadow(0px 0px 1px #bababa);
        transition: color 0.1s, filter 0.3s;
      }
      display: flex;
      align-items: center;
      column-gap: 60px;
    }
    @media (max-width: 768px) {
      margin-bottom: 60px;
      .barbershop-container {
        padding: 30px 0;
        p {
          font-size: 42px;
          line-height: 30px;
        }
        column-gap: 44px;
      }
    }
    @media (max-width: 480px) {
      margin-bottom: 0px;
      .barbershop-container {
        padding: 12px 0;
        p {
          font-size: 26px;
          line-height: 18px;
        }
        img {
          width: 32px;
        }
        column-gap: 22px;
      }
    }
  }
`;

const linkAnimation = keyframes`
0% {transform: scaleX(0);}
50% {transform: scaleX(1);}
100% {transform: scaleX(0);}
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 60px 60px;
  @media (max-width: 1024px) {
    padding: 60px 20px 60px;
  }
  h3,
  a,
  p {
    font-weight: 700;
    font-size: 48px;
    line-height: 59px;
    color: #bababa;
    @media (max-width: 1440px) {
      font-size: 36px;
    }
    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 26px;
    }
    @media (max-width: 1366px) {
      max-width: 80%;
    }
    @media (max-width: 480px) {
      font-size: 21px;
      line-height: 30px;
    }
  }

  h3 {
    margin-bottom: 20px;
  }
  a {
    color: #ffffff;
    margin-bottom: 120px;
    position: relative;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: -2px;
      background-color: #fff;
      transform: scaleX(0);
      transform-origin: right;
    }
    &.boldLink:after {
      height: 3px;
    }
    @media (hover: hover) {
      &:hover:after {
        transform-origin: left;
        animation-name: ${linkAnimation};
        animation-duration: 500ms;
        animation-timing-function: cubic-bezier(0.5, 0.01, 0, 0.5);
      }
    }
    @media (max-width: 768px) {
      margin-bottom: 60px;
      text-decoration: underline;
      &:after {
        display: none;
      }
    }
    @media (max-width: 480px) {
      margin-bottom: 60px;
      max-width: 90%;
      word-break: break-word;
      font-size: 13px;
    }
  }
  p {
    font-size: 16px;
    text-transform: uppercase;
    line-height: 20px;
    margin-bottom: 25px;
    @media (max-width: 768px) {
      max-width: 40%;
    }
    @media (max-width: 480px) {
      max-width: 90%;
      margin-bottom: 15px;
    }
  }
  .input-wrapper {
    position: relative;
    margin-bottom: 60px;
    @media (max-width: 480px) {
      margin-bottom: 30px;
      // input {
      //   border-bottom-color: #ffffff !important;
      // }
    }
    &.hovered {
      input {
        border-bottom-color: #ffffff;
      }
    }
    input {
      background-color: #232025;
      width: 100%;
      border-top: none;
      border-left: none;
      border-right: none;
      color: #bababa;
      @media (max-width: 480px) {
        padding: 15px 0;
        &::placeholder {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    .arrow {
      position: absolute;
      right: 0;
      top: 14px;
      cursor: pointer;
    }
    .response-message {
      font-size: 12px;
      margin-top: 10px;
    }
  }
  .lower-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #bababa;

    @media (max-width: 1280px) {
      flex-direction: column;
      flex-direction: column-reverse;
      align-items: flex-start;
      .right,
      .center {
        margin-bottom: 40px;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .left {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    .center {
      display: flex;
      align-items: center;
      column-gap: 25px;
      a {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 0;
        text-decoration: none;
        &:after {
          height: 2px;
        }
      }
      @media (max-width: 768px) {
        margin-bottom: 30px;
      }
      @media (max-width: 480px) {
        flex-direction: column;
        row-gap: 30px;
        width: 100%;
        align-items: flex-start;
        margin-bottom: 40px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      column-gap: 20px;
      a {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 0;
      }
      @media (max-width: 768px) {
        margin-bottom: 30px;
        line-height: 22px;
        text-decoration: underline;
      }
      @media (max-width: 480px) {
        flex-direction: column;
        row-gap: 30px;
        width: 100%;
        align-items: flex-start;
      }
    }
  }
`;
