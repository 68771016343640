import styled from "styled-components";

export const PartnersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 30px;
  & .subheader {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    &.barber {
      margin-top: 30px;
    }
  }
`;

export const Title = styled.div`
  font-size: 26px;
  font-weight: bold;
`;

export const Partners = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

export const Partner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  gap: 10px;
  width: 260px;
  position: relative;
  & .name {
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:before {
      content: "Name: ";
      font-size: 14px;
      font-weight: 500;
      color: #a8a8a8;
    }
  }
  & .link {
    font-size: 16px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:before {
      content: "Video: ";
      font-size: 14px;
      font-weight: 500;
      color: #a8a8a8;
    }
  }
  & img {
    width: 220px;
    height: 220px;
    object-fit: contain;
  }

  & a {
    color: #232025;
    text-decoration: underline;
  }
`;

export const ActionBtns = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  gap: 5px;
  & .MuiButton-root {
    padding: 2px;
    min-width: 0;
  }
  & .MuiSvgIcon-root {
    color: #a8a8a8;
    &:hover {
      color: #24ae85;
    }
  }
`;
