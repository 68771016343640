import styled from "styled-components";

const Main = styled.div`
  max-height: 100vh;
  overflow-y: scroll;
`;

const ContainedButton = styled.button`
  height: fit-content;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-weight: 400;
  padding: 14px 70px;
  width: 100%;
  min-width: 280px;
  color: #fff;
  background-color: #232025;
  border: 1px solid #fff;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    color: #232025;
    background-color: #fff;
  }
  @media (max-width: 768px) {
    color: #232025;
    background-color: #fff;
  }
`;

const Input = styled.input`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #232025;
  width: 280px;
  padding: 16.5px 10px;
  border: 1px solid #bababa;
  &:focus {
    outline: none;
    background-color: #bababa;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .products-divider {
    width: 92%;
    margin: 0 auto;
  }
`;

const Section = styled.div`
  // margin: 0 80px;
  max-width: 1920px;
  width: 92%;
  margin: 0 auto;
  &.products-grid-section,
  &.products-recommentaions-section {
    padding-top: 40px;
  }
  &.products-recommentaions-section {
    h3 {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: #bababa;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  &.single-product-section {
    display: flex;
  }
  @media (max-width: 768px) {
    &.products-recommentaions-section {
      margin-bottom: 50px;
    }
  }
  &.empty-shopping-cart {
    max-width: max-content;
    margin: unset;
    padding-left: 4%;
    &.checkout {
      padding-left: 0;
    }
    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #bababa;
      margin-bottom: 60px;
    }
    button {
      max-width: 272px;
      margin-bottom: 120px;
      &.checkout {
        margin-bottom: 0;
      }
    }
  }
  &.orders {
    & .pagination-container {
      padding: 0;
    }
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  padding: 60px 0 120px;
  .MuiPagination-ul {
    li {
      button {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 0;
        &.Mui-selected {
          background-color: #fff !important;
          color: #232025;
        }
      }
      div {
        border: 1px solid #ffffff;
        border-radius: 0;
        height: 32px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }
`;

export {
  ContainedButton,
  Input,
  PageWrapper,
  Section,
  Main,
  PaginationContainer,
};
