import React from "react";
import Pagination from "@mui/material/Pagination";
import { PaginationContainer } from "./styled";

const CustomPagination = ({ handlePageChange, pageCount, page }) => {
  return (
    <PaginationContainer className={`pagination-container`}>
      <Pagination
        page={page}
        count={pageCount}
        defaultPage={1}
        siblingCount={2}
        // boundaryCount={1}
        shape="rounded"
        onChange={handlePageChange}
        hidePrevButton={true}
        hideNextButton={true}
      />
    </PaginationContainer>
  );
};

export default CustomPagination;
