import React, { useEffect, useState } from "react";
import styled from "styled-components";
import path from "../../constants/path";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #232025;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 30px;
  color: #fff;
  font-size: 18px;
`;

const UserVerification = () => {
  const navigate = useNavigate();
  const [err, setErr] = useState("");
  const [sent, setSent] = useState(false);

  useEffect(() => {
    const pathname = window.location.pathname;
    const id = pathname.slice(pathname.lastIndexOf("/") + 1);

    !sent &&
      axios
        .get(`${path}/api/verify/${id}`)
        .then((res) => {
          localStorage.setItem("token", res.data.data.access_token);
          setSent(true);
          navigate("/");
          window.location.reload();
        })
        .catch((err) => setErr(err.response.data.message));
  });

  return <Background>{err}</Background>;
};

export default UserVerification;
