import styled from "styled-components";

export const ForgotPassBtn = styled.button`
  border: none;
  background-color: inherit;
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
`;
