import styled from "styled-components";

export const BlogWrapper = styled.div`
  .lower-divider {
    margin-bottom: 120px;
  }
  .section-head {
    padding: 120px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-side {
      h2 {
        font-weight: 700;
        font-size: 64px;
        line-height: 78px;
        color: #bababa;
        margin-right: 160px;
      }
    }
    .right-side {
      p {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-align: right;
        color: #bababa;
      }
    }
    @media (max-width: 480px) {
      br {
        display: none;
      }
    }
  }
  @media (max-width: 880px) {
    .lower-divider {
      margin-bottom: 60px;
    }
    .section-head {
      padding: 60px 0;
      .left-side {
        h2 {
          font-size: 48px;
          line-height: 58px;
          color: #bababa;
          margin-right: 80px;
        }
      }
      .right-side {
        p {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
  @media (max-width: 480px) {
    .section-head {
      padding: 60px 0 30px;
      flex-direction: column;
      align-items: flex-start;
      .left-side {
        h2 {
          font-size: 36px;
          line-height: 44px;
          margin-bottom: 30px;
        }
      }
      .right-side {
        p {
          font-size: 18px;
          line-height: 22px;
          text-align: left;
        }
      }
    }
  }
  .single-blog-section {
    margin-bottom: 120px;
    .img-container {
      min-width: 280px;
      min-height: 620px;
      margin-bottom: 60px;
      @media (max-width: 768px) {
        min-height: 360px;
        margin-bottom: 30px;
      }
      @media (max-width: 520px) {
        min-height: 196px;
      }
    }
    .title-date-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 60px;
      h3 {
        font-weight: 700;
        font-size: 48px;
        line-height: 59px;
        text-transform: uppercase;
        color: #ffffff;
      }
      .date {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #bababa;
      }
    }
    p {
      font-weight: 700;
      font-size: 48px;
      line-height: 59px;
      color: #bababa;
      margin-bottom: 30px;
    }
    @media (max-width: 1440px) {
      p {
        font-size: 36px;
        line-height: 44px;
      }
    }
    @media (max-width: 880px) {
      margin-bottom: 60px;
      img {
        margin-bottom: 30px;
      }
      .title-date-container {
        margin-bottom: 30px;
        h3 {
          font-size: 24px;
          line-height: 29px;
          margin-right: 50px;
        }
      }
      p {
        font-size: 22px;
        line-height: 26px;
      }
    }
    @media (max-width: 480px) {
      margin-bottom: 30px;
      .title-date-container {
        h3 {
          font-size: 18px;
          line-height: 22px;
        }
        .date {
          display: none;
        }
      }
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bababa;
`;
