import styled from "styled-components";
import { Link } from "react-router-dom";

export const Dialog = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  border: 1px solid #bababa;
  max-width: 320px;
  width: 100%;
  background-color: #232025;
  @media (max-width: 568px) {
    max-width: 100%;
    top: 0;
    right: 0;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #232025;
  opacity: 50%;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  & img {
    width: 12px;
    height: 12px;
  }
`;
export const ItemWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 30px 20px;
  & img {
    width: 70px;
    height: 70px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #bababa;
  & .name {
    font-weight: 700;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 20px;
  width: 100%;
  align-items: center;
`;

export const BtnLink = styled(Link)`
  border: none;
  outline: none;
  background-color: inherit;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  padding: 15px;
  border: 1px solid #ffffff;
  width: 100%;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    background-color: #fff;
    color: #232025;
    transition: background-color 100ms;
  }
  @media (max-width: 768px) {
    background-color: #fff;
    color: #232025;
  }
`;

export const CustomLink = styled(Link)`
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bababa;
`;
