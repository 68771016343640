import styled from "styled-components";

export const HomeWrapper = styled.div`
  img {
    object-fit: contain;
  }
  overflow-x: hidden;
  .divider {
    width: 100%;
    height: 1px;
    background-color: #bababa;
  }
  .section-1 {
    user-select: none;
    padding-bottom: 120px;
    @media (max-width: 966px) {
      padding-bottom: 60px;
    }
    .upper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin: 120px 0 60px;
      .moruq {
        font-weight: 700;
        font-size: 180px;
        line-height: 219px;
        letter-spacing: 0.3em;
        color: #bababa;
        @media (max-width: 1440px) {
          font-size: 160px;
        }
        @media (max-width: 1280px) {
          font-size: 120px;
        }
        @media (max-width: 966px) {
          font-size: 48px;
          line-height: 59px;
        }
      }
      .upper-right {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-end;
        p {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;

          color: #bababa;
        }
      }
    }
    .lower {
      display: flex;
      justify-content: space-between;
      float: right;
      max-width: 1370px;
      width: 100%;
      @media (max-width: 1440px) {
        max-width: 1040px;
      }
      h2 {
        margin-right: 25px;
        max-width: 578px;
        min-width: 578px;
        font-weight: 700;
        font-size: 54px;
        line-height: 80px;
        letter-spacing: 0.05em;
        text-align: right;
        color: #232025;
        //TODO -webkit-text-stroke: 1px #bababa;
        filter: drop-shadow(0px 0px 1px #bababa);
        .nature {
          color: #bababa;
          filter: none;
        }
        @media (max-width: 966px) {
          font-size: 24px;
          text-align: left;
          max-width: 280px;
          min-width: 280px;
          line-height: 30px;
        }
      }
    }

    @media (max-width: 644px) {
      padding-bottom: 64px;
      .upper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin: 60px 0 30px;
        .moruq {
          font-weight: 700;
          font-size: 48px;
          line-height: 58px;
          letter-spacing: 0.3em;
          color: #bababa;
          margin-bottom: 30px;
        }
        .upper-right {
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: flex-start;
          p {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #bababa;
          }
        }
      }
      .lower {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        float: right;
        max-width: 1370px;
        width: 100%;
        h2 {
          max-width: 290px;
          min-width: 290px;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          .nature {
            color: #bababa;
            filter: none;
          }
          margin-bottom: 25px;
        }
        a {
          margin: 0 auto;
          button {
            color: #232025;
            background-color: #fff;
          }
        }
      }
    }
  }
  .section-3 {
    padding: 94px 0 120px;
    @media (max-width: 768px) {
      padding: 22px 0 60px;
    }

    p {
      color: #bababa;
      font-weight: 700;
      font-size: 54px;
      line-height: 70px;
      letter-spacing: 0.05em;
      white-space: pre-line;
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 29px;
        span {
          display: block;
          margin-bottom: 20px;
        }
      }
    }
    span,
    a {
      color: #bababa;
      font-size: 14px;
      line-height: 17px;
      margin-right: 80px;

      cursor: default;

      &:focus {
        color: #bababa;
      }
      &:visited {
        color: #bababa;
      }
      &:hover {
        color: #ffffff;
      }
    }
    a {
      text-decoration: underline;
      margin-left: 20px;
    }
    @media (max-width: 320px) {
      padding: 25px 0 60px;
      p {
        color: #bababa;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.05em;
        a {
          display: block;
          margin: 20px 0 0 0;
        }
      }
      span,
      a {
        font-size: 14px;
        line-height: 17px;
        margin-right: 15px;
      }
      a {
        text-decoration: underline;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }

  .section-4,
  .section-5,
  .section-6,
  .section-7 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;
    @media (max-width: 768px) {
      margin-bottom: 60px;
    }
    @media (max-width: 480px) {
      a {
        button {
          margin: 0 auto;
          color: #232025;
          background-color: #fff;
        }
      }
    }
    h3 {
      font-size: 36px;
      line-height: 50px;
      color: #bababa;
      margin-bottom: 60px;
      text-align: center;
      @media (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 30px;
      }
      @media (max-width: 480px) {
        margin-bottom: 30px;
      }
    }
  }

  .section-6 {
    span {
      width: 100%;
    }
    h3 {
      max-width: 65%;
      @media (max-width: 1366px) {
        max-width: 90%;
      }
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        max-width: 90%;
      }
    }
  }
  .section-7 {
    overflow: hidden;
    // !SLIDER!!!!!!!!!!!!!!
    .slider {
      margin: auto;
      position: relative;
      width: 90%;
      display: grid;
      place-items: center;

      .partners-grid {
        width: calc(339px * 10);
        display: flex;
        column-gap: 30px;
        animation: scroll 60s linear infinite;
        @media (max-width: 1440px) {
          column-gap: 22px;
          width: calc(242px * 10);
        }
        .partner-container {
          width: 328px;
          aspect-ratio: 1;
          border: 1px solid #bababa;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: #fff;
          @media (max-width: 1440px) {
            width: 238px;
          }
          & img {
            max-width: 200px;
          }
        }
        @media (hover: hover) {
          &:hover {
            animation-play-state: paused;
          }
        }
      }
      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-339px * 5));
        }
      }
      @media (max-width: 1440px) {
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(calc(-242px * 5));
          }
        }
      }
    }

    @media (max-width: 768px) {
      .partners-grid {
        column-gap: 20px;
        .partner-container {
          width: 180px;
          border: 1px solid #bababa;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    @media (max-width: 320px) {
      .partners-grid {
        width: unset;
      }
    }
  }
  .see-products {
    height: fit-content;
    @media (max-width: 640px) {
      width: 100%;
      button {
        background-color: #fff;
        color: #232025;
      }
    }
  }
`;

export const ParallaxWrapper = styled.div`
  .section-5 {
    @media (max-width: 320px) {
      margin-bottom: 60px;
    }
    overflow: hidden;
    .parallax-wrapper {
      width: -webkit-fill-available;
      .parallax-row {
        display: flex;
        column-gap: 20px;
        row-gap: 30px;
        justify-content: center;
        width: 100%;
        transition: transform 1s;
        transition-timing-function: ease-in-out;
        user-select: none;
        &.to-right {
          transform: translateX(${(prop) => prop.toRight}px);
        }
        &.to-left {
          transform: translateX(${(prop) => prop.toLeft}px);
        }
        p {
          font-family: ArialAMU;
          font-weight: 700;
          font-size: 144px;
          line-height: 166px;
          color: #bababa;
          @media (max-width: 1440px) {
            font-size: 128px;
          }
          @media (max-width: 768px) {
            font-size: 58px;
            line-height: 76px;
          }
          @media (max-width: 320px) {
            font-size: 30px;
            line-height: 45px;
          }
          &.stroke {
            color: #232025;
            filter: drop-shadow(0px 0px 1px #bababa);
          }
        }
      }
    }
  }
`;
