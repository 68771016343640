import React, { useState, useEffect } from "react";
import {
  UserField,
  UserInfo,
  CustomDivider,
  CustomButton,
  UserFieldLinks,
  UserFieldLink,
} from "./responsive.styled";
import expandIcon from "../../assets/expandIcon.svg";
import filledArrow from "../../assets/whiteArrow.svg";
import outlinedArrow from "../../assets/outlinedRightArrow.svg";
import axios from "axios";
import path from "../../constants/path";
import { useNavigate } from "react-router-dom";

const LoggedInMobile = ({ closeMenu, handleReload }) => {
  const [expanded, setExpanded] = useState(false);
  const [user, setUser] = useState({});

  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${path}/api/get-user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUser(res.data.data.user);
        localStorage.setItem(
          "usersName",
          JSON.stringify(res.data.data.user?.name)
        );
      });
  }, []);

  const handleLogOut = () => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${path}/api/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        localStorage.clear();
        navigate("/", { replace: true })
        window.location.reload();
      });
  };

  return (
    <>
      <UserInfo onClick={() => setExpanded(!expanded)} expanded={expanded}>
        <div>{user?.name}</div>
        <img src={expandIcon} alt="" />
      </UserInfo>
      <UserField className={expanded && "show"}>
        <UserFieldLinks>
          <UserFieldLink to="/account/settings" onClick={closeMenu}>
            <div>settings</div>
            <img className="filled" src={filledArrow} alt="->" />
            <img className="outlined" src={outlinedArrow} alt="->" />
          </UserFieldLink>
          <UserFieldLink to="/account/orders" onClick={closeMenu}>
            <div>orders history</div>
            <img className="filled" src={filledArrow} alt="->" />
            <img className="outlined" src={outlinedArrow} alt="->" />
          </UserFieldLink>
        </UserFieldLinks>
        <CustomButton onClick={handleLogOut}>log out</CustomButton>
        <CustomDivider />
      </UserField>
    </>
  );
};

export default LoggedInMobile;
