import React, { useState } from "react";
import {
  Background,
  Dialog,
  Header,
  IconButton,
  Divider,
  Form,
  InputWrapper,
  Input,
  Button,
  ButtonWrapper,
  ErrorField,
  SuccessField,
} from "../../shared/dialog/styled";
import closeIcon from "../../assets/closeIcon.svg";
import axios from "axios";
import path from "../../constants/path";

const ForgetPassDialog = ({ open, handleClose }) => {
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    email
      ? axios
          .post(`${path}/api/users/reset-password`, { email })
          .then((res) => {
            setSuccess(res.data.message);
            setErr("");
          })
          .catch((err) => {
            setErr(err.response.data.message);
            setSuccess("");
          })
      : setErr("Email field is required");
  };

  return (
    <>
      <Background onClick={handleClose} className={open && "show"} />
      <Dialog
        forgetPassword={true}
        className={`forget-password ${open && "show"} ${
          (success || err) && "large-f-pass"
        }`}
      >
        <Header>
          <div>Let's get you into your account</div>
          <IconButton onClick={handleClose}>
            <img src={closeIcon} alt="close" />
          </IconButton>
        </Header>
        <Divider className="forget-password" />
        <Form onSubmit={handleSubmit}>
          <InputWrapper>
            <label htmlFor="email-forget-check">Type your email</label>
            <Input
              id="email-forget-check"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputWrapper>
          {success && <SuccessField>{success}</SuccessField>}
          {err && <ErrorField>{err}</ErrorField>}
          <Divider className="forget-password" />
          <ButtonWrapper>
            <Button>send</Button>
          </ButtonWrapper>
        </Form>
      </Dialog>
    </>
  );
};

export default ForgetPassDialog;
