import styled from "styled-components";

export const AboutWrapper = styled.div`
  .section-head {
    padding: 120px 0;
    h2 {
      font-weight: 700;
      font-size: 100px;
      line-height: 115px;
      color: #bababa;
    }
  }
  .section {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 120px;
    column-gap: 60px;
    img {
      width: 100%;
      @media (min-width: 768px) and (max-width: 1440px) {
        min-width: 360px;
      }
    }
    &:nth-of-type(2n) {
      flex-direction: row;
    }
    & .left-side {
      display: flex;
      flex-direction: column;
      max-width: 900px;
      @media (max-width: 1230px) {
        max-width: unset;
      }
      p {
        &.small {
          font-weight: 700;
          font-size: 32px;
          line-height: 60px;
          letter-spacing: 0.05em;
          color: #bababa;
        }
        &.big {
          font-weight: 700;
          font-size: 72px;
          line-height: 110px;
          text-align: right;
          color: #bababa;
          max-width: 800px;
        }
        @media (min-width: 768px) and (max-width: 1440px) {
          &.small {
            font-size: 24px;
          }
          &.big {
            font-size: 64px;
            max-width: 700px;
          }
        }
        @media (max-width: 768px) {
          &.small {
            font-weight: 700;
            font-size: 20px;
            line-height: 50px;
          }
          &.big {
            font-weight: 700;
            font-size: 48px;
            line-height: 80px;
          }
        }
        @media (min-width: 320px) and (max-width: 768px) {
          &.small {
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
          }
          &.big {
            font-weight: 700;
            font-size: 32px;
            line-height: 60px;
            max-width: 350px;
          }
        }
      }
    }
  }
  @media (max-width: 1230px) {
    .section-head {
      h2 {
        font-weight: 700;
        font-size: 48px;
        line-height: 55px;
      }
    }
    .section {
      flex-direction: column;
      margin-bottom: 60px;
      align-items: flex-start;
      &:nth-of-type(2n) {
        flex-direction: column;
      }
      p {
        margin-bottom: 30px;
        white-space: pre-line;
        text-align: left !important;
        .small {
          font-size: 24px;
          line-height: 50px;
          letter-spacing: 0.05em;
        }
        .big {
          font-size: 48px;
          line-height: 80px;
        }
      }
      .right-side {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 1020px) {
    .section-head {
      padding: 60px 0;
      h2 {
        font-size: 48px;
        line-height: 55px;
      }
    }
  }
  @media (max-width: 480px) {
    .section-head {
      h2 {
        font-size: 36px;
        line-height: 41px;
      }
    }
    .section {
      p {
        .big {
          font-weight: 700;
          font-size: 32px;
          line-height: 60px;
        }
        .small {
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          letter-spacing: 0.05em;
        }
      }
    }
  }
`;
