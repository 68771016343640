import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormWrapper,
  Link,
  TextFieldWrapper,
  FileInputWrapper,
} from "../../styled";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import path from "../../../../constants/path";
import axios from "axios";
import { useParams } from "react-router-dom";

const EditPost = () => {
  const { post } = useParams();
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [image, setImage] = useState("");
  const [link, setLink] = useState("");
  const [defImage, setDefImage] = useState("");

  useEffect(() => {
    axios.get(`${path}/api/blogs/${post}`).then((res) => {
      const data = res.data.data["single-post"][0];
      setId(data?.id);
      setTitle(data?.blog_info?.title);
      setDesc(data?.blog_info?.description);
      setLink(data?.video_link);
      setImage(data?.image);
      setDefImage(data?.image);
    });
  }, []);

  const [errors, setErrors] = useState({});

  const handleErrorRemove = (val) => {
    delete errors[val];
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    const data = {
      title,
      description: desc,
      video_link: link,
    };

    const formData = new FormData();

    image.name && formData.append("image", image, image.name);

    for (const key in data) {
      formData.append(key, data[key]);
    }

    formData.append("_method", "PUT");

    axios
      .post(`${path}/api/blogs/edit/${id}`, formData)
      .then(() => navigate("/admin/blog", { replace: true }))
      .catch((err) => setErrors(err.response.data));
  };

  return (
    <FormWrapper>
      <Link to="/admin/blog">Back to posts</Link>
      <Form>
        <TextFieldWrapper>
          <TextField
            label="Post title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              handleErrorRemove("title");
            }}
            error={errors.title ? true : false}
          />
          {errors.name && <div className="err">{errors.title[0]}</div>}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            label="Description"
            value={desc}
            onChange={(e) => {
              setDesc(e.target.value);
              handleErrorRemove("description");
            }}
            error={errors.description ? true : false}
          />
          {errors.name && <div className="err">{errors.description[0]}</div>}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            label="Youtube link"
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
              handleErrorRemove("video_link");
            }}
            error={errors.link ? true : false}
          />
          {errors.link && <div className="err">{errors.video_link[0]}</div>}
        </TextFieldWrapper>
        <FileInputWrapper>
          <input
            id="product-img"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => {
              setImage(e.target.files[0]);
            }}
          />
          <label htmlFor="product-img">change image</label>
          {image && (
            <div className="img-wrapper">
              <img
                src={
                  image.name ? URL.createObjectURL(image) : `${path}/${image}`
                }
                alt=""
              />
              {image !== defImage && (
                <Button
                  onClick={() => {
                    setImage(defImage);
                  }}
                >
                  cancel
                </Button>
              )}
            </div>
          )}
        </FileInputWrapper>
      </Form>
      <Button variant="contained" className="add" onClick={handleSubmit}>
        save
      </Button>
    </FormWrapper>
  );
};

export default EditPost;
