import styled from "styled-components";

const ProductCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  grid-gap: 4%;
  margin-bottom: 60px;
  width: 100%;
  align-items:start;
  > a {
    display: grid;
    &::before {
      // for apsect ratio
      content: "";
      display: block;
      // padding-bottom: 100%;
      grid-area: 1 / 1 / 2 / 2;
    }
    img {
      width: 100%;
      // margin-bottom: 20px;
      // height: 100%;
      // object-fit: contain;
      grid-area: 1 / 1 / 2 / 2;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    grid-gap: 30px;
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    grid-gap: 30px;
  }
`;

const ProductCardWrapper = styled.div`
  cursor: pointer;
`;

const ProductImg = styled.img``;

const ProductName = styled.h4`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 17px;
  }
`;
const ProductPrice = styled.h4`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 17px;
  }
`;
const ProductQuantity = styled.h4`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  display: none;
  margin-top: 10px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export {
  ProductCardGrid,
  ProductCardWrapper,
  ProductImg,
  ProductName,
  ProductPrice,
  ProductQuantity,
};
