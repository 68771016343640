import React, { useState, useEffect } from "react";
import path from "../../../../constants/path";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import {
  BtnLink,
  Container,
  EmptyPageText,
  Title,
  InfoField,
  TextFieldWrapper,
} from "../../styled";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../shared-admin/CustomPagination";
import DeleteDialog from "../DeleteDialog";
import moment from "moment";
import TextField from "@mui/material/TextField";

const Promos = () => {
  const [subscriptionDiscount, setSubscriptionDiscount] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [err, setErr] = useState("");
  const [reloadDiscount, setReloadDiscount] = useState(false);

  const handleEdit = (value) => {
    !value
      ? setErr("subscribtion_discount")
      : axios
          .put(`${path}/api/information/edit/subscription_discount`, {
            value: +value,
          })
          .then(() => {
            setDisabled(true);
            setErr("");
            setReloadDiscount(!reloadDiscount);
          });
  };

  useEffect(() => {
    axios.get(`${path}/api/information/subscription_discount`).then((res) => {
      setSubscriptionDiscount(res.data.data.information?.value || 0);
    });
  }, [reloadDiscount]);

  const [promos, setPromos] = useState([]);
  const [pageInfo, setPageInfo] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    +window.location.hash.slice(1) || 1
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [reload, setReload] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleRemovePromo = () => {
    axios
      .delete(`${path}/api/promo-codes/delete/${deletingItem.id}`)
      .then(() => {
        setOpenDialog(false);
        setReload(!reload);
      });
  };

  useEffect(() => {
    axios
      .get(`${path}/api/promo-codes?size=10&page=${currentPage}`)
      .then((res) => {
        setPromos(res.data.data["promo-codes"]);
        !res.data.data["promo-codes"].length && setIsEmpty(true);
        setPageInfo(res.data.data.pagination);
      });
  }, [currentPage, reload]);

  const navigate = useNavigate();

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    navigate(`/admin/promo-codes#${page}`);
  };

  return (
    <>
      <Container>
        <Title>Subscription discount </Title>
        <InfoField>
          <TextFieldWrapper>
            <TextField
              className="disabled-bold"
              label="Subscription discount"
              value={subscriptionDiscount}
              onChange={(e) => {
                setSubscriptionDiscount(e.target.value);
              }}
              error={err === "subscribtion_discount" ? true : false}
              disabled={disabled}
            />
            {err === "subscription_discount" && (
              <div className="err">This is a required field</div>
            )}
          </TextFieldWrapper>
          {disabled ? (
            <Button onClick={() => setDisabled(false)}>edit</Button>
          ) : (
            <>
              <Button onClick={() => handleEdit(subscriptionDiscount)}>
                save
              </Button>
              <Button onClick={() => setDisabled(true)}>cancel</Button>
            </>
          )}
        </InfoField>
        <Title>Promocodes</Title>
        <BtnLink to="/admin/promo-codes/add-new">add promo code</BtnLink>
        {isEmpty ? (
          <EmptyPageText>Nothing to show</EmptyPageText>
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className="title">
                  <TableCell>Code</TableCell>
                  <TableCell>Discount (%)</TableCell>
                  <TableCell>Valid from</TableCell>
                  <TableCell>Valid to</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {promos.map((promo) => (
                  <TableRow
                    key={promo.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{promo.code}</TableCell>
                    <TableCell>{promo.discount_amount}</TableCell>
                    <TableCell>
                      {moment(promo.valid_from).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell
                      className={
                        new Date(promo.valid_to) < new Date() ? "expired" : ""
                      }
                    >
                      {moment(promo.valid_to).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      <Button
                        disabled={promo.deleted_at ? true : false}
                        onClick={() => {
                          setOpenDialog(true);
                          setDeletingItem(promo);
                        }}
                      >
                        {promo.deleted_at ? "deleted" : "delete"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {pageInfo?.pages_count > 1 && (
          <CustomPagination
            pageCount={pageInfo?.pages_count}
            currentPage={+currentPage}
            handlePageChange={handlePageChange}
          />
        )}
      </Container>
      <DeleteDialog
        open={openDialog}
        onClose={handleCloseDialog}
        handleClose={handleCloseDialog}
        handleRemove={handleRemovePromo}
        name={`${deletingItem?.code} promo code`}
      />
    </>
  );
};

export default Promos;
