import React from "react";
import {
  ProductCardWrapper,
  ProductImg,
  ProductName,
  ProductPrice,
  ProductQuantity,
} from "../product-card/styled";

const ProductCard = ({
  productName,
  productPrice,
  productImg,
  onClick,
  productQuantity,
  quantityStyle,
}) => {
  return (
    <ProductCardWrapper onClick={onClick}>
      <ProductImg src={productImg} alt="product" />
      <ProductName>{productName}</ProductName>
      <ProductPrice>{productPrice} AMD</ProductPrice>
      <ProductQuantity style={quantityStyle}>
        QUANTITY ({productQuantity})
      </ProductQuantity>
    </ProductCardWrapper>
  );
};

export default ProductCard;
