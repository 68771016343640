import React, { useState, useEffect } from "react";
import {
  AddInfoWrapper,
  InfoTypo,
  TelLink,
  CustomInput,
  InputWrapper,
  IconButton,
  Message,
} from "./RightContent.styled";
import path from "../../constants/path";
import axios from "axios";

const AddInfo = () => {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState("");
  const [phone, setPhone] = useState("");
  const [subscriptionDiscount, setSubscriptionDiscount] = useState("");

  useEffect(() => {
    axios.get(`${path}/api/information/phone`).then((res) => {
      setPhone(res.data.data.information?.value);
    });
    axios.get(`${path}/api/information/subscription_discount`).then((res) => {
      setSubscriptionDiscount(res.data.data.information?.value);
    });
  }, []);

  const handleSubscribe = () => {
    axios
      .post(`${path}/api/subscribe`, { email })
      .then((res) => setResponse(res.data.message))
      .catch((err) =>
        setResponse(
          err.response.data?.data?.email?.[0] || err.response.data.message
        )
      )
      .finally(() => {
        setTimeout(() => {
          setEmail("");
          setResponse("");
        }, 5000);
      });
  };

  return (
    <AddInfoWrapper>
      <InfoTypo>Call for more information or to place a quick order</InfoTypo>
      <TelLink href={`tel:${phone}`}>{phone}</TelLink>
      <InfoTypo>
        subscribe to our newsletter and get{" "}
        <span>{`${subscriptionDiscount || ""}% `} </span>discount
      </InfoTypo>
      <InputWrapper>
        <CustomInput
          placeholder="e-mail address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <IconButton onClick={handleSubscribe} />
        {response && <Message>{response}</Message>}
      </InputWrapper>
    </AddInfoWrapper>
  );
};

export default AddInfo;
