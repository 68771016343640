import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { ContainedButton } from "../../shared/styled";

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const baseFlex = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const customTypo = css`
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  text-transform: uppercase;
  color: #232025;
`;

export const MobileMenu = styled(ColumnFlex)`
  display: none;
  @media (max-width: 992px) {
    display: flex;
  }
`;

export const NavBar = styled(ColumnFlex)``;

export const CustomButton = styled(ContainedButton)`
  max-width: 100%;
`;

export const ProductsNameListWrapper = styled(ColumnFlex)`
  overflow: hidden;
  gap: 0;
  height: auto;
`;

export const ProductsNameExpandButton = styled.button`
  ${baseFlex}
  ${customTypo};
  border: none;
  background-color: inherit;
  cursor: pointer;
  & img {
    transform: ${({ expanded }) => !expanded && "rotate(180deg)"};
  }
`;

export const ProductsNameList = styled(ColumnFlex)`
  max-height: 0;
  overflow: hidden;
  margin-top: -30px;
  transition: max-height 500ms, margin-top 500ms;
  &.show {
    margin-top: 0;
    max-height: 100%;
    transition: max-height 500ms, margin-top 500ms;
  }
`;

export const ProductName = styled(NavLink)`
  ${baseFlex}
  ${customTypo}
  font-size: 16px;
  line-height: 20px;
  &.active {
    color: rgba(35, 32, 37, 0.5);
  }
  & .outlined {
    display: none;
  }
  &.active .outlined {
    display: inline-block;
  }
  &.active .filled {
    display: none;
  }
`;

export const CustomLink = styled(NavLink)`
  ${baseFlex}
  ${customTypo}
    &.active {
    color: rgba(35, 32, 37, 0.5);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const UserField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 0;
  margin-top: -30px;
  overflow: hidden;
  transition: height 500ms, margin-top 500ms;
  &.show {
    margin-top: 0;
    height: 170px;
    transition: height 500ms, margin-top 500ms;
  }
`;

export const UserInfo = styled.button`
  ${baseFlex}
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #232025;
  background-color: inherit;
  border: none;
  border-bottom: 1px solid rgba(35, 32, 37, 0.5);
  padding: 15px 0;
  cursor: pointer;
  & img {
    transform: ${({ expanded }) => !expanded && "rotate(180deg)"};
  }
`;

export const CustomDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(35, 32, 37, 0.5);
`;

export const UserFieldLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const UserFieldLink = styled(NavLink)`
  ${baseFlex}
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #232025;
  padding: 10px 20px;
  border: 1px solid #232025;
  & .outlined {
    display: none;
  }
  &.active {
    background-color: #bababa;
    border: 1px solid #bababa;
  }
  &.active .outlined {
    display: inline-block;
  }
  &.active .filled {
    display: none;
  }
`;
