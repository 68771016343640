import React from "react";
import { Card, Info, InfoTitle, InfoContent, IconBtn } from "./styled";
import removeIcon from "../../assets/removeIconSmallBlack.svg";
import path from "../../constants/path";
import axios from "axios";

const AddressCard = ({ address, reload, radio }) => {
  const phone = address?.phone;

  const phoneFormat = `${phone?.slice(0, 4)} ${phone?.slice(
    4,
    6
  )} ${phone?.slice(6, 8)} ${phone?.slice(8, 10)} ${phone.slice(10)}`;

  const handleDelete = () => {
    axios
      .delete(`${path}/api/users/address/${address.id}/delete`, {
        id: address.id,
      })
      .then(() => reload());
  };

  return (
    <Card className={radio && "radio"}>
      <Info>
        <InfoTitle>city</InfoTitle>
        <InfoContent>{address.city}</InfoContent>
      </Info>
      <Info>
        <InfoTitle>addresses</InfoTitle>
        <InfoContent>{address.address}</InfoContent>
      </Info>
      <Info>
        <InfoTitle>phone number</InfoTitle>
        <InfoContent>{phoneFormat}</InfoContent>
      </Info>
      {address?.additional_info && (
        <Info>
          <InfoTitle>additional info</InfoTitle>
          <InfoContent>{address.additional_info}</InfoContent>
        </Info>
      )}
      <IconBtn type="button" onClick={handleDelete}>
        <img src={removeIcon} alt="x" />
      </IconBtn>
    </Card>
  );
};

export default AddressCard;
