import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";

const linkAnimation = keyframes`
0% {transform: scaleX(0);}
50% {transform: scaleX(1);}
100% {transform: scaleX(0);}
`;

export const LeftMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  gap: 20px;
  @media (max-width: 992px) {
    display: none;
    min-height: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 992px) {
    gap: 30px;
  }
`;

export const ProductContainerDesctop = styled(Container)`
  gap: 30px;
  @media (max-width: 992px) {
    display: none;
  }
`;

export const CustomLink = styled(NavLink)`
  & div {
    position: relative;
    overflow: hidden;
    transition-delay: 0.475s;
    transform: translate3d(0, 0, 0);
    transition: transform 0.5s cubic-bezier(0.31, 0, 0.05, 0.93);
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.05em;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #232025;
    max-height: 29px;
  }
  @media (max-width: 992px) {
    font-size: 22px;
    line-height: 27px;
  }
  &.active div {
    opacity: 50%;
  }
  & .hov {
    transform-origin: 50% 50%;
    transform: translate3d(0, 0, 0) rotateX(-90deg);
  }
  @media (hover: hover) {
    &:hover .def {
      transform: translate3d(0, -50%, 0) rotateX(-90deg);
    }
    &:hover .hov {
      transform: translate3d(0, -100%, 0);
    }
  }
`;

export const ProductLink = styled(CustomLink)`
  & div {
    font-size: 48px;
    line-height: 59px;
    letter-spacing: 0.3em;
    max-height: 59px;
    @media (max-width: 1024px) {
      font-size: 40px;
    }
  }
`;

export const ExternalLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const ExternalLink = styled.a`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #232025;
  text-transform: uppercase;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -3px;
    background-color: #232025;
    transform: scaleX(0);
    transform-origin: right;
  }
  @media (hover: hover) {
    &:hover:after {
      transform-origin: left;
      animation-name: ${linkAnimation};
      animation-duration: 500ms;
      animation-timing-function: cubic-bezier(0.5, 0.01, 0, 0.5);
    }
  }
  @media (hover: none) {
    text-decoration: underline;
  }
  @media (max-width: 992px) {
    text-decoration: underline;
    &:after {
      display: none;
    }
  }
`;
