import React, { useState, useEffect } from "react";
import {
  ResetPage,
  ResetForm,
  ResetInput,
  ResetLabel,
  ResetField,
  ChangeButton,
  ErrorFieldReset,
  ResetHeader,
  ResetDivider,
  SuccessFieldReset,
} from "./styled";
import axios from "axios";
import path from "../../constants/path";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const pathname = window.location.pathname;
    const currId = sessionStorage.getItem("id");
    if (!currId) {
      const id = pathname.slice(pathname.lastIndexOf("/") + 1);
      sessionStorage.setItem("id", id);
      navigate("/resetpassword");
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const id = sessionStorage.getItem("id");
    axios
      .put(`${path}/api/users/change-password/${id}`, {
        change_password: password,
        change_password_confirmation: confirm,
      })
      .then((res) => {
        setErr("");
        setSuccess(res.data.message);
        localStorage.setItem("token", res.data.data.access_token);
        navigate("/");
        window.location.reload();
        sessionStorage.removeItem("id");
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setSuccess("");
      });
  };

  return (
    <ResetPage>
      <ResetForm onSubmit={handleSubmit}>
        <ResetHeader>Reset password</ResetHeader>
        <ResetDivider />
        <ResetField>
          <ResetLabel htmlFor="password">New password</ResetLabel>
          <ResetInput
            name="password"
            id="password"
            type="password"
            className={`password`}
            autoComplete="new-password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </ResetField>
        <ResetField>
          <ResetLabel htmlFor="confirm">Confirm new password</ResetLabel>
          <ResetInput
            name="confirm"
            id="confirm"
            type="password"
            className={`password`}
            autoComplete="new-password"
            value={confirm}
            onChange={(e) => {
              setConfirm(e.target.value);
            }}
          />
        </ResetField>
        {err && <ErrorFieldReset>{err}</ErrorFieldReset>}
        {success && <SuccessFieldReset>{success}</SuccessFieldReset>}
        <ResetDivider />
        <ChangeButton>confirm</ChangeButton>
      </ResetForm>
    </ResetPage>
  );
};

export default ResetPassword;
