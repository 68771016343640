import React, { useEffect, useState } from "react";
import { ContainedButton, PageWrapper, Section } from "../../shared/styled";
import { ProductCardGrid } from "../../shared/product-card/styled";
import ProductCard from "../../shared/product-card/ProductCard";
import { OrdersWrapper, Divider, Empty, Container } from "./styled";
import PATH from "../../constants/path";
import axios from "axios";
import CustomPagination from "../../shared/CustomPagination";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const [pagination, setPagination] = useState({});
  const [userOrders, setUserOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${PATH}/api/users/orders?size=4&page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUserOrders(response.data.data.orders);
        setPagination(response.data.data.pagination);
      });
  }, [currentPage]);

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
  };

  return (
    <OrdersWrapper className="orders-wrapper">
      <PageWrapper>
        <Divider />
        {!!userOrders.length ? (
          userOrders.map((order) => {
            return (
              <Section key={order?.id} className="section-order">
                <p className="order-number">
                  ORDER NUMBER: <span>{order?.order_number}</span>
                </p>
                <p className="order-status">
                  ORDER STATUS: <span>{order?.global_status?.name}</span>
                </p>
                <ProductCardGrid className="order-grid">
                  <a href={`/product/${order?.order_info[0]?.product?.slug}`}>
                    <ProductCard
                      productImg={`${PATH}/${order?.order_info[0].product.image[0].image}`}
                      productName={
                        order?.order_info[0]?.product?.product_info
                          ?.product_name
                      }
                      productPrice={`${order?.order_info[0]?.total_price}.00`}
                    />
                  </a>
                </ProductCardGrid>
                <div className="buttons-container">
                  <ContainedButton
                    onClick={() => navigate(`/order/${order?.id}`)}
                  >
                    SEE DETAILS
                  </ContainedButton>
                </div>
                <Divider />
              </Section>
            );
          })
        ) : (
          <Container>
            <Empty> You haven't ordered anything yet</Empty>
            <ContainedButton onClick={() => navigate("/products/all")}>
              see products
            </ContainedButton>
          </Container>
        )}
        <Section
          style={{ width: "100%" }}
          className="section-pagination orders"
        >
          {pagination.pages_count > 1 && (
            <CustomPagination
              page={+currentPage}
              pageCount={pagination.pages_count}
              handlePageChange={handlePageChange}
            />
          )}
        </Section>
      </PageWrapper>
    </OrdersWrapper>
  );
};

export default Orders;
