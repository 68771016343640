import React, { useState } from "react";
import {
  Form,
  FormWrapper,
  Link,
  TextFieldWrapper,
  FileInputWrapper,
} from "../../styled";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import path from "../../../../constants/path";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddNewPost = () => {
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [image, setImage] = useState(null);
  const [link, setLink] = useState("");

  const [errors, setErrors] = useState({});

  const handleErrorRemove = (val) => {
    delete errors[val];
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    const data = {
      title,
      description: desc,
      video_link: link,
    };

    const formData = new FormData();
    image && formData.append("image", image, image.name);

    for (const key in data) {
      formData.append(key, data[key]);
    }

    axios
      .post(`${path}/api/blogs/store`, formData)
      .then(() => navigate("/admin/blog", { replace: true }))
  };

  return (
    <FormWrapper>
      <Link to="/admin/blog">Back to posts</Link>
      <Form>
        <TextFieldWrapper>
          <TextField
            label="Post title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              handleErrorRemove("title");
            }}
            error={errors.title ? true : false}
          />
          {errors.title && <div className="err">{errors.title[0]}</div>}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            label="Description"
            value={desc}
            onChange={(e) => {
              setDesc(e.target.value);
              handleErrorRemove("description");
            }}
            error={errors.description ? true : false}
          />
          {errors.description && (
            <div className="err">{errors.description[0]}</div>
          )}
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            label="Youtube link"
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
              handleErrorRemove("video_link");
            }}
            error={errors.link ? true : false}
          />
          {errors.link && <div className="err">{errors.video_link[0]}</div>}
        </TextFieldWrapper>
        <FileInputWrapper>
          <input
            id="product-img"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => setImage(e.target.files[0])}
          />
          <label htmlFor="product-img">add image</label>
          {image && (
            <div className="img-wrapper">
              <img src={URL.createObjectURL(image)} alt="" />
              <Button onClick={() => setImage(null)}>
                <ClearIcon />
              </Button>
            </div>
          )}
        </FileInputWrapper>
      </Form>
      <Button variant="contained" className="add" onClick={handleSubmit}>
        save
      </Button>
    </FormWrapper>
  );
};

export default AddNewPost;
