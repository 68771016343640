import React, { useEffect, useState } from "react";
import {
  Container,
  ProductContainerDesctop,
  CustomLink,
  ProductLink,
  ExternalLinks,
  ExternalLink,
  LeftMenu,
} from "./LeftContent.styled";
import path from "../../constants/path";
import axios from "axios";

const LeftContent = ({ closeMenu }) => {
  const categories = JSON.parse(localStorage.getItem("categories"));

  const [fbLink, setFbLink] = useState("")
  const [igLink, setIgLink] = useState("")

  useEffect(() => {
    axios.get(`${path}/api/information/facebook_link`).then((res) => {
      setFbLink(res.data.data.information?.value);
    });
    axios.get(`${path}/api/information/instagram_link`).then((res) => {
      setIgLink(res.data.data.information?.value);
    });
  }, []);

  return (
    <LeftMenu>
      <ProductContainerDesctop>
        {categories?.map((category) => (
          <ProductLink
            key={category.id}
            to={`products/filter&id=${category.id}`}
            onClick={closeMenu}
          >
            <div>
              <div className="def">{category.name}</div>
              <div className="hov">{category.name}</div>
            </div>
          </ProductLink>
        ))}
        <ProductLink to="/barbershop" onClick={closeMenu}>
          <div>
            <div className="def">barbershop</div>
            <div className="hov">barbershop</div>
          </div>
        </ProductLink>
      </ProductContainerDesctop>
      <Container>
        <CustomLink to="/about" onClick={closeMenu}>
          <div>
            <div className="def">about</div>
            <div className="hov">about</div>
          </div>
        </CustomLink>
        <CustomLink to="/blog" onClick={closeMenu}>
          <div>
            <div className="def">blog</div>
            <div className="hov">blog</div>
          </div>
        </CustomLink>
        <CustomLink to="/contacts" onClick={closeMenu}>
          <div>
            <div className="def">contacts</div>
            <div className="hov">contacts</div>
          </div>
        </CustomLink>
      </Container>
      <ExternalLinks>
        {fbLink && <ExternalLink href={fbLink} target="_blank">
          facebook
        </ExternalLink>}
        {igLink && <ExternalLink href={igLink} target="_blank">
          instagram
        </ExternalLink>}
      </ExternalLinks>
    </LeftMenu>
  );
};

export default LeftContent;
