import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

export const SpaceBwItemsCtrDisplayFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderWrapper = styled(SpaceBwItemsCtrDisplayFlex)`
  width: 100%;
  padding: 0 60px;
  min-height: 80px;
  @media (max-width: 1024px), (max-height: 880px) {
    padding: 0 20px;
  }
  transform: scaleY(0);
  transition: transform 0.8s;
  transform-origin: top;
  &.show {
    transform: scaleY(1);
    transition: transform 1s;
    transform-origin: bottom;
  }
`;

export const MenuCartContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const TextButton = styled.button`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #bababa;
  background-color: inherit;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  @media (hover: hover) {
    &:hover {
      color: #fff;
    }
  }
  @media (max-width: 992px), (max-height: 880px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Background = styled.div`
  width: 0;
  height: 0;
  &.show {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    height: 100vh;
    background-color: #232025;
    opacity: 0.65;
  }
  @media (max-width: 768px), (max-height: 625px) {
    &.show {
      opacity: 1;
      transition: background-color;
      transition-delay: 1000ms;
    }
  }
`;

export const Cart = styled.div`
  overflow: hidden;
  position: absolute;
  z-index: 30;
  right: 80px;
  border: 1px solid #bababa;
  box-shadow: 0px 4px 30px rgba(255, 255, 255, 0.3);
  background-color: #232025;
  max-width: 320px;
  width: 100%;
  display: none;
  &.hide {
    height: 0;
  }
  &.show {
    display: block;
    z-index: 10;
    height: fit-content;
  }
  @media (max-width: 768px), (max-height: 625px) {
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    max-height: 100%;
    transform: translate3d(0, 0, 0);
    max-width: 100%;
    border: none;
    box-shadow: none;
    overflow: hidden;
    &.hide {
      height: 0;
      transition: all 1s cubic-bezier(0.85, 0.01, 0.4, 1);
    }
    &.show {
      height: 100%;
      transition: height 1s cubic-bezier(0.85, 0.01, 0.4, 1);
    }
  }
`;

export const Wrapper = styled.div`
  padding: 0 20px;
`;

export const CartHeader = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0;
`;

export const RemoveIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const CartTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #bababa;
`;

export const IconButton = styled.button`
  border: none;
  background-color: inherit;
  cursor: pointer;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bababa;
  @media (max-width: 768px) {
    width: calc(100% - 40px);
    margin-left: 20px;
  }
`;

export const Cards = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 300px;
  width: 100%;
  overflow: auto;
`;

export const EmptyCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
  & div {
    padding: 20px;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
  }
`;

export const Card = styled(SpaceBwItemsCtrDisplayFlex)`
  width: 100%;
  gap: 5px;
`;

export const CardContent = styled.div`
  display: flex;
  gap: 5px;
  img {
    max-width: 70px;
    cursor: pointer;
  }
`;

export const CardContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #bababa;
  text-transform: uppercase;
`;

export const CardTextBold = styled(CardText)`
  font-weight: 700;
  cursor: pointer;
`;

export const CalcButton = styled.button`
  border: 1px solid #fff;
  width: 24px;
  height: 24px;
  color: #fff;
  background-color: inherit;
  cursor: pointer;
`;

export const Count = styled(CardText)`
  color: #fff;
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
  border-width: 0 1px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const animation = keyframes`
0% {transform: scaleX(0);}
50% {transform: scaleX(1);}
100% {transform: scaleX(0);}
`;

export const RemoveButton = styled.button`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  background-color: inherit;
  border: none;
  width: fit-content;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.5px;
    left: 0;
    bottom: -2px;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: right;
  }
  @media (hover: hover) {
    &:hover:after {
      transform-origin: left;
      animation-name: ${animation};
      animation-duration: 350ms;
      animation-timing-function: cubic-bezier(0.5, 0.01, 0, 0.5);
    }
  }
  @media (hover: none) {
    text-decoration: underline;
  }
`;

export const TotalSumWrapper = styled(SpaceBwItemsCtrDisplayFlex)`
  padding: 14px 20px 0 20px;
  font-size: 16px !important;
  line-height: 20px !important;
`;

export const ButtonLink = styled(Link)`
  margin: 30px 20px;
  display: block;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-weight: 400;
  padding: 14px 0;
  color: #fff;
  background-color: #232025;
  border: 1px solid #fff;
  text-transform: uppercase;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      color: #232025;
      background-color: #fff;
    }
  }

  @media (max-width: 768px) {
    color: #232025;
    background-color: #fff;
  }
`;

export const CustomLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const CustomLink = styled(Link)`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 30px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -2px;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: right;
  }
  @media (hover: hover) {
    &:hover:after {
      transform-origin: left;
      animation-name: ${animation};
      animation-duration: 350ms;
      animation-timing-function: cubic-bezier(0.5, 0.01, 0, 0.5);
    }
  }
  @media (hover: none) {
    text-decoration: underline;
  }
`;

export const MainMenu = styled.div`
  background-color: #e5e5e5;
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  height: fit-content;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 1s cubic-bezier(0.85, 0.01, 0.4, 1);
  &.open {
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 1s cubic-bezier(0.85, 0.01, 0.4, 1);
  }
  padding: 0 80px;
  @media (max-width: 992px), (max-height: 880px) {
    padding: 0 20px;
  }
`;

export const MenuHeader = styled(SpaceBwItemsCtrDisplayFlex)`
  min-height: 80px;
`;

export const MenuContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 50px;
  width: 100%;
  height: 100%;
  padding: 30px 0;
  min-height: calc(100vh - 100px);
  @media (max-width: 992px), (max-height: 880px) {
    flex-direction: column;
  }
`;
