import React, { useState } from "react";
import {
  Background,
  Dialog,
  Header,
  IconButton,
  Divider,
  Form,
  InputWrapper,
  Input,
  Button,
  ButtonWrapper,
  ErrorField,
} from "../../shared/dialog/styled";
import closeIcon from "../../assets/closeIcon.svg";
import TextField from "@mui/material/TextField";
import { PhoneWrapper } from "../../components/account/styled";
import NumberInput from "../../shared/NumberInput";
import path from "../../constants/path";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import { ClickAwayListener } from "@mui/material";
import {
  Label,
  InputWrapper as SelectWrapper,
} from "../../components/checkout/styled";

const AddDeliveryAddress = ({ handleClose, show, reload }) => {
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [additional_info, setAdditional_info] = useState("");
  const [err, setErr] = useState("");
  const [errors, setErrors] = useState({});
  const [selectOpened, setSelectOpened] = useState(false);

  const handleErrorRemove = (val) => {
    delete errors[val];
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    axios
      .post(
        `${path}/api/users/address/add`,
        {
          phone: `+374${phone}`,
          address,
          additional_info,
          city,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        handleClose();
        setPhone("");
        setAddress("");
        setCity("");
        setAdditional_info("");
        reload && reload();
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setErrors(err.response.data.data);
      });
  };

  return (
    <>
      <Background onClick={handleClose} className={show && "show"} />
      <Dialog className={`${show ? "show" : "hide"} ${err && "large"}`}>
        <Header>
          <div>Add new delivery address</div>
          <IconButton onClick={handleClose}>
            <img src={closeIcon} alt="close" />
          </IconButton>
        </Header>
        <Divider />
        <Form onSubmit={handleSubmit}>
          <ClickAwayListener
            onClickAway={() => {
              setSelectOpened(false);
            }}
          >
            <SelectWrapper className="select-input modal">
              <Label htmlFor="city">City / Region</Label>

              <div
                className="select-box"
                id="city"
                onClick={() => {
                  setSelectOpened((prev) => !prev);
                }}
              >
                {city}
                {
                  <svg
                    style={{
                      transform: selectOpened
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7929 0.5L8.00004 7.29293L1.20711 0.5L14.7929 0.5Z"
                      stroke="white"
                    />
                  </svg>
                }
              </div>

              <div
                className="menu-items"
                style={{ display: selectOpened ? "block" : "none" }}
              >
                <MenuItem
                  onClick={(e) => {
                    setCity(e.target.innerText);
                    setSelectOpened(false);
                    setSelectOpened(false);
                  }}
                  style={{
                    backgroundColor: "#232025",
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                  }}
                  value="yerevan"
                >
                  Yerevan
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setCity(e.target.innerText);
                    setSelectOpened(false);
                  }}
                  style={{
                    backgroundColor: "#232025",
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                  }}
                  value="armavir"
                >
                  Armavir
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setCity(e.target.innerText);
                    setSelectOpened(false);
                  }}
                  style={{
                    backgroundColor: "#232025",
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                  }}
                  value="ararat"
                >
                  Ararat
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setCity(e.target.innerText);
                    setSelectOpened(false);
                  }}
                  style={{
                    backgroundColor: "#232025",
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                  }}
                  value="kotayk"
                >
                  Kotayk
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setCity(e.target.innerText);
                    setSelectOpened(false);
                  }}
                  style={{
                    backgroundColor: "#232025",
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                  }}
                  value="shirak"
                >
                  Shirak
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setCity(e.target.innerText);
                    setSelectOpened(false);
                  }}
                  style={{
                    backgroundColor: "#232025",
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                  }}
                  value="lori"
                >
                  Lori
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setCity(e.target.innerText);
                    setSelectOpened(false);
                  }}
                  style={{
                    backgroundColor: "#232025",
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                  }}
                  value="gegharkunik"
                >
                  Gegharkunik
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setCity(e.target.innerText);
                    setSelectOpened(false);
                  }}
                  style={{
                    backgroundColor: "#232025",
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                  }}
                  value="syunik"
                >
                  Syunik
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setCity(e.target.innerText);
                    setSelectOpened(false);
                  }}
                  style={{
                    backgroundColor: "#232025",
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                  }}
                  value="aragatsotn"
                >
                  Aragatsotn
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setCity(e.target.innerText);
                    setSelectOpened(false);
                  }}
                  style={{
                    backgroundColor: "#232025",
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                  }}
                  value="tavush"
                >
                  Tavush
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setCity(e.target.innerText);
                    setSelectOpened(false);
                  }}
                  style={{
                    backgroundColor: "#232025",
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                  }}
                  value="vayots-dzor"
                >
                  Vayots Dzor
                </MenuItem>
              </div>
            </SelectWrapper>
          </ClickAwayListener>
          <InputWrapper>
            <label htmlFor="address-add">Address</label>
            <TextField
              id="address-add"
              multiline
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
                handleErrorRemove("address");
              }}
              className={errors.address && "error"}
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="phone">Phone number</label>
            <PhoneWrapper>
              <Input
                id="phone"
                as={NumberInput}
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  handleErrorRemove("phone");
                }}
                className={`phone ${errors.phone && "error"}`}
              />
              <div className="suff">+374</div>
            </PhoneWrapper>
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="info">Additional info</label>
            <TextField
              id="info"
              multiline
              value={additional_info}
              onChange={(e) => {
                setAdditional_info(e.target.value);
                handleErrorRemove("additional_info");
              }}
              className={errors.additional_info && "error"}
            />
          </InputWrapper>
          {err && <ErrorField>{err}</ErrorField>}
          <Divider />
          <ButtonWrapper>
            <Button>save</Button>
          </ButtonWrapper>
        </Form>
      </Dialog>
    </>
  );
};

export default AddDeliveryAddress;
