import React, { useState, useEffect } from "react";
import path from "../../../../constants/path";
import axios from "axios";
import {
  Form,
  TextFieldWrapper,
  FileInputWrapper,
  Title,
  Container,
  InfoField,
  PageTitle,
} from "../../styled";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import NumberTextField from "../../shared-admin/NumberTextField";
import DeleteDialog from "../DeleteDialog";

const BarbershopAdmin = () => {
  const [text, setText] = useState("");
  const [image, setImage] = useState("");
  const [sliderImageOne, setSliderImageOne] = useState("")
  const [sliderImageTwo, setSliderImageTwo] = useState("")
  const [sliderImageThree, setSliderImageThree] = useState("")
  const [sliderImageFour, setSliderImageFour] = useState("")
  const [sliderImageFive, setSliderImageFive] = useState("")
  const [sliderImageOneDef, setSliderImageOneDef] = useState("")
  const [sliderImageTwoDef, setSliderImageTwoDef] = useState("")
  const [sliderImageThreeDef, setSliderImageThreeDef] = useState("")
  const [sliderImageFourDef, setSliderImageFourDef] = useState("")
  const [sliderImageFiveDef, setSliderImageFiveDef] = useState("")
  const [defImage, setDefImage] = useState("");

  const [prices, setPrices] = useState([]);

  const [editingService, setEditingService] = useState("");
  const [editingPrice, setEditingPrice] = useState("");
  const [editingId, setEditingId] = useState("");

  const [reload, setReload] = useState(false);
  const [reloadPrices, setReloadPrices] = useState(false);

  const [err, setErr] = useState("");
  const errMessage = "This is a required field";

  const [errors, setErrors] = useState({});

  const [editingItem, setEditingItem] = useState("");

  const [newValue, setNewValue] = useState("");
  const [newKey, setNewKey] = useState("");
  const [addNew, setAddNew] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);

  const handleErrorRemove = (val) => {
    delete errors[val];
  };

  useEffect(() => {
    axios.get(`${path}/api/price-list/all`).then((res) => {
      setPrices(res.data.data.price_list);
    });
  }, [reloadPrices]);

  const handleAddNewItem = () => {
    const data = {
      name: newKey.trim().toLowerCase().split(" ").join("_"),
      price: newValue,
    };
    axios
      .post(`${path}/api/price-list/store`, data)
      .then(() => {
        setReloadPrices(!reloadPrices);
        handleCancelAdd();
      })
      .catch((err) => setErrors(err.response.data.data));
  };

  useEffect(() => {

    axios.get(`${path}/api/information/homepage_text`).then((res) => {
      setText(res.data.data.information?.value);
    });
    axios
      .get(`${path}/api/information/barbershop_background_image`)
      .then((res) => {
        setImage(res.data.data.information?.image);
        setDefImage(res.data.data.information?.image);
      });
    axios
      .get(`${path}/api/information/barbershop_slider_1`)
      .then((res) => {
        setSliderImageOne(res.data.data.information?.image);
        setSliderImageOneDef(res.data.data.information?.image); 
      });
    axios
      .get(`${path}/api/information/barbershop_slider_2`)
      .then((res) => {
        setSliderImageTwo(res.data.data.information?.image);
        setSliderImageTwoDef(res.data.data.information?.image); 
      });
    axios
      .get(`${path}/api/information/barbershop_slider_3`)
      .then((res) => {
        setSliderImageThree(res.data.data.information?.image);
        setSliderImageThreeDef(res.data.data.information?.image); 
      });
    axios
      .get(`${path}/api/information/barbershop_slider_4`)
      .then((res) => {
        setSliderImageFour(res.data.data.information?.image);
        setSliderImageFourDef(res.data.data.information?.image); 
      });
    axios
      .get(`${path}/api/information/barbershop_slider_5`)
      .then((res) => {
        setSliderImageFive(res.data.data.information?.image);
        setSliderImageFiveDef(res.data.data.information?.image); 
      });
  }, [reload]);

  const handleEdit = (key, value) => {
    !value
      ? setErr(key)
      : axios
          .put(`${path}/api/information/edit/${key}`, {
            value,
          })
          .then(() => {
            setEditingItem("");
            setErr("");
          });
  };

  const handleCancel = () => {
    setEditingItem("");
    setErr("");
    setReload(!reload);
  };

  const handleCancelAdd = () => {
    setAddNew(false);
    setNewKey("");
    setNewValue("");
    handleErrorRemove("name");
    handleErrorRemove("price");
  };

  const handleEditImage = () => {
    const formData = new FormData();
    formData.append("image", image, image.name);

    formData.append("_method", "PUT");

    axios
      .post(
        `${path}/api/information/edit/barbershop_background_image`,
        formData
      )
      .then(() => {
        setEditingItem("");
        setErr("");
        setReload(!reload);
      });
  };

  const handleEditSliderImageOne = () => {
    const formData = new FormData();
    formData.append("image", sliderImageOne, sliderImageOne.name);

    formData.append("_method", "PUT");

    axios
      .post(
        `${path}/api/information/edit/barbershop_slider_1`,
        formData
      )
      .then((res) => {
        setEditingItem("");
        setErr("");
        setReload(!reload);
      });
  };
  const handleEditSliderImageTwo = () => {
    const formData = new FormData();
    formData.append("image", sliderImageTwo, sliderImageTwo.name);

    formData.append("_method", "PUT");

    axios
      .post(
        `${path}/api/information/edit/barbershop_slider_2`,
        formData
      )
      .then((res) => {
        setEditingItem("");
        setErr("");
        setReload(!reload);
      });
  };
  const handleEditSliderImageThree = () => {
    const formData = new FormData();
    formData.append("image", sliderImageThree, sliderImageThree.name);

    formData.append("_method", "PUT");

    axios
      .post(
        `${path}/api/information/edit/barbershop_slider_3`,
        formData
      )
      .then((res) => {
        setEditingItem("");
        setErr("");
        setReload(!reload);
      });
  };
  const handleEditSliderImageFour = () => {
    const formData = new FormData();
    formData.append("image", sliderImageFour, sliderImageFour.name);

    formData.append("_method", "PUT");

    axios
      .post(
        `${path}/api/information/edit/barbershop_slider_4`,
        formData
      )
      .then((res) => {
        setEditingItem("");
        setErr("");
        setReload(!reload);
      });
  };
  const handleEditSliderImageFive = () => {
    const formData = new FormData();
    formData.append("image", sliderImageFive, sliderImageFive.name);

    formData.append("_method", "PUT");

    axios
      .post(
        `${path}/api/information/edit/barbershop_slider_5`,
        formData
      )
      .then((res) => {
        setEditingItem("");
        setErr("");
        setReload(!reload);
      });
  };

  const handleCancelServiceEdit = () => {
    setEditingId("");
    setEditingPrice("");
    setEditingService("");
    setErrors({});
  };

  const handleSelectEditingItem = (id, name, price) => {
    setEditingId(id);
    setEditingService(name);
    setEditingPrice(price);
  };

  const handleEditService = (service) => {
    const data = {
      name: editingService.split(" ").join("_"),
      price: `${editingPrice}`,
    };

    axios
      .post(`${path}/api/price-list/edit/${editingId}`, data)
      .then(() => {
        setReloadPrices(!reloadPrices);
        handleCancelServiceEdit();
      })
      .catch((err) => setErrors(err.response.data.data));
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => {
    axios
      .delete(`${path}/api/price-list/delete/${deletingItem.id}`)
      .then(() => {
        setReloadPrices(!reloadPrices);
        setOpenDeleteDialog(false);
      })
  };

  return (
    <Container>
      <Title>Barbershop</Title>
      <Form>
        <InfoField>
          <TextFieldWrapper>
            <TextField
              className="disabled-bold"
              label="Text"
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
              error={err === "barbershop_text" ? true : false}
              disabled={editingItem === "barbershop_text" ? false : true}
              multiline
            />
            {err === "barbershop_text" && (
              <div className="err">{errMessage}</div>
            )}
          </TextFieldWrapper>
          {editingItem !== "barbershop_text" ? (
            <Button onClick={() => setEditingItem("barbershop_text")}>
              edit
            </Button>
          ) : (
            <>
              <Button onClick={() => handleEdit("barbershop_text", text)}>
                save
              </Button>
              <Button onClick={handleCancel}>cancel</Button>
            </>
          )}
        </InfoField>
        <FileInputWrapper>
          <input
            id="product-img"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => e.target.files[0] && setImage(e.target.files[0])}
            multiple
          />
          <label htmlFor="product-img">change cover image</label>

          <div key={image?.id || image?.name} className="img-wrapper-admin">
            {image && (
              <img
                src={
                  image.name ? URL.createObjectURL(image) : `${path}/${image}`
                }
                alt=""
              />
            )}
            {image && image !== defImage && (
              <div className="img-btns">
                <Button
                  className="save-btn"
                  variant="contained"
                  onClick={handleEditImage}
                >
                  save image
                </Button>
                <Button variant="outlined" onClick={() => setImage(defImage)}>
                  cancel
                </Button>
              </div>
            )}
          </div>
        </FileInputWrapper>
        {/* =================================================== */}
        <FileInputWrapper>
          <input
            id="slider-img-one"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => e.target.files[0] && setSliderImageOne(e.target.files[0])}
            multiple
          />
          <label htmlFor="slider-img-one">change slider image 1</label>

          <div key={sliderImageOne?.id || sliderImageOne?.name} className="img-wrapper-admin">
            {sliderImageOne && (
              <img
                src={
                  sliderImageOne.name ? URL.createObjectURL(sliderImageOne) : `${path}/${sliderImageOne}`
                }
                alt=""
              />
            )}
            {sliderImageOne && sliderImageOne !== sliderImageOneDef && (
              <div className="img-btns">
                <Button
                  className="save-btn"
                  variant="contained"
                  onClick={handleEditSliderImageOne}
                >
                  save image
                </Button>
                <Button variant="outlined" onClick={() => setSliderImageOne(sliderImageOneDef)}>
                  cancel
                </Button>
              </div>
            )}
          </div>
        </FileInputWrapper>
        {/* =================================================== */}
        {/* =================================================== */}
        <FileInputWrapper>
          <input
            id="slider-img-two"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => e.target.files[0] && setSliderImageTwo(e.target.files[0])}
            multiple
          />
          <label htmlFor="slider-img-two">change slider image 2</label>

          <div key={sliderImageTwo?.id || sliderImageTwo?.name} className="img-wrapper-admin">
            {sliderImageTwo && (
              <img
                src={
                  sliderImageTwo.name ? URL.createObjectURL(sliderImageTwo) : `${path}/${sliderImageTwo}`
                }
                alt=""
              />
            )}
            {sliderImageTwo && sliderImageTwo !== sliderImageTwoDef && (
              <div className="img-btns">
                <Button
                  className="save-btn"
                  variant="contained"
                  onClick={handleEditSliderImageTwo}
                >
                  save image
                </Button>
                <Button variant="outlined" onClick={() => setSliderImageTwo(sliderImageTwoDef)}>
                  cancel
                </Button>
              </div>
            )}
          </div>
        </FileInputWrapper>
        {/* =================================================== */}
        {/* =================================================== */}
        <FileInputWrapper>
          <input
            id="slider-img-three"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => e.target.files[0] && setSliderImageThree(e.target.files[0])}
            multiple
          />
          <label htmlFor="slider-img-three">change slider image 3</label>

          <div key={sliderImageThree?.id || sliderImageThree?.name} className="img-wrapper-admin">
            {sliderImageThree && (
              <img
                src={
                  sliderImageThree.name ? URL.createObjectURL(sliderImageThree) : `${path}/${sliderImageThree}`
                }
                alt=""
              />
            )}
            {sliderImageThree && sliderImageThree !== sliderImageThreeDef && (
              <div className="img-btns">
                <Button
                  className="save-btn"
                  variant="contained"
                  onClick={handleEditSliderImageThree}
                >
                  save image
                </Button>
                <Button variant="outlined" onClick={() => setSliderImageThree(sliderImageThreeDef)}>
                  cancel
                </Button>
              </div>
            )}
          </div>
        </FileInputWrapper>
        {/* =================================================== */}
        {/* =================================================== */}
        <FileInputWrapper>
          <input
            id="slider-img-four"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => e.target.files[0] && setSliderImageFour(e.target.files[0])}
            multiple
          />
          <label htmlFor="slider-img-four">change slider image 4</label>

          <div key={sliderImageFour?.id || sliderImageFour?.name} className="img-wrapper-admin">
            {sliderImageFour && (
              <img
                src={
                  sliderImageFour.name ? URL.createObjectURL(sliderImageFour) : `${path}/${sliderImageFour}`
                }
                alt=""
              />
            )}
            {sliderImageFour && sliderImageFour !== sliderImageFourDef && (
              <div className="img-btns">
                <Button
                  className="save-btn"
                  variant="contained"
                  onClick={handleEditSliderImageFour}
                >
                  save image
                </Button>
                <Button variant="outlined" onClick={() => setSliderImageFour(sliderImageFourDef)}>
                  cancel
                </Button>
              </div>
            )}
          </div>
        </FileInputWrapper>
        {/* =================================================== */}
        {/* =================================================== */}
        <FileInputWrapper>
          <input
            id="slider-img-five"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => e.target.files[0] && setSliderImageFive(e.target.files[0])}
            multiple
          />
          <label htmlFor="slider-img-five">change slider image 5</label>

          <div key={sliderImageFive?.id || sliderImageFive?.name} className="img-wrapper-admin">
            {sliderImageFive && (
              <img
                src={
                  sliderImageFive.name ? URL.createObjectURL(sliderImageFive) : `${path}/${sliderImageFive}`
                }
                alt=""
              />
            )}
            {sliderImageFive && sliderImageFive !== sliderImageFiveDef && (
              <div className="img-btns">
                <Button
                  className="save-btn"
                  variant="contained"
                  onClick={handleEditSliderImageFive}
                >
                  save image
                </Button>
                <Button variant="outlined" onClick={() => setSliderImageFive(sliderImageFiveDef)}>
                  cancel
                </Button>
              </div>
            )}
          </div>
        </FileInputWrapper>
        {/* =================================================== */}
        <PageTitle>Barbershop price list</PageTitle>
        <InfoField>
          {!addNew ? (
            <Button
              variant="contained"
              className="barber-btn"
              onClick={() => setAddNew(true)}
            >
              add new service
            </Button>
          ) : (
            <div className="flex-wrapper">
              <TextFieldWrapper>
                <TextField
                  autoComplete="off"
                  className="disabled-bold"
                  label="New service name"
                  value={newKey}
                  onChange={(e) => {
                    setNewKey(e.target.value);
                    handleErrorRemove("name");
                  }}
                  error={errors?.name ? true : false}
                />
                {errors.name && <div className="err">{errors.name[0]}</div>}
              </TextFieldWrapper>
              <TextFieldWrapper>
                <NumberTextField
                  autoComplete="off"
                  customInput={TextField}
                  className="disabled-bold"
                  label="New service price"
                  value={newValue}
                  onChange={(e) => {
                    setNewValue(e.target.value);
                    handleErrorRemove("price");
                  }}
                  error={errors?.price ? true : false}
                />
                {errors.price && <div className="err">{errors.price[0]}</div>}
              </TextFieldWrapper>
              <div className="barber-btns">
                <Button onClick={handleAddNewItem}>save</Button>
                <Button onClick={handleCancelAdd}>cancel</Button>
              </div>
            </div>
          )}
        </InfoField>
        {!!prices.length &&
          prices.map((service) => (
            <InfoField key={service.id}>
              <div className="flex-wrapper">
                <TextFieldWrapper>
                  <TextField
                    autoComplete="off"
                    className="disabled-bold"
                    label="Service name"
                    value={
                      editingId !== service.id
                        ? service.service_name.split("_").join(" ")
                        : editingService.split("_").join(" ")
                    }
                    onChange={(e) => {
                      setEditingService(e.target.value);
                      handleErrorRemove("name");
                    }}
                    disabled={service.id !== editingId ? true : false}
                    error={
                      errors?.name && service.id === editingId ? true : false
                    }
                  />
                  {errors.name && service.id === editingId && (
                    <div className="err">{errors.name[0]}</div>
                  )}
                </TextFieldWrapper>
                <TextFieldWrapper>
                  <NumberTextField
                    autoComplete="off"
                    customInput={TextField}
                    className="disabled-bold"
                    label="Service price"
                    value={
                      editingId !== service.id ? service.price : editingPrice
                    }
                    onChange={(e) => {
                      setEditingPrice(e.target.value);
                      handleErrorRemove("price");
                    }}
                    disabled={service.id !== editingId ? true : false}
                    error={
                      errors?.price && service.id === editingId ? true : false
                    }
                  />
                  {errors.price && service.id === editingId && (
                    <div className="err">{errors.price[0]}</div>
                  )}
                </TextFieldWrapper>
                <div className="barber-btns">
                  {editingId !== service.id ? (
                    <>
                      <Button
                        onClick={() =>
                          handleSelectEditingItem(
                            service.id,
                            service.service_name,
                            service.price
                          )
                        }
                      >
                        edit
                      </Button>
                      <Button
                        onClick={() => {
                          setDeletingItem(service);
                          setOpenDeleteDialog(true);
                        }}
                      >
                        Delete
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button onClick={() => handleEditService(service)}>
                        save
                      </Button>
                      <Button onClick={handleCancelServiceEdit}>cancel</Button>
                    </>
                  )}
                </div>
              </div>
            </InfoField>
          ))}
      </Form>
      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleCloseDelete}
        handleClose={handleCloseDelete}
        handleRemove={handleDelete}
        name={`${deletingItem?.service_name.split("_").join(" ")} service`}
      />
    </Container>
  );
};

export default BarbershopAdmin;
